import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure Bootstrap is imported

import Panel from "@/shared/components/Panel";
import { Ring } from "@uiball/loaders";
import { addCertificate, addProductTrackingDetail, getProductInformation, getProjectOwnerProducts } from "../../../utils/services/user.service";
import { Button } from "../../../shared/components/Button";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import { Error } from "@material-ui/icons";
import { Formik } from "formik";
import { Badge } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import LocationSearch from "./LocationSearch";
import { FaFileAlt } from "react-icons/fa";
import { useChainContext } from "../../../containers/context/context";

const OrderTrackingTable = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    // Extract the `id` from the query string
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("id");    const [productData, setProductData] = useState([]);

    const { globalProjectOwnerId,
        setGlobalProjectOwnerId, } = useChainContext()
    const [addTrackinfInfo, setAddTrackingInfo] = useState(false);
    const [addCertification, setAddCertification] = useState(false);
    const [trackinfInfoProductId, setTrackingInfoPrductId] = useState("");
    const logedInUser = JSON.parse(localStorage.getItem("user"))?.data.user.user_id;
    const [coordinates, setCoordinates] = useState(null);
    const panelRef = useRef(null); // Step 1: Create a ref for the panel

    const [locationData, setLocationData] = useState({
        location: '',
        coordinates: { lat: 0, lng: 0 },
    });

    const handleCoordinatesChange = ({ location, coordinates }) => {
        setCoordinates(coordinates)
        setLocationData({ location, coordinates });
    };

    const fetchAllProducts = async () => {
        setIsLoading(true)
        try {
            const payload = {
                limit: '1000000',
                page_number: 1,
                project_id: projectId

            }
            const res = await getProductInformation(payload)
            setProductData(res?.data?.data)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)

        }
    }
    useEffect(() => {
        fetchAllProducts()
    }, [projectId])

    const AddCertificationsData = async (values) => {
        const formData = new FormData();

        // Add the product ID to FormData
        formData.append("product_id", trackinfInfoProductId);

        // Append files to FormData
        values.files.forEach((file, index) => {
            formData.append(`File`, file); // Add files with unique keys
        });

        try {
            const res = await addCertificate(formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (res.status === 200) {
                toast.success("Certification data added successfully!");
            } else {
                toast.error("Failed to add certification data.");
            }
        } catch (error) {
            console.error("Error uploading data:", error);
            toast.error("An error occurred while uploading data.");
        }
    };

    const addTrackingDetails = async (val) => {
        console.log('hhhh')
        try {
            setIsLoading(true);
            // addTrackinfInfo(false)

            // Create a FormData object to handle files and text
            const formData = new FormData();
            formData.append("project_id", projectId);
            formData.append("title", val.title);
            formData.append("description", val.description);
            formData.append("origin", locationData?.location);
            formData.append("batch_number", val.batch_number);
            formData.append("location", JSON.stringify(coordinates)); // Convert coordinates to string

            // Append images to FormData
            val.images.forEach((image, index) => {
                formData.append(`File`, image);
            });

            // // Append documents to FormData
            // val.documents.forEach((document, index) => {
            //     formData.append(`File2`, document);
            // });

            // Make the API call
            const res = await addProductTrackingDetail(formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            // Handle success response
            toast.success(res?.data?.Message);
            fetchAllProducts();
            setIsLoading(false);
            setAddTrackingInfo(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error?.response?.data?.Message || "Something went wrong!");
        }
    };


    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>

            <Panel lg={12} title="Tracking Info" closeBtn minusBtn refreshLoad>


                {globalProjectOwnerId == logedInUser && <Button variant="outline-primary" className="m-0 col-lg-3 col-12" onClick={() => {
                    setAddTrackingInfo(true);
                    // setTrackingInfoPrductId(product._id)
                    panelRef.current?.scrollIntoView({ behavior: "smooth" });

                }}>Add Tracking Details</Button>}

                <div className="d-flex flex-wrap gap-2 mt-2">
                    {productData?.map((product) => (
                        <div
                            key={product?.id}
                            className="d-flex flex-column align-items-center gap-1 col-12 col-md-12 col-lg-3"
                            style={{
                                border: '1px solid white',
                                borderRadius: '9px'
                            }}
                        >

                            <h4 className="mt-1">{product?.title}</h4>

                            <img
                                src={product?.images[0]}
                                alt={product.title}
                                style={{
                                    width: "200px",
                                    height: "150px",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    objectFit: 'contain'
                                }}
                            />

                            <div className="d-flex w-100 gap-2 flex-column item-center">


                                {/* {product?.tracking_details.length > 0 && logedInUser == product?.userId &&
                                    <Button className="w-full m-0" variant="contain-primary" onClick={() => {
                                        setAddCertification(true);
                                        setTrackingInfoPrductId(product._id)
                                        panelRef.current?.scrollIntoView({ behavior: "smooth" });

                                    }}>Add Certification</Button>} */}
                                <Button
                                    variant="outline-primary"
                                    onClick={() => history.push(`/product-detail/${product._id}`)}
                                >
                                    View
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>



            </Panel>


            <div ref={panelRef}>
                {addTrackinfInfo && (
                    <Panel lg={12} title="Add Tracking Info" closeBtn minusBtn refreshLoad>
                        <Formik
                            initialValues={{
                                title: "",
                                model: "",
                                description: "",
                                Manufacturer: "",
                                Company: "",
                                store_link: "",
                                File: null,
                                batch_number: "",
                                origin: "",
                                images: [], // New field for images
                                documents: [], // New field for documents
                            }}
                            onSubmit={(values, { resetForm }) => {
                                addTrackingDetails(values);
                                resetForm();
                            }}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                } = props;

                                const handleFileUpload = (e, field) => {
                                    const files = Array.from(e.target.files);
                                    setFieldValue(field, [...values[field], ...files]);
                                };

                                const handleRemoveFile = (field, index) => {
                                    const updatedFiles = values[field].filter((_, i) => i !== index);
                                    setFieldValue(field, updatedFiles);
                                };

                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            {[
                                                {
                                                    id: "title",
                                                    label: "Title",
                                                    placeholder: "Enter Title",
                                                },
                                                {
                                                    id: "description",
                                                    label: "Description",
                                                    placeholder: "Enter Description",
                                                },

                                                {
                                                    id: "batch_number", // Ensure this matches the key in initialValues
                                                    label: "Batch Number",
                                                    placeholder: "Enter Batch Number",
                                                },
                                            ].map((field) => (
                                                <div key={field.id} className="col-12">
                                                    <InputDiv>
                                                        <label
                                                            htmlFor={field.id}
                                                            style={{ fontSize: "small" }}
                                                            className="form-label text-white"
                                                        >
                                                            {field.label}
                                                        </label>
                                                        <div>
                                                            <ChatSearchInput
                                                                type="text"
                                                                value={values[field.id]}
                                                                name={field.id} // Ensure the name matches the key in initialValues
                                                                id={field.id}
                                                                placeholder={field.placeholder}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={`form-control mt-2 ${errors[field.id] && touched[field.id] && "error"
                                                                    }`}
                                                            />
                                                            {errors[field.id] && touched[field.id] && (
                                                                <Error className="input feedback">
                                                                    {errors[field.id]}
                                                                </Error>
                                                            )}
                                                        </div>
                                                    </InputDiv>
                                                </div>
                                            ))}

                                            {/* Upload Images Section */}
                                            <div className="col-12">
                                                <InputDiv>
                                                    <label htmlFor="images" className="form-label text-white">
                                                        Upload Images
                                                    </label>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            const files = Array.from(e.target.files);
                                                            setFieldValue('images', [...values.images, ...files]);
                                                        }}
                                                        className="form-control mt-2"
                                                    />
                                                    <PreviewContainer>
                                                        {values?.images.map((file, index) => (
                                                            <PreviewItem key={index}>
                                                                <img src={URL.createObjectURL(file)} alt="preview" />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            'images',
                                                                            values.images.filter((_, i) => i !== index)
                                                                        );
                                                                    }}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </PreviewItem>
                                                        ))}
                                                    </PreviewContainer>
                                                </InputDiv>
                                            </div>
                                        </div>




                                        <LocationSearch onCoordinatesChange={handleCoordinatesChange} />

                                        <hr />

                                        <div className="row mb-4">
                                            <div className="col-md-12 col-lg-4 col-sm-12" />
                                            <div className="col-md-12 col-lg-4 col-sm-12">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Button
                                                        type="submit"
                                                        size="md"
                                                        className="py-2 btn-block"
                                                        variant="outline-success"
                                                    >
                                                        Add Tracking Data
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-4 col-sm-12" />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Panel>
                )}

                {addCertification && (
                    <Panel lg={12} title="Add Certification" closeBtn minusBtn refreshLoad>
                        <Formik
                            initialValues={{
                                title: "",
                                model: "",
                                description: "",
                                Manufacturer: "",
                                Company: "",
                                store_link: "",
                                files: [], // Updated to handle multiple files
                            }}
                            onSubmit={(values, { resetForm }) => {
                                console.log("🚀 ~ Form Submitted:", values);
                                AddCertificationsData(values); // Function to handle form submission
                                resetForm(); // Reset form after submission
                            }}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                } = props;

                                // Function to handle file upload
                                const handleFileUpload = (event) => {
                                    const files = Array.from(event.target.files); // Convert FileList to array
                                    const validFiles = files.filter((file) =>
                                        ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)
                                    );

                                    if (values.files.length + validFiles.length > 20) {
                                        toast.error("You can upload a maximum of 20 documents."); // Show toast error
                                        return;
                                    }

                                    setFieldValue("files", [...values.files, ...validFiles]); // Add valid files to state
                                };

                                // Function to remove a specific file
                                const removeFile = (index) => {
                                    const updatedFiles = values.files.filter((_, i) => i !== index);
                                    setFieldValue("files", updatedFiles);
                                };



                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            {/* Existing Fields */}
                                            {[
                                                // {
                                                //     id: "title",
                                                //     label: "Certification Name",
                                                //     placeholder: "Enter Certification Name",
                                                // },
                                                // {
                                                //     id: "model",
                                                //     label: "Certification Type",
                                                //     placeholder: "Enter Certification Type",
                                                // },
                                            ].map((field) => (
                                                <div key={field.id} className="col-12">
                                                    <InputDiv>
                                                        <label
                                                            htmlFor={field.id}
                                                            style={{ fontSize: "small" }}
                                                            className="form-label text-white"
                                                        >
                                                            {field.label}
                                                        </label>
                                                        <ChatSearchInput
                                                            type="text"
                                                            value={values[field.id]}
                                                            name={field.id}
                                                            id={field.id}
                                                            placeholder={field.placeholder}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={`form-control mt-2 ${errors[field.id] && touched[field.id] ? "error" : ""
                                                                }`}
                                                        />
                                                        {errors[field.id] && touched[field.id] && (
                                                            <Error className="input feedback">
                                                                {errors[field.id]}
                                                            </Error>
                                                        )}
                                                    </InputDiv>
                                                </div>
                                            ))}

                                            {/* File Upload Section */}
                                            <div className="col-12">
                                                <InputDiv>
                                                    <label
                                                        htmlFor="files"
                                                        style={{ fontSize: "small" }}
                                                        className="form-label text-white"
                                                    >
                                                        Upload Documents
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="files"
                                                        accept=".pdf,.doc,.docx,.xlsx"
                                                        multiple
                                                        style={{ display: "none" }}
                                                        onChange={handleFileUpload}
                                                    />
                                                    <Button
                                                        type="button"
                                                        size="md"
                                                        className="py-2 btn-block"
                                                        variant="outline-info"
                                                        onClick={() => document.getElementById("files").click()}
                                                    >
                                                        Choose Documents
                                                    </Button>

                                                    {/* Display Uploaded Files */}
                                                    {values.files.length > 0 && (
                                                        <div className="mt-3">
                                                            <div className="row">
                                                                {values.files.map((file, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="col-lg-4 col-md-6 col-12 mb-3 d-flex align-items-center"
                                                                    >
                                                                        <div
                                                                            className="p-3 text-white rounded d-flex flex-column align-items-start"
                                                                            style={{ minHeight: "120px" }}
                                                                        >
                                                                            <span className="mb-2">
                                                                                {file.name}
                                                                            </span>
                                                                            <Button
                                                                                variant="outline-danger"
                                                                                size="sm"
                                                                                onClick={() => removeFile(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}

                                                </InputDiv>
                                            </div>
                                        </div>

                                        <hr />

                                        {/* Submit Button */}
                                        <div className="row mb-4">
                                            <div className="col-12 text-center">
                                                <Button
                                                    type="submit"
                                                    size="md"
                                                    className="py-2 btn-block"
                                                    variant="outline-success"
                                                >
                                                    Add Data
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Panel>
                )}
            </div>


        </>
    );
};

export default OrderTrackingTable;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;a
`;
const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const PreviewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
  }

  p {
    font-size: 12px;
    text-align: center;
  }

  button {
    font-size: 10px;
    color: red;
    border: none;
    background: none;
    cursor: pointer;
  }
`;
