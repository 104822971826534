import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import TopbarProfile from '../components/topbar/TopbarProfile';
import { useCart } from '../../../store/Layout/product/CartContext';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import TopbarNotification from '../components/topbar/TopbarNotification';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarMiddle,
  TopbarRight,
  TopbarRightOver,
  TopbarButton,
} from '../components/topbar/BasicTopbarComponents';
import TopbarWallet from '../components/topbar/TopbarWallet';
import Logo from '../../../utils/img/custodiy.png';
import './topbar.css';
import { FaUser } from 'react-icons/fa';
import { useChainContext } from '../../context/context';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}) => {
  const { cartCount } = useCart();
  const { infoData } = useChainContext()
  const location = useLocation();

  return (
    <TopbarContainer>
      {/* Left Section */}
      <TopbarLeft className='d-flex align-items-center'>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <Link to="/">
          <img src={Logo} alt="Logo" style={{ height: '40px' }} />
        </Link>
      </TopbarLeft>

      {/* Middle Section */}
      {location.pathname === "/" && <TopbarMiddle className='d-none d-md-flex'>
        {infoData.map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <i className={`fa ${item.icon}`} style={{ fontSize: "20px", color: "#70BBFC" }}></i>
            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#70BBFC" }}>
              {item.value}
            </span>
          </div>
        ))}
      </TopbarMiddle>}


      {/* Right Section */}
      <TopbarRight>
        <TopbarRightOver>
          <Link to="/showcart" className="cicon">
            <TopbarButton type="button" className="mx-0 p-0">
              <FontAwesomeIcon
                icon={faShoppingCart}
                style={{
                  width: '24px',
                  height: '24px',
                  transition: 'color 0.3s',
                }}
              />
              {/* {cartCount > 0 && (
                <Badge bg="danger" className="rounded-md">
                  {cartCount}
                </Badge>
              )} */}
                {cartCount > 0 && (
          <h5 className="leading-9 mt-2 ml-0">
            <Badge bg="danger rounded-md">{cartCount}</Badge>
          </h5>
        )}
            </TopbarButton>
          </Link>
          <TopbarNotification />
          <TopbarProfile />
          <TopbarWallet />
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
