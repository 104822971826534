/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useParams, Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel';
import { Ring } from '@uiball/loaders';
import Slider from 'react-slick';
// import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SettingsInputComponentSharp } from '@material-ui/icons';
import { CustomSelect, CustomMultiSelect } from '../../../shared/components/CustomSelect';
import 'react-toastify/dist/ReactToastify.css';
import '../style/product.css';
import {
  getMyProduct,
  getProductData,
  getProductReview,
} from '../../../utils/services/user.service';
import { useCart } from './CartContext';
import StarRating from '../home/StarRating';
import ReviewStarRating from '../home/ReviewStarRating';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TopbarNew from '../../../containers/Layout/topbar/TopbarNew';
import { Button } from '../../../shared/components/Button';
import SameProducts from './SameProducts';
import CustomOfferModal from '../../../shared/components/customOfferModal/CustomOfferModal';
import { useLocation } from 'react-router-dom';

const ProductDetails = () => {
  // console.log('ProductDetails Rendor');
  const location = useLocation();
  const pathname = location.pathname;
  const id1 = pathname.split("/").pop(); // Extracts the last part after the final "/"

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  console.log("🚀 ~ ProductDetails ~ id:", id)
  const [product, setProduct] = useState({});
  const [getImage, setImages] = useState([]);
  const [userReview, setUserReview] = useState([]);
  const [getIndexValues, setIndexValues] = useState([]);

  const [Rating, setRating] = useState('');
  const [productViewCountTotal, setProductViewCountTotal] = useState(0);
  const [RatingCount, setRatingCount] = useState(0);
  const [userFeatures, setUserFeatures] = useState([]);
  const [productViewCount, setProductViewCount] = useState([]);
  const [colorVal, setColor] = useState([]);
  const [productColors, setProductColors] = useState([])
  const transformedProductColors = productColors.map(color => ({ value: color, label: color }));
  const [sizeVal, setSize] = useState([]);
  const [storageVal, setStorage] = useState([]);
  const [transmissionVal, setTransmission] = useState([]);
  const [horsePowerVal, setHorsePower] = useState([]);
  const [propertyTypeVal, setPropertyType] = useState([]);
  const [floorVal, setFloor] = useState([]);
  const [ModelVal, setModel] = useState([]);
  const [DataArray, setDataArray] = useState([]);
  console.log("🚀 ~ ProductDetails ~ DataArray:", DataArray)
  const [DataArray1, setDataArray1] = useState([]);
  // const [cartCount, setCartCount] = useState(5); 
  const [isLoading, setIsLoading] = useState(false);
  const [localCartCount, setLocalCartCount] = useState(0);
  const [cart, setCart] = useState([]);
  const [QtyNew, setQtyNew] = useState(1);
  const [SizeNew, setSizeNew] = useState(' ');
  const [PriceNew, setPriceNew] = useState(' ');
  const [PriceNew2, setPriceNew2] = useState(0);
  const [sessionId, setSessionId] = useState(0);
  const [showCustomOfferModal, setShowCustomOfferModal] = useState(false);
  const logedInUser = JSON.parse(localStorage.getItem("user"))?.data.user.user_id;
  let storeColor = [];
  let storeSize = [];
  let storeStorage = [];
  let storeModel = [];
  let storeTransmission = [];
  let storeHorsePower = [];
  let storeFloor = [];
  let storePropertyType = [];
  let storeDataNew = [];
  let storeDataNew1 = [];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { cartCount, incrementCartCount } = useCart();

  if (localStorage.getItem('storeSessioId') === null) {
    const sessionIdValue = Math.random().toString(36).substring(2, 15);
    setSessionId(sessionIdValue);
    localStorage.setItem('storeSessioId', sessionIdValue);
  }

  const productList = [
    {
      productId: id || id1,
      session_id: localStorage.getItem('storeSessioId'),
    },
  ];

  const productListReview = [
    {
      productId: id || id1,
      limit: 10,
      pageNumber: 1,
    },
  ];

  const [SelectedColor, setSelectedColor] = useState('Red');
  const [SelectedTransmission, setSelectedTransmission] = useState();
  const [SelectedCategoryId, setSelectedCategoryId] = useState();
  const [SelectedRegistration, setSelectedRegistration] = useState();
  const [SelectedHorsePower, setSelectedHorsePower] = useState();
  const [SelectedStorage, setSelectedStorage] = useState();
  const [SelectedModel, setSelectedModel] = useState();
  const [SelectedModelArr, setSelectedModelArr] = useState();
  const [SelectedMaxQty, setSelectedMaxQty] = useState();
  const [SelectedObjectedID, setObjectedID] = useState();
  const [selectedTargetId, setTargetId] = useState();
  const [SelectedDiscountPercentage, setDiscountPercentage] = useState();
  const [SelectedSize, setSizeColor] = useState();
  const [SelectedFloor, setSelectedFloor] = useState();
  const [SelectedSquareSize, setSelectedSquareSize] = useState();
  const [SelectedPropertyType, setSelectedPropertyType] = useState();
  const [UpdatedQuantity, setUpdatedQuantity] = useState([]);
  const [SelectedQuantity, setSelectedQuantity] = useState(1);
  const [SelectedQuantityCheck, setSelectedQuantityCheck] = useState(1);
  const [getIsDiscout, setIsDiscout] = useState(false);
  const [getIsDetail, setIsDetail] = useState(false);
  const [getIsProperty, setIsProperty] = useState(0);
  const NoImg = `${process.env.PUBLIC_URL}/img/no-img.png`;


  const handleColorChange = (value) => {
    const indices = DataArray.reduce((acc, obj, index) => {
      if (obj.color === value.value) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newArray = indices.map(index => DataArray[index]);
    setDataArray1(newArray);
    storeHorsePower = newArray.map(value11 => ({
      value: value11.horse_power,
      label: value11.horse_power,
    }));
    storeTransmission = newArray.map(value11 => ({
      value: value11.transmission,
      label: value11.transmission,
    }));

    setIsDiscout(false);
    setPriceNew2(0);
    // const index = data.findIndex(item => item.color === "red");
    const index = DataArray1.findIndex(item => item.color === value.label);
    const Updated = DataArray1[index];
    setPriceNew(Updated?.price);
    setQtyNew(Updated?.quantity);
    // eslint-disable-next-line no-underscore-dangle
    setTargetId(Updated._id);
    setSelectedColor(value.value);
    setSelectedMaxQty(Updated.quantity);
    setSelectedTransmission(Updated.transmission);
    setSelectedHorsePower(Updated.horse_power);

    if (getIndexValues.includes('discount')) {
      if (Updated.discount > 0) {
        const getPrice = Updated.price;
        const getDiscount = Updated.discount;
        const priceTotal = (getDiscount / 100) * getPrice;
        setPriceNew2(priceTotal);
        setIsDiscout(true);
      }
    }
    if (getIndexValues.includes('storage')) {
      const Quantityindex = DataArray.findIndex(
        item => item.storage === SelectedStorage && item.model === SelectedModel && item.color === value.value,
      );
      setSelectedQuantityCheck(DataArray[Quantityindex].quantity);
      setSelectedQuantity(1);
    }
    if (getIndexValues.includes('size') && getIndexValues.includes('color')) {
      const Quantityindex = DataArray.findIndex(
        item => item.size === newArray[0].size && item.color === value.value,
      );
      setSelectedQuantityCheck(DataArray[Quantityindex].quantity);
      setSelectedQuantity(1);
    }
    if (getIndexValues.includes('horse_power') && getIndexValues.includes('transmission')) {
      setTransmission(storeTransmission);
      setHorsePower(storeHorsePower);
      setSelectedTransmission(newArray[0].transmission);
      setSelectedHorsePower(newArray[0].horse_power);
    }

    if (!getIndexValues.includes('storage') && !getIndexValues.includes('horse_power') && !getIndexValues.includes('model')) {
      const Quantityindex = DataArray.findIndex(
        item => item.color === value.value,
      );
      setSelectedQuantityCheck(DataArray[Quantityindex].quantity);
      setSelectedQuantity(1);
    }
    // eslint-disable-next-line no-underscore-dangle
    setObjectedID(Updated._id);
    setDiscountPercentage(Updated.discount);
  };

  const handleSizeChange = (value) => {
    const indices = DataArray.reduce((acc, obj, index) => {
      if (obj.size === value.value) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newArray = indices.map(index => DataArray[index]);
    setDataArray1(newArray);
    storeColor = newArray.map(value11 => ({
      value: value11.color,
      label: value11.color,
    }));
    // console.log('Newe Color', storeColor[0].value);
    // if (getIndexValues.includes('color')) {
    //   setSelectedColor();
    // } else {
    //   setSelectedColor();
    // }
    setColor(storeColor);
    setSizeColor(value.value);

    if (getIndexValues.includes('size') && getIndexValues.includes('color')) {
      const Quantityindex = DataArray.findIndex(
        item => item.color === newArray[0].color && item.size === value.value,
      );
      setSelectedQuantityCheck(DataArray[Quantityindex].quantity);
      setSelectedQuantity(1);
      // console.log('Index Values', SelectedStorage, SelectedModel, value.value);
    }

    const index = DataArray.findIndex(item => item.size === value.value);
    const Updated = DataArray[index];
    setSizeNew(Updated.size);
    // eslint-disable-next-line no-underscore-dangle
    setTargetId(Updated._id);
    setSelectedColor();
    storeDataNew1 = {
      label: Updated.color,
      size: Updated.color,
    };
  };

  const handleStorageChange = (value) => {
    const indices = DataArray.reduce((acc, obj, index) => {
      if (obj.storage === value.value) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newArray = indices.map(index => DataArray[index]);
    setDataArray1(newArray);
    storeColor = newArray.map(value11 => ({
      value: value11.color,
      label: value11.color,
    }));
    storeModel = newArray.map(value11 => ({
      value: value11.model,
      label: value11.model,
    }));
    setColor(storeColor);
    setSelectedModel(newArray[0].model);
    setSelectedColor(newArray[0].color);
    setModel(storeModel);
    setSelectedStorage(value.value);

    const Quantityindex = DataArray.findIndex(
      item => item.storage === value.value && item.model === newArray[0].model && item.color === newArray[0].color,
    );
    setSelectedQuantityCheck(DataArray[Quantityindex].quantity);
    setSelectedQuantity(1);

    const index = DataArray.findIndex(item => item.storage === value.value);
    const Updated = DataArray[index];
    // eslint-disable-next-line no-underscore-dangle
    setTargetId(Updated._id);
    // setSelectedColor();
    storeDataNew1 = {
      label: Updated.color,
      size: Updated.color,
    };
  };

  const handleModelChange = (value) => {
    const indices = DataArray.reduce((acc, obj, index) => {
      if (obj.model === value.value) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newArray = indices.map(index => DataArray[index]);
    setDataArray1(newArray);
    storeColor = newArray.map(value11 => ({
      value: value11.color,
      label: value11.color,
    }));
    storeTransmission = newArray.map(value11 => ({
      value: value11.transmission,
      label: value11.transmission,
    }));
    storeHorsePower = newArray.map(value11 => ({
      value: value11.horse_power,
      label: value11.horse_power,
    }));

    if (getIndexValues.includes('storage')) {
      const Quantityindex = DataArray.findIndex(
        item => item.storage === SelectedStorage && item.model === SelectedModel && item.color === SelectedColor,
      );
      setSelectedQuantityCheck(DataArray[Quantityindex].quantity);
      setSelectedQuantity(1);
    }

    setColor(storeColor);
    setSelectedColor(newArray[0].color);
    setTransmission(storeTransmission);
    setHorsePower(storeHorsePower);
    setSelectedModel(value.value);
    const index = DataArray.findIndex(item => item.model === value.value);
    const Updated = DataArray[index];
    // eslint-disable-next-line no-underscore-dangle
    setTargetId(Updated._id);
    // setSelectedColor();
    if (getIndexValues.includes('transmission') && getIndexValues.includes('horse_power')) {
      setSelectedTransmission(newArray[0].transmission);
      setSelectedHorsePower(newArray[0].horse_power);
    } else {
      setSelectedTransmission();
      setSelectedHorsePower();
    }
    storeDataNew1 = {
      label: Updated.color,
      size: Updated.color,
    };
  };

  const handleTransmissionChange = (value) => {
    const indices = DataArray.reduce((acc, obj, index) => {
      if (obj.transmission === value.value) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newArray = indices.map(index => DataArray[index]);
    setDataArray1(newArray);
    storeColor = newArray.map(value11 => ({
      value: value11.color,
      label: value11.color,
    }));
    setColor(storeColor);
    setSelectedTransmission(value.value);
    const index = DataArray.findIndex(item => item.transmission === value.value);
    const Updated = DataArray[index];
    // eslint-disable-next-line no-underscore-dangle
    setTargetId(Updated._id);
    setSelectedHorsePower(Updated.horse_power);
    setSelectedColor();
    storeDataNew1 = {
      label: Updated.color,
      size: Updated.color,
    };
  };

  const handleHorsePowerChange = (value) => {
    setSelectedHorsePower(value.value);
  };

  // const handlePropertyTypeChange = (value) => { 
  //   setSelectedPropertyType(value.value);
  // };

  const handlePropertyTypeChange = (value) => {
    if (DataArray[0].square_size) {
      setSelectedSquareSize(DataArray[0].square_size);
    }
    const indices = DataArray.reduce((acc, obj, index) => {
      if (obj.property_type === value.value) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newArray = indices.map(index => DataArray[index]);
    setDataArray1(newArray);
    storeFloor = newArray.map(value11 => ({
      value: value11.floor,
      label: value11.floor,
    }));
    if (storeFloor.length === 1) {
      setSelectedSquareSize(newArray[0].square_size);
      setSelectedFloor(newArray[0].floor);
    } else {
      setSelectedSquareSize(' ');
    }
    setFloor(storeFloor);
    setSelectedPropertyType(value.value);
    const index = DataArray.findIndex(item => item.property_type === value.value);
    const Updated = DataArray[index];
    // eslint-disable-next-line no-underscore-dangle
    setTargetId(Updated._id);
  };

  const handleFloorChange = (value) => {
    const indices = DataArray.reduce((acc, obj, index) => {
      if (obj.floor === value.value) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newArray = indices.map(index => DataArray[index]);
    setDataArray1(newArray);

    // storeFloor = newArray.map(value11 => ({
    //   value: value11.floor,
    //   label: value11.floor,
    // }));

    const uniqueFloors = new Set(); // Use a Set to store unique values

    storeFloor = newArray.map((value11) => {
      // Check if the floor is not already in the Set
      if (!uniqueFloors.has(value11.floor)) {
        uniqueFloors.add(value11.floor); // Add the unique floor to the Set
        return {
          value: value11.floor,
          label: value11.floor,
        };
      }
      return null; // Return null for duplicates, you can filter them out later
    });

    // Filter out null values (duplicates) from storeFloor
    const filteredStoreFloor = storeFloor.filter(item => item !== null);



    setDataArray1(newArray);
    setFloor(filteredStoreFloor);
    setSelectedFloor(value.value);
    // const CityArray = [
    //   { value: 'America', label: 'America' },
    //   { value: 'London', label: 'London' },
    // ];
    // setSelectedFloor(CityArray);
    const index = DataArray.findIndex(item => item.floor === value.value);
    const Updated = DataArray[index];
    setSelectedSquareSize(Updated.square_size);
    // eslint-disable-next-line no-underscore-dangle
    setTargetId(Updated._id);
  };

  // const handleFloorChange = (value) => { 
  //   console.log('handleFloorChange');
  //   setSelectedFloor(value.value);
  // };

  const handleQuantityChange = (value) => {
    if (value <= 0) {
      setSelectedQuantity(1);
    } else if (value <= QtyNew) {
      if (value <= SelectedQuantityCheck) {
        setSelectedQuantity(value);
      }
    }
  };

  useEffect(() => {
    const storedArray = JSON.parse(localStorage.getItem('customArray'));
    if (storedArray != null) {
      // setCartCount(storedArray.length);
    }
    const fetchProductData = async () => {
      setIsLoading(true);
      // try {
      const response = await getProductData(productList[0]); // Pass the id ,id1 string directly
      if (response.data && response.data.productDetails[0]) {
        setIsLoading(false);
        const colorArr = response.data.productDetails[0].product_sizes_quantity;
        console.log("🚀 ~ fetchProductData ~ colorArr:", colorArr)
        const categoryArrayDetailKeys = colorArr.map(data => Object.keys(data));
        // if (categoryArrayDetailKeys[0].length > 0) {
        //   categoryArrayDetailKeys[0].pop();
        // }
        const newArrayArr = categoryArrayDetailKeys[0].filter(item => item !== '_id');
        console.log("🚀 ~ fetchProductData ~ newArrayArr:", newArrayArr)
        setIndexValues(newArrayArr);
        // Extracting productDetails from the response data
        const productDetails = response.data.productDetails;

        // Extracting colors from productDetails
        const colors = productDetails.flatMap(product => product.product_sizes_quantity.map(item => item.color));

        // Getting unique colors
        const uniqueColors = [...new Set(colors)];

        // Setting unique colors in state
        setProductColors(uniqueColors);
        const uniqueColors1 = new Set();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        storeColor = colorArr.map((value) => {
          if (!uniqueColors1.has(value.color)) {
            // Check if the color is not already in the Set
            uniqueColors1.add(value.color); // Add the color to the Set
            return {
              value: value.color,
              label: value.color,
            };
          }
          return null; // If it's a duplicate, return null (or you can skip it)
        }).filter(Boolean); // Remove any null entries from the mapped array
        const uniqueSizes = new Set(); // Use a Set to store unique sizes
        const uniqueStorage = new Set(); // Use a Set to store unique sizes
        const uniqueModel = new Set(); // Use a Set to store unique sizes
        const uniqueTransmission = new Set(); // Use a Set to store unique sizes
        const uniqueHorsePower = new Set(); // Use a Set to store unique sizes
        const uniquePropertyType = new Set(); // Use a Set to store unique sizes
        const uniqueFloor = new Set(); // Use a Set to store unique sizes

        // eslint-disable-next-line react-hooks/exhaustive-deps
        storeSize = colorArr
          .filter((value) => {
            if (!uniqueSizes.has(value.size)) {
              uniqueSizes.add(value.size); // Add the size to the Set
              return true; // Include this item in the result
            }
            return false; // Exclude this item (it's a duplicate)
          })
          .map(value => ({
            value: value.size,
            label: value.size,
          }));

        storeStorage = colorArr
          .filter((value) => {
            if (!uniqueStorage.has(value.storage)) {
              uniqueStorage.add(value.storage); // Add the size to the Set
              return true; // Include this item in the result
            }
            return false; // Exclude this item (it's a duplicate)
          })
          .map(value => ({
            value: value.storage,
            label: value.storage,
          }));

        storeModel = colorArr
          .filter((value) => {
            if (!uniqueModel.has(value.model)) {
              uniqueModel.add(value.model); // Add the size to the Set
              return true; // Include this item in the result
            }
            return false; // Exclude this item (it's a duplicate)
          })
          .map(value => ({
            value: value.model,
            label: value.model,
          }));

        storeTransmission = colorArr
          .filter((value) => {
            if (!uniqueTransmission.has(value.transmission)) {
              uniqueTransmission.add(value.transmission); // Add the size to the Set
              return true; // Include this item in the result
            }
            return false; // Exclude this item (it's a duplicate)
          })
          .map(value => ({
            value: value.transmission,
            label: value.transmission,
          }));

        storeHorsePower = colorArr
          .filter((value) => {
            if (!uniqueHorsePower.has(value.horse_power)) {
              uniqueHorsePower.add(value.horse_power); // Add the size to the Set
              return true; // Include this item in the result
            }
            return false; // Exclude this item (it's a duplicate)
          })
          .map(value => ({
            value: value.horse_power,
            label: value.horse_power,
          }));

        // storeFloor = colorArr
        //   .filter((value) => {
        //     if (!uniqueFloor.has(value.floor)) {
        //       uniqueFloor.add(value.floor); // Add the size to the Set
        //       return true; // Include this item in the result
        //     }
        //     return false; // Exclude this item (it's a duplicate)
        //   })
        //   .map(value => ({
        //     value: value.floor,
        //     label: value.floor,
        //   }));

        storePropertyType = colorArr
          .filter((value) => {
            if (!uniquePropertyType.has(value.property_type)) {
              uniquePropertyType.add(value.property_type); // Add the size to the Set
              return true; // Include this item in the result
            }
            return false; // Exclude this item (it's a duplicate)
          })
          .map(value => ({
            value: value.property_type,
            label: value.property_type,
          }));


        // eslint-disable-next-line react-hooks/exhaustive-deps
        storeDataNew = colorArr.map(value => ({
          color: value.color,
          discount: value.discount,
          storage: value.storage,
          model: value.model,
          transmission: value.transmission,
          horse_power: value.horse_power,
          floor: value.floor,
          property_type: value.property_type,
          price: value.price,
          quantity: value.quantity,
          pcs: value.pcs,
          size: value.size,
          sold_quantity: value.sold_quantity,
          square_size: value.square_size,
          // eslint-disable-next-line no-underscore-dangle
          _id: value._id,
        }));
        setSelectedColor(response.data.productDetails[0].product_sizes_quantity[0].color);
        setPriceNew(response.data.productDetails[0].product_sizes_quantity[0].price);
        setSizeColor(response.data.productDetails[0].product_sizes_quantity[0].size);
        setSelectedMaxQty(response.data.productDetails[0].product_sizes_quantity[0].quantity);
        setSelectedStorage(response.data.productDetails[0].product_sizes_quantity[0].storage);
        setSelectedModelArr(response.data.productDetails[0].product_sizes_quantity[0]);
        const getPrice = response.data.productDetails[0].product_sizes_quantity[0].price;
        let priceTotal = 0;
        if (response.data.productDetails[0].product_sizes_quantity[0].discount) {
          if (response.data.productDetails[0].product_sizes_quantity[0].discount > 0) {
            const getDiscount = response.data.productDetails[0].product_sizes_quantity[0].discount;
            priceTotal = (getDiscount / 100) * getPrice;
            setIsDiscout(true);
          }
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].horse_power) {
          setIsDetail(true);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].model) {
          setSelectedModel(response.data.productDetails[0].product_sizes_quantity[0].model);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].horse_power) {
          setSelectedHorsePower(response.data.productDetails[0].product_sizes_quantity[0].horse_power);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].transmission) {
          setSelectedTransmission(response.data.productDetails[0].product_sizes_quantity[0].transmission);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].registration) {
          setSelectedRegistration(response.data.productDetails[0].product_sizes_quantity[0].registration);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].floor) {
          // console.log('setSelectedFloor 12345 >>>> ', response.data.productDetails[0].product_sizes_quantity[0].floor);
          setSelectedFloor(response.data.productDetails[0].product_sizes_quantity[0].floor);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].square_size) {
          setSelectedSquareSize(response.data.productDetails[0].product_sizes_quantity[0].square_size);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].property_type) {
          setSelectedPropertyType(response.data.productDetails[0].product_sizes_quantity[0].property_type);
          setQtyNew(1);
          setIsProperty(1);
        }
        // eslint-disable-next-line no-underscore-dangle
        setTargetId(response.data.productDetails[0].product_sizes_quantity[0]._id);

        const indices = storeDataNew.reduce((acc, obj, index) => {
          if (obj.size === response.data.productDetails[0].product_sizes_quantity[0].size) {
            acc.push(index);
          }
          return acc;
        }, []);
        const indices1 = storeDataNew.reduce((acc, obj, index1) => {
          if (obj.floor === response.data.productDetails[0].product_sizes_quantity[0].floor) {
            acc.push(index1);
          }
          return acc;
        }, []);
        const newArray = indices.map(index => storeDataNew[index]);
        const newArray1 = indices1.map(index1 => storeDataNew[index1]);
        setDataArray1(newArray);
        const index = newArray.findIndex(item => item.color === response.data.productDetails[0].product_sizes_quantity[0].color);
        const Updated = newArray[index];

        storeColor = newArray.map(value11 => ({
          value: value11.color,
          label: value11.color,
        }));
        storeFloor = newArray1.map(value11 => ({
          value: value11.floor,
          label: value11.floor,
        }));

        const uniqueFloors = new Set(); // Use a Set to store unique values
        const storeFloors = newArray1.map((value11) => {
          // Check if the floor is not already in the Set
          if (!uniqueFloors.has(value11.floor)) {
            uniqueFloors.add(value11.floor); // Add the unique floor to the Set
            return {
              value: value11.floor,
              label: value11.floor,
            };
          }
          return null; // Return null for duplicates, you can filter them out later
        });

        // Filter out null values (duplicates) from storeFloor
        const filteredStoreFloor = storeFloors.filter(item => item !== null);

        const storeColorArray = [
          storeColor[0],
        ];
        const storeModelArray = [
          storeModel[0],
        ];
        const storeTransmissionArray = [
          storeTransmission[0],
        ];
        const storeHorsePowerArray = [
          storeHorsePower[0],
        ];

        setPriceNew(Updated.price);
        setPriceNew2(priceTotal);
        if (Updated.quantity === undefined) {
          setQtyNew(1);
        } else {
          setQtyNew(Updated.quantity);
        }
        setSelectedColor(response.data.productDetails[0].product_sizes_quantity[0].color);
        if (response.data.productDetails[0].product_sizes_quantity[0].quantity) {
          setSelectedQuantityCheck(response.data.productDetails[0].product_sizes_quantity[0].quantity);
        }
        if (response.data.productDetails[0].product_sizes_quantity[0].pcs) {
          setSelectedQuantityCheck(response.data.productDetails[0].product_sizes_quantity[0].pcs);
        }
        // eslint-disable-next-line no-underscore-dangle
        setObjectedID(Updated._id);
        if (Updated.discount === undefined) {
          setDiscountPercentage(0);
        } else {
          setDiscountPercentage(Updated.discount);
        }
        setSelectedCategoryId(response.data.productDetails[0].category_id);



        if (!newArrayArr.includes('storage') && !newArrayArr.includes('horse_power') && !newArrayArr.includes('model')) {
          setColor(storeColor);
        } else {
          setColor(storeColorArray);
        }
        setSize(storeSize);
        setStorage(storeStorage);
        setTransmission(storeTransmissionArray);
        setHorsePower(storeHorsePowerArray);
        setFloor(filteredStoreFloor);
        setPropertyType(storePropertyType);
        if (newArrayArr.includes('transmission') && newArrayArr.includes('horse_power') && newArrayArr.includes('model')) {
          setModel(storeModel);
        } else {
          setModel(storeModelArray);
        }
        setDataArray(storeDataNew);
        // console.log('response.data.productDetails[0]', response.data.productDetails[0]);
        setProduct(response.data.productDetails[0]);
        setUserFeatures(response.data.productDetails[0].features);
        setImages(response.data.productDetails[0].pictures);
      } else {
        setIsLoading(false);
      }
      // } catch (error) {
      //   setIsLoading(false);
      //   console.error('Error fetching product data:', error);
      // }
    };
    const fetchProductReview = async () => {
      setIsLoading(true);
      try {
        setIsLoading(true);
        const response = await getProductReview(productListReview[0]); // Pass the id ,id1 string directly
        if (response.data && response.data.productReviews) {
          setIsLoading(false);
          setRating(response.data.ratting.averageRating.toFixed(1));
          setRatingCount(response.data.productReviews.length);
          setUserReview(response.data.productReviews);
          setProductViewCount(response.data.ratting);
          const totalCount = response.data.ratting;
          setProductViewCountTotal(totalCount.five_rating_count + totalCount.four_rating_count + totalCount.three_rating_count + totalCount.two_rating_count + totalCount.one_rating_count);
        } else {
          setIsLoading(false);
          console.error('Not Found');
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching product data:', error);
        setRating(0);
        setRatingCount(0);
        setUserReview([]);
        setProductViewCount({
          one_rating_count: 0, two_rating_count: 0, three_rating_count: 0, four_rating_count: 0, five_rating_count: 0,
        });
        setProductViewCountTotal(0);
      }
    };

    fetchProductData();
    fetchProductReview();
  }, [id, id1]);

  const handleClearLocalStorage = () => {
    localStorage.removeItem('customArray');
  };

  const addToCart = () => {
    const userAdress = JSON.parse(localStorage.getItem('user'));
    if (getIndexValues.includes('color') && !SelectedColor) {
      toast.error('Color is Required', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const isProductInCart = cart.some(item => item.productId === product.productId);
      if (SelectedQuantity > QtyNew) {
        toast.warning(`Available Stock: ${product.quantity} Products`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        const storedArray = JSON.parse(localStorage.getItem('customArray')) || [];
        const match1 = product.userId;

        // Check if the product already exists in the stored array
        // eslint-disable-next-line no-underscore-dangle
        const isProductInLocalStorage = storedArray.some(item => item.objectId === selectedTargetId);

        if (isProductInLocalStorage) {
          toast.error('Product is already in the cart', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          const updatedCart = [...cart, product];
          updatedCart[0].color = SelectedColor;
          updatedCart[0].userId = userAdress.data.user.user_id;
          updatedCart[0].productqty = SelectedQuantity;
          updatedCart[0].objectId = selectedTargetId;
          updatedCart[0].seller_wallet = product.seller_wallet;
          updatedCart[0].match = match1;
          updatedCart[0].allData = {
            pStoreId: product.store_id,
            pCategoryId: product.category_id,
            pChainId: 97,
            pObjectId: SelectedObjectedID,
            pSymbolId: product.symbol,
            pSize: SelectedSize,
            pColor: SelectedColor,
            pQty: SelectedQuantity,
            pMaxQty: SelectedMaxQty,
            pPrice: PriceNew,
            pDiscount: SelectedDiscountPercentage,
            pUserId: product.userId,
          };

          const combinedArray = [...storedArray, updatedCart[0]];
          localStorage.setItem('customArray', JSON.stringify(combinedArray));

          setCart(updatedCart);
          incrementCartCount();
          // setCartCount(updatedCart.length);
          toast.success('Product is Added in cart', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      }
    }
  };


  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Container className="mt-4 containerDiv">
        <div className="row">
          <div className="col-lg-4 hero-img py-1 justify-content-center">
            {getImage.length !== 0 ? (
              <div className="carousel-container">
                <Slider {...settings}>
                  {getImage.map((image, index) => (
                    <div className="">
                      <img src={image} className="" alt={`Imagee ${index + 1}`} />
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="square-img-wrapper mt-4">
                <img
                  src={NoImg}
                  className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                  alt={product.storeName}
                />
              </div>
            )}
          </div>
          <div className="col-lg-8 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-5">
            <h2 id="h2" style={{ textTransform: 'Capitalize' }}>{product.title}</h2>
            <h4 className="mb-0 mt-2">{PriceNew - PriceNew2} {product.symbol}</h4>
            {getIsDiscout && (
              <h5 className="mb-2 mt-0">
                <span style={{ textDecoration: 'line-through' }}>
                  {PriceNew} {product.symbol}
                </span>
                <Badge style={{ fontWeight: 400, marginLeft: '5px' }} pill bg="success">
                  {SelectedDiscountPercentage}% OFF
                </Badge>
              </h5>
            )}
            <div className="row px-2 py-4">
              <div data-aos="fade-up" data-aos-delay="300" className="col-12 text-center CreatorDiv1">
                <Row xs={2} md={6} lg={4} style={{ textTransform: 'Capitalize' }}>
                  <Col>
                    <p className="py-1 mb-0"> <b> Brand</b></p>
                    <p className="py-0 mb-2">{product.brand}</p>
                  </Col>
                  <Col>
                    <p className="py-1 mb-0"> <b> Condition</b></p>
                    <p className="py-0 mb-2">{product.product_condition}</p>
                  </Col>
                  <Col>
                    <p className="py-1 mb-0"> <b> Shipping</b></p>
                    <p className="py-0 mb-2">{product.deliveryCharges} {product.symbol}</p>
                  </Col>
                  <Col>
                    {
                      product.seller_wallet &&
                      <><p className="py-1 mb-0"> <b> Seller </b></p><p className="py-0 mb-2">{product.seller_wallet.name}</p></>
                    }
                  </Col>
                  <Col>
                    {product.approver_name && product.approver_name[0] && (
                      <span>
                        <p className="py-1 mb-0"><b>Approver</b></p>
                        <p className="py-0 mb-2">{product.approver_name[0].name}</p>
                      </span>
                    )}
                  </Col>
                  <>
                    {/* {product.product_sizes_quantity && product.product_sizes_quantity.length > 0 && (
                      <>

                        {product.product_sizes_quantity.length === 1 ? (
                          // Show the quantity of the first object if only one object exists
                          <Col>
                              <p className="py-1 mb-0">
                              <b>Total Quantity</b>
                            </p>
                            <p className="py-0 mb-2">
                              Quantity: {product.product_sizes_quantity[0].quantity || 1}
                            </p>
                          </Col>
                        ) : (
                          // Show each quantity in a separate Col for all objects
                          product.product_sizes_quantity.map((item) => (
                            <Col key={item._id}>
                              <p className="py-1 mb-0">
                                <p className='d-flex ' style={{
                                  fontWeight:'bold'
                                }}>Quantity for {item.color}</p>{item.quantity || 1}
                              </p>
                            </Col>
                          ))
                        )}
                      </>
                    )} */}
                  </>

                </Row>
              </div>
            </div>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="profile" title="Description">
                <p style={{ textTransform: 'capitalize' }} className="mb-2">{product.description}</p>
              </Tab>
              <Tab eventKey="detail" title="Details">
                <Row xs={2} md={6} lg={4} className="text-center">
                  {getIndexValues.includes('model') && (
                    <Col>
                      <p className="py-1"> <b style={{ fontWeight: '600' }}> Model </b></p>
                      <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">{SelectedModel}</h5>
                    </Col>
                  )}
                  {getIndexValues.includes('horse_power') && (
                    <Col>
                      <p className="py-1"> <b style={{ fontWeight: '600' }}> Horse Power </b></p>
                      <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">{SelectedHorsePower}</h5>
                    </Col>
                  )}
                  {getIndexValues.includes('transmission') && (
                    <Col>
                      <p className="py-1"> <b style={{ fontWeight: '600' }}> Transmission </b></p>
                      <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">{SelectedTransmission}</h5>
                    </Col>
                  )}
                  {getIndexValues.includes('registration') && (
                    <Col>
                      <p className="py-1"> <b style={{ fontWeight: '600' }}> Registration </b></p>
                      <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">{SelectedRegistration}</h5>
                    </Col>
                  )}
                  {getIndexValues.includes('storage') && (
                    <Col>
                      <p className="py-1"> <b style={{ fontWeight: '600' }}> Storage </b></p>
                      <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">{SelectedStorage}</h5>
                    </Col>
                  )}
                  {product.product_document ? (
                    <Col xs={12} md={6} lg={6}>
                      <p className="py-1 mb-1"> <b style={{ fontWeight: '600' }}> Documents </b></p>
                      {product.product_document.map((doc, index) => (
                        <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-success">
                          <a
                            href={doc}
                            target="_blank"
                            rel="noreferrer"
                            className="text-white"
                          >
                            Doc {index + 1}
                          </a>
                        </Button>
                      ))}
                    </Col>
                  ) : (
                    <Col>
                      <p className="py-1"> <b style={{ fontWeight: '600' }}>Product Document </b></p>
                      <h5 style={{ textTransform: 'capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">-</h5>
                    </Col>
                  )}
                </Row>
              </Tab>
            </Tabs>
            <hr className="mt-2 mx-0" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
            <div className="column">
              <div className='row'>
                {getIndexValues.includes('property_type') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Property Type</b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedPropertyType}
                          onChange={handlePropertyTypeChange}
                          options={propertyTypeVal}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIndexValues.includes('floor') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Floor {SelectedFloor}</b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedFloor}
                          onChange={handleFloorChange}
                          options={floorVal}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIndexValues.includes('square_size') && (
                  <div className="col-lg-2 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Square Size</b></p>
                    <input
                      type="text"
                      value={SelectedSquareSize}
                      name="SquareSize"
                      id="SquareSize"
                      placeholder="Square Size"
                      className="form-control mt-1"
                      disabled
                    />
                  </div>
                )}
                {getIndexValues.includes('size') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Size</b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedSize}
                          onChange={handleSizeChange}
                          options={sizeVal}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIndexValues.includes('storage') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Storage</b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedStorage}
                          onChange={handleStorageChange}
                          options={storageVal}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIndexValues.includes('model') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Model</b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedModel}
                          onChange={handleModelChange}
                          options={ModelVal}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIndexValues.includes('color') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"> <b> Color </b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedColor}
                          onChange={handleColorChange}
                          options={transformedProductColors}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIndexValues.includes('transmission') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Transmission</b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedTransmission}
                          onChange={handleTransmissionChange}
                          options={transmissionVal}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIndexValues.includes('horse_power') && (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-0"><b>Horse Power</b></p>
                    <InputDiv>
                      <DropdownDiv className="mb-0" style={{ textTransform: 'capitalize' }}>
                        <CustomSelect
                          name="color"
                          id="color"
                          className="w-25"
                          value={SelectedHorsePower}
                          onChange={handleHorsePowerChange}
                          options={horsePowerVal}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                )}
                {getIsProperty === 0 && (
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-2 px-0">
                    <div className="text-center d-flex gap-1">
                      <p className="">
                        <b>Quantity</b>
                      </p>
                      <p className="text-sm ">
                        <b>{QtyNew}</b>
                      </p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button className="btn-sm btn btn-light mt-2 w-100" type="button" onClick={() => handleQuantityChange(SelectedQuantity - 1)}>
                        <i className="fa fa-minus" />
                      </Button>
                      <Button
                        className="w-100"
                        style={{
                          border: '1px solid white', color: '#000000', paddingBottom: '3%', paddingTop: '3%',
                        }}
                        variant="dark px-2 mx-1 btn-sm btn mt-2"
                        type="button"
                      >
                        {SelectedQuantity}
                      </Button>
                      <Button className="btn-sm btn btn-light mt-2 w-100" type="button" onClick={() => handleQuantityChange(SelectedQuantity + 1)}>
                        <i className="fa fa-plus" />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label
                  htmlFor="approver"
                  className="form-label text-transparent mt-2"
                  style={{ color: 'rgba(0, 0, 0, 0)' }}
                >
                  &nbsp;
                </label>
                {QtyNew > 0 ? (
                  <div className="d-inline-flex flex-xl-row flex-column text-center">
                    <Button onClick={addToCart} variant="outline-success" style={{ fontSize: '14px', textTransform: 'none' }} className="btn mt-1 py-2 w-100">  Add to Cart <i className="ml-1 fa fa-shopping-cart" /></Button>
                    {product?.custom_offer_status && <Button onClick={() => setShowCustomOfferModal(true)} variant="outline-success" style={{ fontSize: '14px', textTransform: 'none' }} className="btn mt-1 py-2 w-100">  Send Custom Offer <i className="ml-2 fa fa-handshake-o" aria-hidden="true" /></Button>
                    }
                    {logedInUser !== product?.userId && <Link to={`/livechat?userId=${product?.userId}&name=${encodeURIComponent(product.seller_wallet?.name)}`}>
                      <Button variant="outline-success" style={{ fontSize: '14px', textTransform: 'none' }} className="btn mt-1 py-2 w-100"> Chat with Owner <i className="ml-1 fa fa-comments" /></Button>
                    </Link>}
                  </div>
                ) : (
                  <div className="text-center">
                    <h5
                      style={{ border: '1px solid #ffc107', borderRadius: '5px' }}
                      className="currentPrice text-warning mt-1 px-2 py-2"
                    >
                      Out of Stock
                    </h5>
                  </div>
                )}
                <br />
                <button hidden type="button" onClick={handleClearLocalStorage}>Clear Local Storage</button>
              </div>
            </div>
          </div>
        </div>
        {userFeatures.length > 0 && (
          <div className="row mb-2 px-4" style={{ textTransform: 'capitalize' }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <span className="mb-3">
                <h6 className="mb-2"> <b> Features : </b></h6>
                <div className="row mb-2">
                  {userFeatures.map((feature, index) => (
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <ul>
                        <li className="text-white">{feature}</li>
                      </ul>
                    </div>
                  ))}
                </div>
              </span>
            </div>
          </div>
        )}
        {/* <hr className="mt-2 mx-4" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} /> */}
        {/* <div className="row px-4">
          <div className="col-12">
            <h4>Ratings & Reviews</h4>
          </div>
        </div>
        <div className="row px-4">
          <div className="col-lg-4 col-md-6 col-sm-12 hero-img py-1 justify-content-center">
            <span className="d-flex mt-1 span1">
              <span>
                <h3>{Rating}</h3>
              </span>
              <span>
                <h3 style={{ color: '#8d8d8d' }}>/5</h3>
              </span>
            </span>
            <div className="Rating mb-0 text-center d-flex mt-0 span2">
              <ReviewStarRating rating={Rating} />
              <span style={{ fontSize: 'larger' }} className="text-white px-3 mt-4"> ({RatingCount} Reviews) </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 hero-img py-1 justify-content-center">
            <div>
              <span className="d-flex">
                <span className="mr-2" style={{ color: 'gold', fontSize: '20px' }}>&#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <span className="w-100">
                  <div className="progress" style={{ width: '100%', marginTop: '10px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      // eslint-disable-next-line max-len
                      style={{ width: `${(productViewCount.five_rating_count / productViewCountTotal) * 100}%`, background: 'gold', color: 'gold' }}
                      aria-valuenow={23}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      23
                    </div>
                  </div>
                </span>
                <span className="ml-2" style={{ color: 'white', fontSize: '20px' }}> {productViewCount.five_rating_count}
                </span>
              </span>
            </div>
            <div>
              <span className="d-flex">
                <span className="mr-2" style={{ color: 'gold', fontSize: '20px' }}>&#9733;&#9733;&#9733;&#9733;&#9734;
                </span>
                <span className="w-100">
                  <div className="progress" style={{ width: '100%', marginTop: '10px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      // eslint-disable-next-line max-len
                      style={{ width: `${(productViewCount.four_rating_count / productViewCountTotal) * 100}%`, background: 'gold', color: 'gold' }}
                      aria-valuenow={23}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      23
                    </div>
                  </div>
                </span>
                <span className="ml-2" style={{ color: 'white', fontSize: '20px' }}> {productViewCount.four_rating_count}
                </span>
              </span>
            </div>
            <div>
              <span className="d-flex">
                <span className="mr-2" style={{ color: 'gold', fontSize: '20px' }}>&#9733;&#9733;&#9733;&#9734;&#9734;
                </span>
                <span className="w-100">
                  <div className="progress" style={{ width: '100%', marginTop: '10px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      // eslint-disable-next-line max-len
                      style={{ width: `${(productViewCount.one_rating_count / productViewCountTotal) * 100}%`, background: 'gold', color: 'gold' }}
                      aria-valuenow={23}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      23
                    </div>
                  </div>
                </span>
                <span className="ml-2" style={{ color: 'white', fontSize: '20px' }}> {productViewCount.one_rating_count}
                </span>
              </span>
            </div>
            <div>
              <span className="d-flex">
                <span className="mr-2" style={{ color: 'gold', fontSize: '20px' }}>&#9733;&#9733;&#9734;&#9734;&#9734;
                </span>
                <span className="w-100">
                  <div className="progress" style={{ width: '100%', marginTop: '10px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      // eslint-disable-next-line max-len
                      style={{ width: `${(productViewCount.three_rating_count / productViewCountTotal) * 100}%`, background: 'gold', color: 'gold' }}
                      aria-valuenow={23}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      23
                    </div>
                  </div>
                </span>
                <span className="ml-2" style={{ color: 'white', fontSize: '20px' }}> {productViewCount.three_rating_count}
                </span>
              </span>
            </div>
            <div>
              <span className="d-flex">
                <span className="mr-2" style={{ color: 'gold', fontSize: '20px' }}>&#9733;&#9734;&#9734;&#9734;&#9734;
                </span>
                <span className="w-100">
                  <div className="progress" style={{ width: '100%', marginTop: '10px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      // eslint-disable-next-line max-len
                      style={{ width: `${(productViewCount.two_rating_count / productViewCountTotal) * 100}%`, background: 'gold', color: 'gold' }}
                      aria-valuenow={23}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      23
                    </div>
                  </div>
                </span>
                <span className="ml-2" style={{ color: 'white', fontSize: '20px' }}> {productViewCount.two_rating_count}
                </span>
              </span>
            </div>
          </div>
        </div> */}
        <div className="row mt-3 px-4">
          <div className="col-lg-7 order-1 order-lg-1 hero-img py-1 justify-content-center">
            {userReview.length > 0 && (
              <h5 className="mb-2">
                <b>Product Reviews :</b>
              </h5>
            )}
            {userReview.map(review => (
              <div>
                <hr className="mt-3" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
                <span className="bg-dark">
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-8 d-flex">
                      <span>
                        <p
                          className="mt-1 mr-2"
                          style={{ textTransform: 'Capitalize' }}
                        >
                          <i className="mr-1 fas fa-user-circle text-white" />{review.userDetails.name}
                        </p>
                      </span>
                      <span>
                        <div className="Rating mb-0 text-center mt-0">
                          <StarRating rating={review.ratting} />
                        </div>
                      </span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-4">
                      {(() => {
                        const originalDateString = review.createdAt;
                        const originalDate = new Date(originalDateString);
                        // eslint-disable-next-line max-len
                        const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;
                        return (
                          <p style={{ textTransform: 'capitalize', float: 'right' }}>
                            {`${formattedDate}`}
                          </p>
                        );
                      })()}
                    </div>
                  </div>
                </span>
                <p>{review.commit}</p>
              </div>
            ))}
          </div>
        </div>
        <hr className="mt-2 mx-4" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
      </Container>
      <SameProducts productIdNo={id} />
      {showCustomOfferModal && <CustomOfferModal SelectedColor={SelectedColor} showModal={showCustomOfferModal} product={product} closeModal={() => setShowCustomOfferModal(false)} />}
    </>
  );
};

export default ProductDetails;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;