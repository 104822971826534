import React, { useEffect, useRef } from "react";
import Pikaday from "pikaday";
import "pikaday/css/pikaday.css";

const DateRangeFilter = ({ onDateChange }) => {
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);

  useEffect(() => {
    const today = new Date(); // Get the current date

    const fromPicker = new Pikaday({
      field: fromDateRef.current,
      format: "YYYY-MM-DD",
      minDate: today, // Disable past dates
      onSelect: (date) => {
        onDateChange({ from: date, to: toDateRef.current.value });
      },
    });

    const toPicker = new Pikaday({
      field: toDateRef.current,
      format: "YYYY-MM-DD",
      minDate: today, // Disable past dates
      onSelect: (date) => {
        onDateChange({ from: fromDateRef.current.value, to: date });
      },
    });

    return () => {
      fromPicker.destroy();
      toPicker.destroy();
    };
  }, [onDateChange]);

  // Handle backspace key to clear dates
  const handleKeyDown = (e, type) => {
    if (e.key === "Backspace") {
      if (type === "from") {
        onDateChange({ from: "", to: toDateRef.current.value });
      } else if (type === "to") {
        onDateChange({ from: fromDateRef.current.value, to: "" });
      }
    }
  };

  return (
    <div className="d-flex gap-2">
      <input
        ref={fromDateRef}
        type="text"
        placeholder="From Date"
        className="border p-2 rounded bg-transparent text-white"
        onKeyDown={(e) => handleKeyDown(e, "from")}
      />
      <input
        ref={toDateRef}
        type="text"
        placeholder="To Date"
        className="border p-2 rounded bg-transparent text-white"
        onKeyDown={(e) => handleKeyDown(e, "to")}
      />
    </div>
  );
};

export default DateRangeFilter;
