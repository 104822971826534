/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainWrapper from '../MainWrapper';
import LogIn from '../../Account/LogIn/index';
import LogInNew from '../../Account/LogIn/indexNew';
import Register from '../../Account/Register/index';
import ResetPassword from '../../Account/ResetPassword/index';
import GetOTP from '../../../shared/components/account/login_register/OtpGet';
import ProtectedRoute from '../ProtectedRoute';
import ProtectedRouteNew from '../ProtectedRouteNew';
import PublicRoute from '../PublicRoute';
import Dashboard from '../../Dashboards/Dashboard';
import NotFoundPage from './WrappedRoutes/NotFoundPage';
import SendPage from '../../Dashboards/SendPage';
import Support from '../../Dashboards/Support';
import Chat from '../../Dashboards/Chat';
import RoomChat from '../../Dashboards/RoomChat';
import Finance from '../../Dashboards/Finance';
import Finance1 from '../../Dashboards/Finance1';
import Wallet from '../../Dashboards/Wallet';
import ReceivePage from '../../Dashboards/Receive';
import NewContractPage from '../../Dashboards/NewContract';
import AddSafeBoxPage from '../../Dashboards/AddSafebox';
import SafeBoxesOperations from '../../Dashboards/Operations/SafeBoxes';
import ContractOperations from '../../Dashboards/Operations/Contract';
import Catalog from '../../Dashboards/ECommerce/Catalog';
import MetamaskPage from '../../Dashboards/MetaMask';
import OrdersList from '../../Dashboards/ECommerce/OrdersList';
import Payment from '../../Dashboards/ECommerce/Payment';
import ProductPage from '../../Dashboards/ECommerce/ProductPage';
import Cart from '../../Dashboards/ECommerce/Cart';
import ProductsList from '../../Dashboards/ECommerce/ProductsList';
import Profile from '../../Account/Profile/index';
import Calendar from '../../DefaultPage/Calendar/index';
import Todo from '../../Todo/index';
import Mail from '../../Mail/index';
import ProductEdit from '../../Dashboards/ECommerce/ProductEdit';
import CreateProduct from '../../../store/Layout/product/CreateProduct';
import CreateCheckoutView from '../../../store/Layout/product/checkoutView';
import Convertor from '../../Dashboards/Finance/Convertor';
import Whitepaper from '../../Dashboards/Finance/Whitepaper';
import Roadmap from '../../Dashboards/Finance/Roadmap';
import Token from '../../Dashboards/Finance/Token';
import Products from '../../../store/Layout/product/Products';
import Services from '../../../store/Layout/service/Services';
import CreateServices from '../../../store/Layout/service/CreateServices';
import ProductDetails from '../../../store/Layout/product/ProductDetails';
import ProductCart from '../../../store/Layout/product/ProductCart';
import Checkout from '../../../store/Layout/product/ProductInvoice';
import ServiceDetails from '../../../store/Layout/service/ServiceDetails';
import MarketDashboard from '../../../store/Layout/dashboard/MarketDashboard';
import Home from '../../../store/Layout/home/Home';
import HomeProducts from '../../../store/Layout/home/HomeProducts';
import CreateStore from '../../../store/Layout/createstore/CreateStore';
import CreateStoreWallet from '../../../store/Layout/createstore/index';
import StoreDetail from '../../../store/Layout/storedetail/StoreDetail';
import StoreCard from '../../../store/Layout/storedetail/StoreCard';
import EditStore from '../../../store/Layout/editstore/EditStore';
import StoreDetailUser from '../../../store/Layout/storedetail/StoreDetailUser';
import StoreDetailNew from '../../../store/Layout/storedetail/StoreDetailNew';
import GetMyOrders from '../../../store/Layout/orders/GetMyOrders';
import GetMarketplaceContracts from '../../../store/Layout/orders/GetMarketplaceContracts';
import GeneralContracts from '../../../store/Layout/orders/GeneralContracts';
import GetMyApproval from '../../../store/Layout/orders/GetMyApproval';
import GetMyOrderAsBuyer from '../../../store/Layout/orders/GetMyOrderAsBuyer';
import CustomOffer from '../../../store/Layout/custom-offer/CustomOffer';
import OtcMarketplaceLists from '../../../shared/components/OtcMarketplace/OtcMarketplaceLists';
import OtcMarketplaceDetail from '../../../shared/components/OtcMarketplace/OtcMarketplaceDetails';
import PoolHolders from '../../Dashboards/PoolHolders/PoolHolders';
import SubAccounts from '../../Layout/SubAccounts/SubAccounts';
import BalanceInfo from '../../Dashboards/BalanceInfo/BalanceInfo';
import AllTransectionTable from '../../../shared/components/OtcMarketplace/AllTransectionTable';
import GetAllSolBalanceUser from '../../Dashboards/GetAllSolBalanceUser/GetAllSolBalanceUser';
import { getSubAccountType } from '../../../utils/services/user.service';
import NewHomePage from '../../../store/Layout/home/NewHomePage';
import ReactGA from 'react-ga';
import Swap from '../../Dashboards/Swap/Swap';
import TimelineHistory from '../../UI/Timeline/components/TimelineHistory';
import PrivacyNotice from '../../../store/Layout/home/PrivacyNotice';
import CookiePolicy from '../../../store/Layout/home/CookiePolicy';
import FAQS from '../../Dashboards/Finance/FAQS';
import CreateProject from '../../../store/Layout/Projects/CreateProjects';
import ProjectVoting from '../../../store/Layout/Projects/ProjectVoting';
import MyProject from '../../../store/Layout/Projects/MyProject';
import ProjectOtc from '../../../store/Layout/Projects/ProjectOtc';
import UserRequests from '../../../store/Layout/Projects/UserRequests';
import AllProjects from '../../../store/Layout/Projects/AllProjects';
import ProjectAccess from '../../../store/Layout/Projects/ProjectAccess';
import MyPtojectDetails from '../../../store/Layout/Projects/MyProjectDetails';
import ProductTrackingDetails from '../../../store/Layout/Projects/ProductTrackingDetails';
import MediaDetailPage from '../../../store/Layout/Projects/MediaDetailPage';
import CustodiyServices from '../../Dashboards/Services/CustodiyServices';


const Router = () => {
  const { isLoggedIn } = useSelector(state => state.user);
  const [accountType, setAccountType] = useState(null)

  const user = JSON.parse(localStorage.getItem('user'))

  const adimLogin = user?.token && user?.data?.user.email === "asim92578@gmail.com"


  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location?.pathname + location.search);
  }, [location]);
  const fetchAccountType = async () => {
    const payload = {
      user_id: user?.data?.user?.user_id
    }
    const res = await getSubAccountType(payload)

    setAccountType(res.data.type)
  }
  React.useEffect(() => {
    fetchAccountType()
  }, [isLoggedIn]);


  return (
    <MainWrapper>
      <main>
        <Switch>
          {/* <PublicRoute path="/" isAuthenticated={isLoggedIn}>
            <LogIn />
          </PublicRoute> */}
          {/* <PublicRoute exact path="/" isAuthenticated={isLoggedIn}>
            <LogIn />
          </PublicRoute> */}
          <ProtectedRouteNew exact path="/">
            <NewHomePage />
          </ProtectedRouteNew>
          <PublicRoute path="/login" isAuthenticated={isLoggedIn}>
            <LogIn />
          </PublicRoute>
          <PublicRoute path="/Admin_login" >
            <LogInNew />
          </PublicRoute>
          <PublicRoute path="/register" isAuthenticated={isLoggedIn}>
            <Register />
          </PublicRoute>
          <PublicRoute path="/reset_password" isAuthenticated={isLoggedIn}>
            <ResetPassword />
          </PublicRoute>
          <PublicRoute path="/Webapistore" isAuthenticated={isLoggedIn}>
            <GetOTP />
          </PublicRoute>




          {/* <ProtectedRoute path="/dashboard" isAuthenticated={isLoggedIn}>
            <Dashboard />
          </ProtectedRoute> */}

          <ProtectedRoute path="/send" isAuthenticated={isLoggedIn}>
            <SendPage />
          </ProtectedRoute>
          <ProtectedRouteNew path="/support" isAuthenticated={isLoggedIn}>
            <Support />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/chat" isAuthenticated={isLoggedIn}>
            <Chat />
          </ProtectedRouteNew>
          <ProtectedRoute path="/receive" isAuthenticated={isLoggedIn}>
            <ReceivePage />
          </ProtectedRoute>
          <ProtectedRoute path="/new-contract" isAuthenticated={isLoggedIn}>
            <NewContractPage />
          </ProtectedRoute>
          <ProtectedRoute path="/safebox" isAuthenticated={isLoggedIn}>
            <AddSafeBoxPage />
          </ProtectedRoute>
          <ProtectedRoute path="/safebox-history" isAuthenticated={isLoggedIn}>
            <SafeBoxesOperations />
          </ProtectedRoute>
          <ProtectedRoute path="/history" isAuthenticated={isLoggedIn}>
            <ContractOperations />
          </ProtectedRoute>
          <ProtectedRoute path="/wallets" isAuthenticated={isLoggedIn}>
            <MetamaskPage />
          </ProtectedRoute>
          <ProtectedRoute path="/e-commerce/catalog" isAuthenticated={isLoggedIn}>
            <Catalog />
          </ProtectedRoute>
          <ProtectedRoute path="/e-commerce/orders_list" isAuthenticated={isLoggedIn}>
            <OrdersList />
          </ProtectedRoute>
          <ProtectedRoute path="/e-commerce/payment" isAuthenticated={isLoggedIn}>
            <Payment />
          </ProtectedRoute>
          <ProtectedRoute path="/e-commerce/product_page" isAuthenticated={isLoggedIn}>
            <ProductPage />
          </ProtectedRoute>
          <ProtectedRoute path="/e-commerce/cart" isAuthenticated={isLoggedIn}>
            <Cart />
          </ProtectedRoute>
          <ProtectedRoute path="/e-commerce/products_list" isAuthenticated={isLoggedIn}>
            <ProductsList />
          </ProtectedRoute>
          <ProtectedRoute path="/ProductEdit/:id" isAuthenticated={isLoggedIn}>
            <ProductEdit />
          </ProtectedRoute>
          <ProtectedRoute path="/createproduct" isAuthenticated={isLoggedIn}>
            <CreateProduct />
          </ProtectedRoute>
          <ProtectedRoute path="/products" isAuthenticated={isLoggedIn}>
            <Products />
          </ProtectedRoute>
          <ProtectedRouteNew path="/services" >
            <Services />
          </ProtectedRouteNew>
          <ProtectedRoute path="/createservices" isAuthenticated={isLoggedIn}>
            <CreateServices />
          </ProtectedRoute>
          <ProtectedRouteNew path="/productdetails">
            <ProductDetails />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/mediaDetail/:id">
            <MediaDetailPage />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/showcart">
            <ProductCart />
          </ProtectedRouteNew>
          <ProtectedRoute path="/checkout" isAuthenticated={isLoggedIn}>
            <CreateCheckoutView />
          </ProtectedRoute>
          <ProtectedRoute path="/servicedetails" isAuthenticated={isLoggedIn}>
            <ServiceDetails />
          </ProtectedRoute>
          <ProtectedRoute path="/livechat" isAuthenticated={isLoggedIn}>
            <RoomChat />
          </ProtectedRoute>
          <ProtectedRoute path="/profile" isAuthenticated={isLoggedIn}>
            <Profile />
          </ProtectedRoute>
          <ProtectedRoute path="/default_pages/calendar" isAuthenticated={isLoggedIn}>
            <Calendar />
          </ProtectedRoute>
          <ProtectedRoute path="/todo" isAuthenticated={isLoggedIn}>
            <Todo />
          </ProtectedRoute>
          <ProtectedRoute path="/timeline" isAuthenticated={isLoggedIn}>
            <TimelineHistory />
          </ProtectedRoute>
          <ProtectedRoute path="/subaccounts" isAuthenticated={isLoggedIn}>
            <SubAccounts />
          </ProtectedRoute>
          <ProtectedRoute path="/mail" isAuthenticated={isLoggedIn}>
            <Mail />
          </ProtectedRoute>
          <ProtectedRouteNew path="/home" >
            <Home />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/homeproducts">
            <HomeProducts />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/privacy-notice" >
            <PrivacyNotice />
          </ProtectedRouteNew>

          <ProtectedRouteNew path="/cookie-policy" >
            <CookiePolicy />
          </ProtectedRouteNew>

          <ProtectedRouteNew path="/services" >
            <CustodiyServices />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/finance" isAuthenticated={isLoggedIn}>
            <Finance1 />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/swap">
            <Swap />
          </ProtectedRouteNew>
          <ProtectedRoute path="/dashboard" type={accountType} isAuthenticated={isLoggedIn}>
            <Wallet />
          </ProtectedRoute>
          <ProtectedRoute path="/balanceinfo" isAuthenticated={isLoggedIn}>
            <BalanceInfo />
          </ProtectedRoute>
          <ProtectedRouteNew path="/walletActivities">
            <Finance />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/convertor">
            <Convertor />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/whitepaper">
            <Whitepaper />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/faqs">
            <FAQS />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/roadmap">
            <Roadmap />
          </ProtectedRouteNew>
          <ProtectedRouteNew path="/token">
            <Token />
          </ProtectedRouteNew>
          <ProtectedRoute path="/marketdashboard" isAuthenticated={isLoggedIn}>
            <MarketDashboard />
          </ProtectedRoute>
          <ProtectedRoute path="/createstore" isAuthenticated={isLoggedIn}>
            <CreateStoreWallet />
          </ProtectedRoute>
          <ProtectedRoute path="/storecard" isAuthenticated={isLoggedIn}>
            <StoreCard />
          </ProtectedRoute>
          <ProtectedRoute path="/storedetail" isAuthenticated={isLoggedIn}>
            <StoreDetail />
          </ProtectedRoute>
          <ProtectedRoute path="/editstore/:storeId" isAuthenticated={isLoggedIn}>
            <EditStore />
          </ProtectedRoute>
          <ProtectedRoute path="/storedetailuser/:storeId" isAuthenticated={isLoggedIn}>
            <StoreDetailUser />
          </ProtectedRoute>
          <ProtectedRouteNew path="/storedetailnew/:storeId/:storename">
            <StoreDetailNew />
          </ProtectedRouteNew>

          <ProtectedRoute path="/getmyorders" isAuthenticated={isLoggedIn}>
            <GetMyOrders />
          </ProtectedRoute>
          <ProtectedRoute path="/getmarketplacecontracts" isAuthenticated={isLoggedIn}>
            <GetMarketplaceContracts />
          </ProtectedRoute>
          <ProtectedRoute path="/contractslist" isAuthenticated={isLoggedIn}>
            <GeneralContracts />
          </ProtectedRoute>
          <ProtectedRoute path="/poolholders" isAuthenticated={isLoggedIn}>
            <PoolHolders />
          </ProtectedRoute>
          <ProtectedRoute path="/getmyapproval" isAuthenticated={isLoggedIn}>
            <GetMyApproval />
          </ProtectedRoute>
          <ProtectedRoute path="/getmyorderasbuyer" isAuthenticated={isLoggedIn}>
            <GetMyOrderAsBuyer />
          </ProtectedRoute>
          <ProtectedRoute path="/customoffers" isAuthenticated={isLoggedIn}>
            <CustomOffer />
          </ProtectedRoute>
          <ProtectedRoute path="/otcmarketplacelists" type={accountType} isAuthenticated={isLoggedIn}>
            <OtcMarketplaceLists />
          </ProtectedRoute>
          <ProtectedRoute path="/otcmarketplacedetail" type={accountType} isAuthenticated={isLoggedIn}>
            <OtcMarketplaceDetail />
          </ProtectedRoute>
          <ProtectedRoute path="/CreateProject" isAuthenticated={isLoggedIn}>
            <CreateProject />
          </ProtectedRoute>
          <ProtectedRoute path="/project-voting" isAuthenticated={isLoggedIn}>
            <ProjectVoting />
          </ProtectedRoute>
          <ProtectedRoute path="/my-project" isAuthenticated={isLoggedIn}>
            <MyProject />
          </ProtectedRoute>
          <ProtectedRoute path="/project-otc" isAuthenticated={isLoggedIn}>
            <ProjectOtc />
          </ProtectedRoute>
          <ProtectedRoute path="/user-requests/:projectId" isAuthenticated={isLoggedIn}>
            <UserRequests />
          </ProtectedRoute>
          <ProtectedRoute  path="/project-detail"isAuthenticated={isLoggedIn}>
            <MyPtojectDetails />
          </ProtectedRoute>
          {/* <ProtectedRoute  path="/project-detail/:projectId"isAuthenticated={isLoggedIn}>
            <MyPtojectDetails />
          </ProtectedRoute> */}
          <ProtectedRoute path="/product-detail/:productId" isAuthenticated={isLoggedIn}>
            <ProductTrackingDetails />
          </ProtectedRoute>
          <ProtectedRoute path="/get-access/:projectId" isAuthenticated={isLoggedIn}>
            <ProjectAccess />
          </ProtectedRoute>

          <ProtectedRoute path="/all-projects" isAuthenticated={isLoggedIn}>
            <AllProjects />
          </ProtectedRoute>
          <ProtectedRoute path="/getallsolbalanceuser" isAuthenticated={isLoggedIn}>
            <GetAllSolBalanceUser />
          </ProtectedRoute>
          {/* <Route exact path="/" component={LogIn} />
        <Route path="/login" component={LogIn} />
        <Route path="/register" component={Register} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/Webapistore" component={GetOTP} /> */}
          {/* <ProtectedRoute exact path="/" component={WrappedRoutes} /> */}
          {/* <Route path="/" component={WrappedRoutes} /> */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </main>
    </MainWrapper>
  );
};
export default Router;
