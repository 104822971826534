/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react";
import "./create.css";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { Ring } from "@uiball/loaders";
import styled from "styled-components";
import { FormLabel, listItemTextClasses } from "@mui/material";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal as BootstrapModal, Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// eslint-disable-next-line import/no-extraneous-dependencies
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as Yup from "yup";
import config from "@/config/walletConnect";
import { colorAccent } from "@/utils/palette";
import { Button } from "../../../shared/components/Button";
import Abi from "../../../containers/Dashboards/NewContract/component/Abi/tokenAbi.json";
import { SpinnerDiv } from "../../../containers/Dashboards/Operations/Contract/component/ContractTable";
import getCountry from "./country.json";

import {
  createMarketPlaceInternal,
  getCategory,
  transferStoreFee,
  checkStore,
  getApprovers,
  createMarketPlaceExternal,
  getFee,
  verifyTFAEmail,
  getExternalStoreFee,
  getAdminWallet
} from "../../../utils/services/user.service";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import WebCam from "../../../shared/components/WebCam/WebCam";

const {
  REACT_APP_MATIC_USDT_COMISSION_ADDRES,
  REACT_APP_BNB_USDT_COMISSION_ADDRESS,
  REACT_APP_ETH_USDT_COMISSION_ADDRESS,
  REACT_APP_BNB_CTY_COMISSION_ADDRESS,
  REACT_APP_ETHERIUM_CHAIN,
  REACT_APP_SMART_CHAIN,
  REACT_APP_POLYGON_MATIC,
  projectId,
  REACT_APP_STORE_CREATE_FEE,
  REACT_APP_ADMIN_WALLET_ADDRESS_FOR_COMMISION,
} = config;
const DropdownDiv = styled.div`
  display: block;
  float: right;
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const CreateStore = ({ benficiaryToggle, chain }) => {
  // console.log(chain);
  const [msg, setMsg] = useState("");
  const account = useAccount(); // Get External wallet status
  // console.log('wallet address ===>>>>>', account.address);
  // const {
  //   projectId,
  //   REACT_APP_ETHERIUM_CHAIN,
  //   REACT_APP_SMART_CHAIN,
  //   REACT_APP_POLYGON_MATIC,
  // } = config;
  const [categories, setCategories] = useState([]);
  const [selectedChain, setSelectedChain] = useState(REACT_APP_SMART_CHAIN);
  const [selectedSymbol, SetSelectedSymbol] = useState();
  const [selectedSymbolFee, SetSelectedSymbolFee] = useState("USDT");
  const [contractInstance, SetContractInstance] = useState({});
  const [approverWalletAddress, setApproverWalletAddress] = useState("");
  const [deliveryAvailableCountries, setDeliveryAvailableCountries] = useState(
    []
  );
  const [selectedType, setSelectedType] = useState("")
  const [selectedCountries, setSelectedCountries] = useState([]);

  const [onlineDeliveryAvailable, setOnlineDeliveryAvailable] = useState(false);
  const [Approvers, setApprovers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const [getLogo, setLogo] = useState(null);
  const fileInputRef = useRef(null);
  const [getApproverID, setApproverID] = useState("");
  const [selectedToken, setSelectedToken] = useState("USDT");
  const [tokenBalance, settokenBalance] = useState(null);
  const [coinBalance, setcoinBalance] = useState(null);
  const [showTokenDropdown, setShowTokenDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [Fee, setFee] = useState(0);
  const [load11, setLoad11] = useState(false);
  const [tokenArray, setTokenArray] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isTfaVarified, setIsTfaVarified] = useState(false);
  const [adminWallet, setAdminWallet] = useState("");
  const userSessionData = JSON.parse(localStorage.getItem("user"));
  const [tfaCode, setTfaCode] = useState("");

  const handleTfaCode = async () => {
    const requestData = {
      token: tfaCode,
      email: userSessionData.data.user.email,
    };


    try {
      setIsLoading(true);
      const res = await verifyTFAEmail(requestData);

      if (res) {
        setIsLoading(false);
        if (res.data.verified === false) {
          setTfaCode("");
          toast.error("Incorrect TFA Code", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.verified === true) {
          setIsTfaVarified(true);
          toast.success("Verify TFA Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsLoading(false);
          setTfaCode("");
          toast.error("Verify TFA failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setIsLoading(false);
        console.error("verifyTFA failed:", res);
      }
    } catch (error) {

      setIsLoading(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [chainDetails, setChainDetails] = useState({
    ETH: { chainId: Number(REACT_APP_ETHERIUM_CHAIN), symbol: "USDT" },
    BNB: { chainId: Number(REACT_APP_SMART_CHAIN), symbol: "USDT" },
    MATIC: { chainId: Number(REACT_APP_POLYGON_MATIC), symbol: "USDT" },
    // BTC: { chainId: 'BTC', symbol: 'USDT' },
  }); // Initialize an empty object
  const history = useHistory();

  const [showWebCam, setShowWebCap] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [imageSelfie, setImageSelfie] = useState(null);

  const fileInputRef2 = useRef(null);
  const fileSelfieUpload = useRef(null);




  function base64ToFile(base64String, filename) {
    if (
      filename && base64String
    ) {
      // Split the base64 string to get the content type and the data
      const parts = base64String.split(';base64,');
      const contentType = parts[0]?.split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw?.length;
      const uint8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw?.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: contentType });
      return new File([blob], filename, { type: contentType });
    }
  }
  const capturedImageFile = base64ToFile(capturedImage, "captured_image.png");

  const handleCaptureImage = (imageSrc) => {
    // Handle the captured image here (e.g., upload it to a server)
    setCapturedImage(imageSrc);
    setShowWebCap(false); // Hide the webcam modal after capturing the image
  };
  const handleButtonSelfieClick = () => {
    fileSelfieUpload.current.click();
  };
  const handleSelfieUpload = (e) => {
    const selectedImage = e.target.files[0];
    setImageSelfie(selectedImage);
  };
  const validationSchema = Yup.object({
    storeName: Yup.string().required("Store Name is required"),
    storeAddress: Yup.string().required("Store Address is required"),
    store_category_id: Yup.string().required("Category is required"),
    File: Yup.mixed().required("Store Logo is required"),
    storeDescription: Yup.string().required("Store Description is required"),
  });
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategory();
        console.log("🚀 ~ fetchCategories ~ response:", response)
        if (response.data.status) {
          const categoryData = response.data.category;
          // console.log('categoryData >>>>>>>>>>>>>>>>> ', categoryData);
          setCategories(categoryData);
        } else {
          console.error("Error fetching categories:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    const fetchAdminWallet = async () => {
      try {
        const response = await getAdminWallet();
        setAdminWallet(response?.data?.admin_address)
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
    fetchAdminWallet()
  }, []);

  useEffect(() => {
    if (benficiaryToggle === "external") {
      if (chain === 97 || chain === 56) {
        setShowTokenDropdown(true);
        setTokenArray([
          // { value: "CTY", label: "CTY" },
          { value: "USDT", label: "USDT" },
        ]);
      } else {
        setShowTokenDropdown(false);
        setTokenArray([{ value: "USDT", label: "USDT" }]);
      }
    }
  }, [chain, benficiaryToggle]);

  const fetchCountriesData = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const countriesData = await response.json();
      return countriesData;
    } catch (error) {
      console.error("Error fetching countries:", error);
      return [];
    }
  };
  useEffect(() => {
    fetchCountriesData().then((data) => {
      setDeliveryAvailableCountries(data);
    });
  }, []);

  useEffect(() => {
    const fetchApproverData = async () => {
      try {
        const response = await getApprovers(); // Replace with your API call for approvers
        console.log("🚀 ~ fetchApproverData ~ response:", response)
        if (response.data && response.data.user.length > 0) {
          const approverInfo = response.data.user; // Extract the first approver
          setApprovers(approverInfo);
        } else {
          console.error("Error fetching approver data:", response);
        }
      } catch (error) {
        console.error("Error fetching approver data:", error);
      }
    };
    fetchApproverData();
  }, []);
  // useEffect(() => {
  const fetchgetFee = async (value) => {
    setLoad11(true);
    try {
      const requestData = {
        type: "store_fee",
        categoryId: value,
      };
      const response = await getExternalStoreFee(requestData);
      if (response.data) {
        const feeData = response.data.data;
        // const feeType = (feeData?.percentage > 0) ? "percentage" : "amount";
        let storeCreationFee = 0;
          storeCreationFee = feeData.fee ? Number(feeData.fee) : 0;
        setFee(storeCreationFee);
      } else {
        console.error("getFee:", response.statusText);
      }

      setLoad11(false);
    } catch (error) {
      setLoad11(false);
      setFee(0);
      console.error("getFee Activities:", error);

      if (error.response) {
        if (error.response.data && error.response.data.Message) {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
  };

  const optionChain = [
    // { value: 'ETH', label: 'Etherium' },
    { value: "BNB", label: "Smart chain" },
    // { value: 'MATIC', label: 'Polygon matic' },
    // { value: 'BTC', label: 'BTC' },
  ];

  const optionType = [
    { value: 'seller', label: 'Seller' },
    { value: "agency", label: "Agency" },
  ];

  const handleButtonClickClick = () => {
    fileInputRef.current.click();
  };

  const handleApproverChange = (event) => {
    const selectedID = event.value;
    setApproverID(selectedID);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleChainChange = (value) => {
    // console.log('Selected value:', value);
    if (value.value === "BNB") {
      setShowTokenDropdown(true);
    } else {
      setShowTokenDropdown(false);
      setSelectedToken("USDT");
      setChainDetails((prevDetails) => ({
        ...prevDetails,
        BNB: { ...prevDetails.BNB, symbol: "USDT" }, // Reset BNB symbol when changing chain
      }));
    }
    setSelectedChain(value.value);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value.value);
  }

  const handleTokenChange = (value) => {
    setSelectedToken(value.value);
    if (selectedChain === "BNB") {
      setChainDetails((prevDetails) => ({
        ...prevDetails,
        BNB: { ...prevDetails.BNB, symbol: value.value },
      }));
    }
  };
  const handleImageUpload = (event) => {
    const imagePreview = document.getElementById("imagePreview");
    const selectedImage = event.target.files[0];
    setLogo(selectedImage);
    if (selectedImage) {
      imagePreview.src = URL.createObjectURL(selectedImage);
    }
  };
  const web3 = new Web3(window.ethereum);
  async function checkTokenBalance() {
    try {
      if (account.address !== undefined) {
        let contractAddress = "";
        if (Number(REACT_APP_ETHERIUM_CHAIN) === Number(chain)) {
          contractAddress = REACT_APP_ETH_USDT_COMISSION_ADDRESS;
        } else if (Number(REACT_APP_SMART_CHAIN) === Number(chain)) {
          if (selectedSymbolFee === "USDT") {
            contractAddress = REACT_APP_BNB_USDT_COMISSION_ADDRESS;
          } else {
            contractAddress = REACT_APP_BNB_CTY_COMISSION_ADDRESS;
          }
        } else {
          contractAddress = REACT_APP_MATIC_USDT_COMISSION_ADDRES;
        }
        const tokenContract = new web3.eth.Contract(Abi, contractAddress);
        SetContractInstance(tokenContract);
        const tokenbalance = await tokenContract.methods
          .balanceOf(account.address)
          .call();
        settokenBalance(tokenbalance);
      } else {
        setMsg("");
      }
    } catch (error) {
      setMsg(`Catch Error:${error?.message}`);
      // console.log(error);
    }
  }

  async function checkCoinBalance() {
    if (account.address !== undefined) {
      await web3.eth
        .getBalance(account.address)
        .then((balance) => {
          // console.log(balance);
          const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
          const coinBalances = Number(
            web3.utils.fromWei(balanceInWei, "ether")
          ); // Convert to Ether
          setcoinBalance(Number(coinBalances));
        })
        .catch((error) => {
          setMsg(error);
          setcoinBalance(0);
        });
    } else {
      setMsg("");
    }
  }
  useEffect(() => {
    checkTokenBalance();
  }, [benficiaryToggle]);

  const handleFileRemove = () => {
    setFile(null);
    toast.success("Document Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };


  const handleCreateStore = async (values) => {
    try {
      setIsLoading(true);
      // Check if the user already has a store
      const existingStoreResponse = await checkStore();
      // console.log('Response from checkStore:', existingStoreResponse);

      if (!existingStoreResponse.data.success) {
        // User already has a store, show an error message or take appropriate action
        // console.log('User already has a store');
        return;
      }

      // Define details and transactionHash variables
      let details = null;
      let transactionHash = "";

      if (benficiaryToggle === "external") {

        // const externalFee = await getExternalStoreFee(payload)
        // if (Fee === 0) {
        //   toast.error(
        //     "Admin has not set a fee for this category please contact the admin and try again.",
        //     {
        //       position: toast.POSITION.TOP_RIGHT,
        //     }
        //   );
        //   setIsLoading(false);

        //   return;
        // }
        details = {
          symbol: selectedToken,
          chainId: chain,
        };

        // Handle logic for external wallet here...
        if (window.ethereum
          // && Fee > 0
        ) {
          await checkCoinBalance();

          if (coinBalance === 0 || !coinBalance || coinBalance < 300000) {
            setMsg("Not Enough Gas to create store");
          }

          await checkTokenBalance();

          if (Number(tokenBalance) < Number(Fee)) {
            setMsg("Insufficient Fund!");
          }
          const storeFreeConverted = web3.utils.toWei(Fee.toString(), "ether");

          try {
            const instance = new Web3(window.ethereum);
            const receipt = await contractInstance.methods
              .transfer(
                adminWallet,
                storeFreeConverted
              )
              .send({
                from: account.address,
                gasPrice: instance.utils.toWei("5", "gwei"),
                gasLimit: 3000000,
              });

            transactionHash = receipt.transactionHash;
          } catch (err) {
            // console.log("recipet error ===>>>>", err);
          }
        }
      } else {
        details = chainDetails[selectedChain];
      }
      // Create the form data with all necessary values
      const formData = new FormData();
      formData.append("storeName", values.storeName);
      formData.append("store_category_id", selectedCategory);
      formData.append("storeAddress", values.storeAddress);
      formData.append("storeDescription", values.storeDescription);
      formData.append("File", getLogo);
      formData.append("File2", file);
      formData.append("chainId", details.chainId);
      formData.append("symbol", details.symbol);
      formData.append("approver_wallet_address", getApproverID);
      formData.append("store_type", selectedType);
      formData.append(
        "delivery_available_countries",
        JSON.stringify(selectedCountries.value)
      );
      formData.append(
        "online_delivery_available",
        values.online_delivery_available ? "true" : "false"
      );
      //  the benficiaryToggle and make the appropriate API call
      if (benficiaryToggle === "external") {
        formData.append("hash", transactionHash);
        formData.append("store_creation_fee", 2);
        const externalResponse = await createMarketPlaceExternal(formData);
        if (externalResponse.status === 200) {
          // console.log('External store response:', externalResponse);
          // console.log('External Store created successfully');
          toast.success("External Store Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // history.push(`/storecard/${externalResponse.data.storeId}`);
          history.push("/storedetail");
        } else {
          console.error(
            `Error creating external store: ${externalResponse.statusText}`
          );
          toast.error("Error creating external store", externalResponse.Error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        // console.log('testingggg');
        const internalResponse = await createMarketPlaceInternal(formData);
        // console.log('internalResponse', internalResponse.data);
        if (internalResponse.data.status === 200) {
          // console.log('Internal store response:', internalResponse);
          // console.log('Internal Store created successfully');
          toast.success("Internal Store Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // history.push(`/storecard/${internalResponse.data.storeId}`);
          history.push("/storedetail");
        } else {
          console.error(
            `Error creating internal store: ${internalResponse.statusText}`
          );
          toast.error("Error creating internal store", internalResponse.Error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating store:", error);
      toast.error(
        `Error creating store: ${error.response?.data?.Error || error.message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      if (error.response) {
        if (error.response.data && error.response.data.Message) {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
  };

  // ... rest of your code ...
  const animatedComponents = makeAnimated();

  const approverOptions = Approvers.map((approver) => ({
    value: approver.address,
    label: approver.name,
  }));

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <h2 className="text-center mb-4">Create a Store</h2>
      <div className="container mt-3 mb-3 d-flex justify-content-center">
        <Formik
          initialValues={{
            storeName: "",
            storeAddress: "",
            store_category_id: "",
            File: null,
            File2: null,
            storeDescription: "",
            selectedChain: REACT_APP_SMART_CHAIN,
            selectedToken: "USDT",
            approver_wallet_address: "",
            delivery_available_countries: [],
            online_delivery_available: false,
            store_type: "",
          }}
          onSubmit={(values, { resetForm }) => {
            // console.log('values');
            // console.log(values);
            handleCreateStore(values);
          }}
          validate={(values) => {
            const errors = {};
            setShowAlert(false);

            if (!values.storeName) {
              errors.storeName = "Store Name is Required!";
            }
            if (!values.storeAddress) {
              errors.storeAddress = "Address is Required!";
            }
            if (!selectedCategory) {
              errors.store_category_id = "Category is Required!";
            }
            if (selectedType === "") {
              errors.store_type = 'Store Type is Required!';
            }
            if (!file) {
              errors.File2 = "Document is Required!";
            }
            if (!getLogo) {
              errors.File = "Store Logo is Required!";
            }
            if (!values.storeDescription) {
              errors.storeDescription = "Description is Required!";
            }
            if (selectedCountries.length === 0) {
              errors.delivery_available_countries =
                "Delivery Available Country is Required!";
            }

            return errors;
          }}

        // onSubmit={handleCreateStore}
        // validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form className="align-items-start w-100" onSubmit={handleSubmit}>
                <div className="row px-0">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="row px-0">
                      <div className="col-md-12">
                        <div className="image-preview">
                          <img
                            src="https://via.placeholder.com/500"
                            alt="Uploaded Logo"
                            id="imagePreview"
                            className="uploaded-image createImg w-100 mt-4"
                          />
                        </div>
                        <div className="overlay-text-new mt-2 px-2">
                          <label
                            className="change-logo-icon cam"
                            htmlFor="logoInput"
                          >
                            <FontAwesomeIcon
                              icon={faCamera}
                              style={{ color: "#ffffff" }}
                            />
                            <input
                              id="logoInput"
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(event) => {
                                handleImageUpload(event); // Assuming this function remains the same
                              }}
                            />
                          </label>
                        </div>
                        {errors.File && touched.File && (
                          <ErrorMsg className="input feedback">
                            {errors.File}
                          </ErrorMsg>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-12 order-lg-first">
                    <div className="form-content">
                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <InputDiv>
                            <label
                              htmlFor="storeName"
                              className="form-label text-white"
                            >
                              Store Name
                            </label>
                            <ChatSearchInput
                              type="text"
                              id="storeName"
                              name="storeName"
                              onChange={handleChange}
                              placeholder="Enter Store Name"
                              className={`mb-0 form-control ${errors.storeName && touched.storeName && "error"
                                }`}
                            />
                            {errors.storeName && touched.storeName && (
                              <ErrorMsg className="input feedback">
                                {errors.storeName}
                              </ErrorMsg>
                            )}
                          </InputDiv>
                        </div>
                        <div className="col-md-6 mb-2">
                          <InputDiv>
                            <label
                              htmlFor="storeAddress"
                              className="form-label text-white"
                            >
                              Address
                            </label>
                            <ChatSearchInput
                              type="text"
                              id="storeAddress"
                              name="storeAddress"
                              onChange={handleChange}
                              placeholder="Enter Store Address"
                              className={`mb-0 form-control ${errors.storeAddress &&
                                touched.storeAddress &&
                                "error"
                                }`}
                            />
                            {errors.storeAddress && touched.storeAddress && (
                              <ErrorMsg className="input feedback">
                                {errors.storeAddress}
                              </ErrorMsg>
                            )}
                          </InputDiv>
                          {/* <ErrorMessage name="storeAddress" component="div" className="invalid-feedback my-2" /> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 my-2">
                          <label
                            htmlFor="store_category_id"
                            className="form-label text-white"
                          >
                            Category
                          </label>
                          {load11 ? (
                            <SpinnerDiv
                              className="py-1"
                              style={{ height: "auto" }}
                            >
                              <Spinner style={{ color: colorAccent }} />
                            </SpinnerDiv>
                          ) : (
                            <Field
                              as="select"
                              className={`mb-0 form-control ${errors.store_category_id &&
                                touched.store_category_id &&
                                "error"
                                }`}
                              id="store_category_id"
                              name="store_category_id"
                              style={{
                                textTransform: "capitalize",
                                background: "transparent",
                                color: "#b1b1b1",
                              }}
                              onChange={(e) => {
                                handleChange(e);
                                // console.log('e', e);
                                setSelectedCategory(e.target.value); // Assuming e.target.value contains the category object
                                // console.log('selectCategory', setSelectedCategory);
                                // console.log(selectCategory);
                                // setSelectedCategory(selectCategory._id);
                                if (benficiaryToggle === "external") {
                                  fetchgetFee(e.target.value);
                                }
                              }}
                            >
                              <option value="" label="Select category" />
                              {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.category_name}
                                </option>
                              ))}
                            </Field>
                          )}
                          {errors.store_category_id &&
                            touched.store_category_id && (
                              <ErrorMsg className="input feedback">
                                {errors.store_category_id}
                              </ErrorMsg>
                            )}
                        </div>
                        <div className="col-md-6 my-0">
                          <InputDiv>
                            <DropdownDiv className="">
                              <label
                                style={{ fontSize: "small" }}
                                htmlFor="subcategory_id"
                                className="form-label text-white mt-0"
                              >
                                Approver
                              </label>
                              <CustomSelect
                                name="subcategory_id"
                                id="subcategory_id"
                                // eslint-disable-next-line max-len
                                className={`form-control w-25 ${errors.approver_wallet_address &&
                                  touched.approver_wallet_address &&
                                  "error"
                                  }`}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    textTransform: "capitalize",
                                  }),
                                }}
                                value={getApproverID}
                                options={approverOptions}
                                onChange={handleApproverChange}
                              />
                              {errors.approver_wallet_address &&
                                touched.approver_wallet_address && (
                                  <ErrorMsg className="input feedback">
                                    {errors.approver_wallet_address}
                                  </ErrorMsg>
                                )}
                            </DropdownDiv>
                          </InputDiv>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 my-2">
                          <label
                            htmlFor="File2"
                            className="form-label text-white"
                          >
                            Store Document
                          </label>
                          <input
                            hidden
                            type="file"
                            ref={fileInputRef}
                            className="form-control"
                            onChange={handleFileChange}
                            id="File2"
                            name="File2"
                            accept=".pdf"
                          />
                          <Button
                            size="md"
                            onClick={handleButtonClickClick}
                            className="py-2 btn-block mb-0"
                            variant="outline-success"
                          >
                            Upload Document
                          </Button>
                          {/* <ErrorMessage name="File" component="div" className="invalid-feedback" /> */}
                          {errors.File2 && touched.File2 && (
                            <ErrorMsg className="input feedback">
                              {errors.File2}
                            </ErrorMsg>
                          )}
                        </div>
                        {file && (
                          <div className="col-md-6">
                            <label
                              htmlFor="features"
                              style={{ fontSize: "small" }}
                              className="form-label text-white my-2"
                            >
                              Uploaded Document{" "}
                            </label>
                            <br />
                            <div
                              className="pt-1"
                              style={{
                                border: "1px solid white",
                                borderRadius: "4px",
                              }}
                            >
                              <label
                                htmlFor="features"
                                className="form-label text-white mt-1 mx-2"
                              >
                                {file.name}{" "}
                              </label>
                              <button
                                type="button"
                                style={{ marginTop: "2px" }}
                                className="btn btn-danger py-0 float-right mr-1 mb-2"
                                onClick={handleFileRemove}
                              >
                                Remove <i className="fa fa-file-pdf-o" />
                              </button>
                            </div>
                          </div>
                        )}

                        <div className="col-md-6 my-2">
                          <label
                            htmlFor="delivery_available_countries"
                            className="form-label text-white"
                          >
                            Delivery Available Countries
                          </label>
                          <CustomSelectCustom
                            isMulti
                            closeMenuOnSelect
                            menuPlacement="top"
                            components={animatedComponents}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: "150px",
                                overflow: "hidden",
                              }),
                            }}
                            value={selectedCountries.value}
                            options={getCountry} // Adjust options as needed
                            onChange={(selectedOptions) => {
                              // console.log('On Chnage', selectedCountries.value);
                              setSelectedCountries(selectedOptions);
                              // setFieldValue(
                              //   "delivery_available_countries",
                              //   selectedCountries.value
                              // );
                            }}
                          />
                          {/* <ErrorMessage name="delivery_available_countries" component="div" className="invalid-feedback" /> */}
                          {/* <ErrorMsg className="input feedback">{formik.errors.delivery_available_countries}</ErrorMsg> */}
                          {errors.delivery_available_countries &&
                            touched.delivery_available_countries && (
                              <ErrorMsg className="input feedback">
                                {errors.delivery_available_countries}
                              </ErrorMsg>
                            )}
                          {/* Display selected countries */}
                          {/* {selectedCountries.length > 0 && (
                        <div style={{ maxHeight: '150px', overflowY: 'scroll' }}>
                          <strong>Selected Countries:</strong>
                          {selectedCountries.map(country => country.label).join(', ')}
                        </div>
        )} */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 my-2">
                          <InputDiv>
                            <label
                              htmlFor="storeDescription"
                              className="form-label text-white"
                            >
                              Description
                            </label>
                            <ChatSearchInput
                              as="textarea"
                              className={`mb-0 form-control ${errors.storeDescription &&
                                touched.storeDescription &&
                                "error"
                                }`}
                              id="storeDescription"
                              name="storeDescription"
                              onChange={handleChange}
                              rows={1}
                              placeholder="Enter Store Description"
                            />
                            {errors.storeDescription &&
                              touched.storeDescription && (
                                <ErrorMsg className="input feedback">
                                  {errors.storeDescription}
                                </ErrorMsg>
                              )}
                          </InputDiv>
                        </div>

                        <div className="col-md-6 px-3 py-3">
                          <Field
                            type="checkbox"
                            id="online_delivery_available"
                            name="online_delivery_available"
                            className="form-check-input ml-1"
                          />
                          <label
                            htmlFor="online_delivery_available"
                            className="form-label text-white ml-4"
                          >
                            Online Delivery Available
                          </label>
                        </div>

                        <div className="col-md-6 my-2">
                          <>
                            <label
                              htmlFor="store_type"
                              className="form-label text-white"
                            >
                              Store Type
                            </label>
                            <DropdownDiv className="mb-2">
                              <CustomSelect
                                name="store_type"
                                className="w-25"
                                value={selectedType}
                                onChange={(value) => {
                                  setSelectedType("selectedType", value.value);
                                  handleTypeChange(value);
                                }}
                                options={optionType}
                              />
                            </DropdownDiv>
                            {errors.store_type && touched.store_type && (
                              <ErrorMsg className="input feedback">
                                {errors.store_type}
                              </ErrorMsg>
                            )}
                          </>
                        </div>
                        <div className="col-md-6 my-2">
                          {benficiaryToggle !== "external" && (
                            <>
                              <label
                                htmlFor="storeDescription"
                                className="form-label text-white"
                              >
                                Select Chain
                              </label>
                              <DropdownDiv className="mb-2">
                                <CustomSelect
                                  name="chain"
                                  className="w-25"
                                  value={values.selectedChain}
                                  onChange={(value) => {
                                    setFieldValue("selectedChain", value.value);
                                    handleChainChange(value);
                                  }}
                                  options={optionChain}
                                />
                              </DropdownDiv>
                            </>
                          )}
                        </div>

                        {userSessionData?.data?.user?.TFA && <div className="col-md-6 my-2 ">
                          {userSessionData?.data?.user?.TFA && (
                            <div className=" d-flex flex-column  col-lg-12 col-md-12 col-sm-12 mt-1 gap-2  justify-content-between ">
                              <label
                                htmlFor="storeDescription"
                                className="form-label text-white"
                              >
                                Verify TFA *
                              </label>

                              <InputDiv>

                                <ChatSearchInput
                                  type="text"
                                  value={tfaCode}
                                  onChange={(e) => {
                                    setTfaCode(e.target.value);
                                  }}
                                  placeholder="Enter Code"
                                />
                              </InputDiv>

                            </div>
                          )}
                        </div>}

                        {showTokenDropdown &&
                          benficiaryToggle !== "external" && (
                            <div className="col-md-6 my-2">
                              <DropdownDiv className="">
                                <label
                                  htmlFor="approver_wallet_address"
                                  className="form-label text-white"
                                >
                                  Select Token
                                </label>
                                <CustomSelect
                                  name="token"
                                  className="w-25"
                                  value={values.selectedToken}
                                  onChange={(value) => {
                                    setFieldValue("selectedToken", value.value);
                                    handleTokenChange(value);
                                  }}
                                  options={[
                                    // { value: "CTY", label: "CTY" },
                                    { value: "USDT", label: "USDT" },
                                  ]}
                                />
                              </DropdownDiv>
                            </div>
                          )}
                        {benficiaryToggle === "external" && (
                          <div className="col-md-6 my-2">
                            <DropdownDiv className="">
                              <label
                                htmlFor="approver_wallet_address"
                                className="form-label text-white"
                              >
                                Select Token
                              </label>
                              <CustomSelect
                                name="token"
                                className="w-25"
                                value={values.selectedToken}
                                onChange={(value) => {
                                  setFieldValue("selectedToken", value.value);
                                  setSelectedToken(value.value);
                                }}
                                options={tokenArray}
                              />
                            </DropdownDiv>
                          </div>
                        )}
                      </div>
                      <div className=" d-flex ">

                        <Button
                          onClick={() => setShowWebCap(true)}
                          size="md"
                          className="py-2 btn-block"
                          variant="outline-success"
                          disabled={imageSelfie}
                        >
                          Verify Selfie
                        </Button>

                        <div className="d-flex w-100 justify-content-center  align-items-center ">
                          <input
                            hidden
                            type="file"
                            ref={fileSelfieUpload}
                            // value={values.File}
                            name="File[]"
                            id="File"
                            // multiple
                            onChange={handleSelfieUpload}

                            accept="image/*"
                          />
                          <br />
                          <Button
                            onClick={handleButtonSelfieClick}
                            size="md"
                            className="py-2 btn-block"
                            variant="outline-success"
                            disabled={capturedImage}

                          >
                            Upload Selfie
                          </Button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row px-0 mt-5">
                  <div className="col-lg-3 col-md-6 col-sm-12" />
                  <div className="col-lg-9 col-md-12 col-sm-12 order-lg-first">
                    <div className="form-content">
                      <div className="row d-flex align-items-center">
                        <div className="col-md-6" >
                          {userSessionData?.data?.user?.TFA && <InputDiv>
                            <Button
                              variant="contain-success"
                              className="btn-sm"
                              onClick={handleTfaCode}
                              disabled={isTfaVarified}
                            >
                              {isTfaVarified ? "Verified" : "Verify"}
                            </Button>
                          </InputDiv>}

                        </div>
                        <div className="col-md-6">
                          <Button
                            type="submit"
                            size="md"
                            className="btn-block"
                            variant="outline-success"
                            disabled={userSessionData?.data?.user?.TFA && !isTfaVarified}
                          >
                            {isLoading ? "Creating..." : "Create Store"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {showWebCam && (
          <WebCam
            show={showWebCam}
            hide={() => setShowWebCap(false)}
            onCapture={handleCaptureImage}
          />
        )}
      </div>
    </>
  );
};
export default CreateStore;

const ErrorMsg = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;

const CustomSelectCustom = ({
  isLoading,
  onChange,
  options,
  value,
  name,
  onClick,
  handleBlur,
  isDisabled,
  isSearchable,
  onInputChange,
  placeholder,
}) => {
  const style = {
    option: (provided, state) => {
      const styles = {
        ...provided,
        cursor: "pointer",
      };

      if (state.isSelected) {
        styles.color = "#fff";
        styles.backgroundColor = "#4ce1b6";
      } else if (state.isDisabled) {
        styles.color = "#A9A9A9";
        styles.backgroundColor = "#857f7f";
      } else {
        styles.color = "black";
        styles.backgroundColor = "#fff";
      }

      styles["&:hover"] = {
        backgroundColor: "#4ce1b6",
      };

      return styles;
    },
    control: (base) => ({
      ...base,
      width: "100%",
      border: "1px solid #b1b1b1 !important",
      backgroundColor: "transparent !important",
      "&:hover": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
      ".isDisabled": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
    }),
    singleValue: (provided, state) => {
      const styles = {
        ...provided,
        color: "#b1b1b1", // Change this to the color you want
      };

      return styles;
    },
  };
  // eslint-disable-next-line max-len
  const defaultValue = (selectOptions, selectValue) =>
    selectOptions
      ? selectOptions?.find((option) => option.value === selectValue)
      : null;
  return (
    <Select
      key={`my_unique_select_key__${value}`}
      isLoading={isLoading}
      value={defaultValue(options, value) || ""}
      // eslint-disable-next-line no-shadow
      onChange={onChange}
      name={name}
      options={options}
      className="selectDropdpenReact"
      onClick={onClick}
      width="100%"
      styles={style}
      minMenuHeight={100}
      maxMenuHeight={150}
      menuPlacement="bottom"
      onBlur={handleBlur}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      onInputChange={onInputChange}
      placeholder={placeholder}
    />
  );
};

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
