/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  colorBorder,
  colorBackground,
  colorHover,
  colorDustyWhite,
  colorAccent,
} from "@/utils/palette";
import { left } from "@/utils/directions";
import SidebarLink, { SidebarLinkTitle } from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { checkMyAccess, getAllOtcMarketPlaceWebapp, getProject, submitAccessRequest } from "../../../../utils/services/user.service";
import io from "socket.io-client";
import config from "../../../../config/config";
import { toast } from "react-toastify";
import { useChainContext } from "../../../context/context";
import GetAccessModal from "../../../../store/Layout/Projects/GetAccessModal";
import { Ring } from "@uiball/loaders";
import { Link } from "react-router-dom";

const status = JSON.parse(localStorage.getItem("user"));

const SidebarContent = ({ onClick, collapse }) => {
  const [myProjectdata, setMyProjectData] = useState([])
  const [showGetAccessModal, setShowGetAccessModal] = useState(false)
  const [otherProject, setOtherProjects] = useState([])
  const [checkUserProjectAcess, setCheckUserProjectAcess] = useState(null)
  const { setReFetchData, reFethData } = useChainContext()
  const [isLoading, setIsLoading] = useState(false)


  const { setSocket } = useChainContext()

  const [usersList, setUsersList] = useState([]);
  useEffect(() => {
    let isMounted = true; // track whether the component is mounted

    const getAllOtcMarketLists = async () => {
      try {
        const payload = {
          limit: 20,
          page_number: 1,
        };
        const response = await getAllOtcMarketPlaceWebapp(payload);
        if (response && isMounted) { // check if the component is still mounted
          setUsersList(response?.data?.response);
        }
      } catch (error) {
        if (isMounted) { // log the error only if the component is still mounted
          console.error("Error fetching OTC marketplace lists:", error);
        }
      }
    };

    getAllOtcMarketLists();

    return () => {
      isMounted = false; // cleanup function sets isMounted to false
    };
  }, []);

  const socket = io(config.baseURL);
  useEffect(() => {
    fetchMyProject()
    {
      status?.data?.user && setSocket(socket)
      socket.on("connect", () => {
        socket.on("connection", () => {
        });
        {
          JSON.parse(localStorage.getItem("user")).data.user.user_id && socket.emit(
            "connected",
            {
              subject: "testing",
              // user_id_2: "64d200a15a0ec71ba4278c9f",
              user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
            },
            (response) => {
              // Handle the response from the server, if needed

            }
          );
        }
      });
      socket.on("message", (newMessage) => {
        toast.info(`${newMessage?.user?.name} sent you a message`)

      });
    }
  }, [status, reFethData])


  const fetchMyProject = async () => {
    try {
      const res = await getProject();
      const fetchedData = res?.data?.data;

      // Check if status?.data?.user?.user_id exists
      if (status?.data?.user?.user_id && Array.isArray(fetchedData)) {
        // Find the object that matches the user_id
        const matchingProject = fetchedData.find(
          (item) => item.user_id === status.data.user.user_id
        );
        const otherProjectsData = fetchedData.filter(
          (item) => item.user_id !== status.data.user.user_id
        );
        setOtherProjects(otherProjectsData)
        // Set only the matching object in state
        setMyProjectData(matchingProject || null);
      }

    } catch (error) {
      console.log("🚀 ~ fetchMyProject ~ error:", error);
    }
  };
  const checkUserAccess = async (item) => {
    try {
      const payload = {
        project_id: item?._id
      }
      const res = await checkMyAccess(payload)
      setCheckUserProjectAcess(res?.data?.status)


    } catch (error) {
      console.log("🚀 ~ checkUserAccess ~ error:", error)

    }
  }

  return (
    <>
      {
        showGetAccessModal && <GetAccessModal show={showGetAccessModal} hide={() => setShowGetAccessModal(false)} />
      }
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <SidebarContentWrap collapse={collapse}>
        <SidebarBlock collapse={collapse}>
          <SidebarCategory
            title={
              <>
                <span style={{ color: "#7e45dd", fontSize: "50px" }}>&bull;</span>
                <span> &nbsp; Wallet Dashboard</span>
              </>
            }
            collapse={collapse}
          >
            {!collapse
              && (
                <SidebarLink
                  title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Dashboard</span></>}
                  route="/dashboard"
                  onClick={onClick}
                />
              )}

            {/* {!collapse
        && (
        <SidebarLink
          title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Wallet Activities</span></>}
          route="/walletActivities"
          onClick={onClick}
        />
      )} */}

            {/* {!collapse
        && (
        <SidebarLink
          title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Send</span></>}
          route="/send"
          onClick={onClick}
        />
      )}
      {!collapse
        && (
        <SidebarLink
          title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Receive</span></>}
          route="/receive"
          onClick={onClick}
        />
      )}   */}
          </SidebarCategory>
          <SidebarLink
            title={
              <>
                <span
                  style={{ color: "#2584cd", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; Financial Dashboard</span>
              </>
            }
            // icon="rocket"
            route="/finance" // Update the route to include the parent path
            onClick={onClick}
          />

          {/* <SidebarLink
            title={
              <>
                <span
                  style={{ color: "#2584cd", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; Custodiy Services</span>
              </>
            }
            // icon="rocket"
            route="/services" // Update the route to include the parent path
            onClick={onClick}
          /> */}

          {/* <SidebarLink
          title={
            <>
              <span
                style={{ color: "#ffff", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Swap</span>
            </>
          }
          // icon="rocket"
          route="/swap" // Update the route to include the parent path
          onClick={onClick}
        /> */}
          <SidebarCategory
            title={
              <>
                <span style={{ color: "#f79413", fontSize: "50px" }}>&bull;</span>
                <span> &nbsp; Marketplace</span>
              </>
            }
            collapse={collapse}
          >
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Home</span>
                  </>
                }
                route="/homeproducts"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Stores</span>
                  </>
                }
                route="/home"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; My Store</span>
                  </>
                }
                route="/storedetail"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Seller Orders</span>
                  </>
                }
                route="/getmyorders"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Approvals</span>
                  </>
                }
                route="/getmyapproval"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; My Orders</span>
                  </>
                }
                route="/getmyorderasbuyer"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Custom Offers</span>
                  </>
                }
                route="/customoffers"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; FAQs</span>
                  </>
                }
                route="/faqs"
                onClick={onClick}
              />
            )}
          </SidebarCategory>

          {
            <SidebarCategory
              title={
                <>
                  <span style={{ color: "#f74413", fontSize: "50px" }}>
                    &bull;
                  </span>
                  <span> &nbsp; OTC Marketplace </span>
                </>
              }
              collapse={collapse}
            >
              {!collapse &&
                usersList.map((user, index) => (
                  <SidebarLink
                    key={index}
                    title={
                      <>
                        <span
                          style={{
                            color: "#ffffff",
                            fontSize: "35px",
                            marginTop: "2px",
                          }}
                        >
                          &bull;
                        </span>
                        <span className="d-flex mr-2"> &nbsp; {user?.market_place_name}</span>
                        {user?.otc_type == "admin" && <i className="fa fa-lock" aria-hidden="true"></i>
                        }
                      </>
                    }
                    route={`/otcmarketplacedetail?id=${user?._id}`}
                    onClick={onClick}
                  />
                ))}
            </SidebarCategory>
          }
          <SidebarCategory
            title={
              <>
                <span style={{ color: "#EE2E453", fontSize: "50px" }}>
                  &bull;
                </span>
                <span> &nbsp; Projects </span>
              </>
            }
            collapse={collapse}
          >
            {!collapse && !myProjectdata && status.data.user.role == 'project' &&

              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span className="d-flex mr-2"> &nbsp; Create Project</span>
                  </>
                }
                route={`/CreateProject`}
                onClick={onClick}
              />
            }

            {myProjectdata &&
              <SidebarCategory
                title={
                  <>
                    <span style={{ color: "#EE2E453", fontSize: "20px" }}>
                      &bull;
                    </span>
                    <span className="d-flex mr-2"> &nbsp; My Project</span>
                  </>
                }
                collapse={collapse}
              >

                {myProjectdata &&

                  <SidebarLink
                    // key={index}
                    title={
                      <>
                        <span
                          style={{
                            color: "#ffffff",
                            fontSize: "35px",
                            marginTop: "2px",
                          }}
                        >
                          &bull;
                        </span>
                        <span className="d-flex"> &nbsp; {myProjectdata?.name}</span>
                      </>
                    }
                    // to={`/project-detail/${myProjectdata?._id}`}

                    route={`/project-detail?id=${myProjectdata?._id}`}
                    onClick={onClick}
                  />
                  }

                

                {/* commenting for now as flow changed  */}
                {/* <SidebarCategory
                  title={
                    <>
                      <span style={{ color: "#EE2E453", fontSize: "20px" }}>
                        &bull;
                      </span>
                      <span className="d-flex mr-2"> &nbsp; {myProjectdata?.name}</span>
                    </>
                  }
                  collapse={collapse}
                >
                  {!collapse &&

                    <SidebarLink
                      title={
                        <>
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: "35px",
                              marginTop: "2px",
                            }}
                          >
                            &bull;
                          </span>
                          <span className="d-flex mr-2"> &nbsp; OTC</span>
                        </>
                      }
                      route={`/project-otc`}
                      onClick={onClick}
                    />
                  }
                  {!collapse &&

                    <SidebarLink
                      title={
                        <>
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: "35px",
                              marginTop: "2px",
                            }}
                          >
                            &bull;
                          </span>
                          <span className="d-flex mr-2"> &nbsp; Voting System</span>
                        </>
                      }
                      route={`/project-voting`}
                      onClick={onClick}
                    />
                  }
                  {!collapse &&

                    <SidebarLink
                      title={
                        <>
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: "35px",
                              marginTop: "2px",
                            }}
                          >
                            &bull;
                          </span>
                          <span className="d-flex mr-2"> &nbsp; User Requests</span>
                        </>
                      }
                      route={`/user-requests/${myProjectdata?._id}`}
                      onClick={onClick}
                    />
                  }
                  {!collapse &&

                    <SidebarLink
                      title={
                        <>
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: "35px",
                              marginTop: "2px",
                            }}
                          >
                            &bull;
                          </span>
                          <span className="d-flex mr-2"> &nbsp; Tracking</span>
                        </>
                      }
                      route={`/project-voting`}
                      onClick={onClick}
                    />
                  }

                </SidebarCategory> */}


              </SidebarCategory>
            }

            {otherProject &&
              otherProject.map((item) => {
                return (
                  !collapse && !checkUserProjectAcess &&

                  <SidebarLink
                    title={
                      <>
                        <span
                          style={{
                            color: "#ffffff",
                            fontSize: "35px",
                            marginTop: "2px",
                          }}
                        >
                          &bull;
                        </span>
                        <span className="d-flex mr-2"> &nbsp; {item?.name}</span>
                      </>
                    }
                    route={`/project-detail?id=${item?._id}`}

                  />





                  // commenting for now as flow chnaged 
                  // <SidebarCategory
                  //   title={
                  //     <>
                  //       <span style={{ color: "#EE2E453", fontSize: "20px" }}>
                  //         &bull;
                  //       </span>
                  //       <span className="d-flex mr-2"> &nbsp; {item?.name}</span>
                  //     </>
                  //   }
                  //   collapse={collapse}
                  //   onClick={() => checkUserAccess(item)}

                  // >
                  //   {!collapse && !checkUserProjectAcess &&

                  //     <SidebarLink
                  //       title={
                  //         <>
                  //           <span
                  //             style={{
                  //               color: "#ffffff",
                  //               fontSize: "35px",
                  //               marginTop: "2px",
                  //             }}
                  //           >
                  //             &bull;
                  //           </span>
                  //           <span className="d-flex mr-2">&nbsp; Get Access</span>
                  //         </>
                  //       }
                  //       route={`/get-access/${item?._id}`}

                  //     />

                  //   }
                  //   {!collapse && checkUserProjectAcess &&

                  //     <SidebarLink
                  //       title={
                  //         <>
                  //           <span
                  //             style={{
                  //               color: "#ffffff",
                  //               fontSize: "35px",
                  //               marginTop: "2px",
                  //             }}
                  //           >
                  //             &bull;
                  //           </span>
                  //           <span className="d-flex mr-2"> &nbsp; OTC</span>
                  //         </>
                  //       }
                  //       route={`/project-otc`}
                  //       onClick={onClick}
                  //     />
                  //   }
                  //   {!collapse && checkUserProjectAcess &&

                  //     <SidebarLink
                  //       title={
                  //         <>
                  //           <span
                  //             style={{
                  //               color: "#ffffff",
                  //               fontSize: "35px",
                  //               marginTop: "2px",
                  //             }}
                  //           >
                  //             &bull;
                  //           </span>
                  //           <span className="d-flex mr-2"> &nbsp; Voting System</span>
                  //         </>
                  //       }
                  //       route={`/project-voting`}
                  //       onClick={onClick}
                  //     />
                  //   }

                  // </SidebarCategory>
                )
              })
            }



          </SidebarCategory>

          <SidebarCategory
            title={
              <>
                <span style={{ color: "#70BBFD", fontSize: "50px" }}>&bull;</span>
                <span> &nbsp; Contract</span>
              </>
            }
            collapse={collapse}
          >
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; New Contract</span>
                  </>
                }
                route="/new-contract"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; New Safebox</span>
                  </>
                }
                route="/safebox"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Contracts List</span>
                  </>
                }
                route="/contractslist"
                onClick={onClick}
              />
            )}
          </SidebarCategory>

          <SidebarCategory
            title={
              <>
                <span style={{ color: "#F6DA6E", fontSize: "50px" }}>&bull;</span>
                <span> &nbsp; More</span>
              </>
            }
            collapse={collapse}
          >
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Currency Converter</span>
                  </>
                }
                route="/convertor"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Whitepaper</span>
                  </>
                }
                route="/whitepaper"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Token</span>
                  </>
                }
                route="/token"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "35px",
                        marginTop: "2px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Roadmap</span>
                  </>
                }
                route="/roadmap"
                onClick={onClick}
              />
            )}
          </SidebarCategory>
          <SidebarCategory
            title={
              <>
                <span style={{ color: "#70BBFD", fontSize: "50px" }}>&bull;</span>
                <span> &nbsp; Chat and Support</span>
              </>
            }
            collapse={collapse}
          >
            {!collapse && (
              <SidebarLink
                title="&#x25CF; &nbsp; Customer Service"
                route="/support"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title="&#x25CF; &nbsp; Live Chat"
                route="/livechat"
                onClick={onClick}
              />
            )}
          </SidebarCategory>
        </SidebarBlock>
      </SidebarContentWrap>
    </>

  );
};



SidebarContent.propTypes = {
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
    props.collapse &&
    `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
    props.collapse &&
    `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;
// endregion
