
import { useRef } from 'react';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "../../../shared/components/Button";
import Panel from "@/shared/components/Panel";
import { addVote, getMyVote, deleteSurveyService, deleteSurvey, submitVote, getMyVoteStatus, getTotalVotes, updateStatusElection, getFee, getExternalStoreFee } from "../../../utils/services/user.service";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { Ring } from "@uiball/loaders";
import { useChainContext } from "../../../containers/context/context";
import { Badge, Modal } from "react-bootstrap";
import ConfirmChangeStatusModal from "./ConfirmChangeStatusModal";
import RandomAnimatedBars from '../../../containers/Charts/ChartJs/components/RandomAnimatedBars';
import DateRangeFilter from "../../../shared/components/DateRangeFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const ProjectVoting = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [allSurvey, setAllSurvey] = useState([]);
    const user = JSON.parse(localStorage?.getItem('user'))
    const [timerText, setTimerText] = useState('');
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [bannerImage, setBannerImage] = useState({ image: null, file: null });
    const [statement, setStatement] = useState("");
    const [title, setTitle] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [totalVotes, setTotalVotes] = useState("");
    const { globalProjectOwnerId } = useChainContext()
    const [withdrawFee, setElectionFee] = useState(null);
    const location = useLocation()
    // Extract the `id` from the query string
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("id");    const [selectedIndex, setSelectedIndex] = useState(null); // Tracks selected survey index
    const [selectedOption, setSelectedOption] = useState(null); // Tracks selected option for the current survey
    const [selectedOptions, setSelectedOptions] = useState(null); // State to track selected options for each question
    const [selectedDates, setSelectedDates] = useState({ from: '', to: '' });
    const [options, setOptions] = useState([
        { label: "Option 1", image: null, statement: "" },
        { label: "Option 2", image: null, statement: "" },
    ]);

    const [showCropModal, setShowCropModal] = useState(false);
    const [imagePreview, setImagePreview] = useState(null); // For previewing the selected image
    const [cropper, setCropper] = useState(null);
    const [load, setLoad] = useState(false); // For upload button state


    const panelRef = useRef(null);
    const fetchgetFee = async () => {
        setIsLoading(true);
        try {
            const requestData = {
                type: 'election_fee',
            };
            const response = await getExternalStoreFee(requestData);
            setElectionFee(response.data.data)
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    };
    const handleFileChange = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setOptions((prevOptions) =>
                prevOptions.map((option, idx) =>
                    idx === index
                        ? {
                            ...option,
                            image: imageURL, // Update the image URL
                            file: file,      // Store the file object
                        }
                        : option
                )
            );
        }
    };
    const addOption = () => {
        const lastOption = options[options.length - 1];

        // Ensure the previous option has a name before adding a new one
        if (!lastOption || !lastOption.statement || lastOption.statement.trim() === "") {
            toast.error("Please fill in the name for the previous option before adding a new one.");
            return;
        }

        setOptions((prevOptions) => [
            ...prevOptions,
            { label: `Option ${prevOptions.length + 1}`, image: null, statement: "" },
        ]);
    };


    const removeOption = (index) => {
        if (index < 2) {
            // Clear image and statement for Option 1 and Option 2
            const updatedOptions = [...options];
            updatedOptions[index].image = null;
            updatedOptions[index].statement = "";
            setOptions(updatedOptions);
        } else {
            // Remove additional options entirely
            setOptions((prevOptions) => prevOptions.filter((_, idx) => idx !== index));
        }
    };

    const handleOptionChange = (selectedIndex) => {
        setSelectedOptions(selectedIndex); // Save the selected index
    };
    const handleImageClick = (index) => {
        setSelectedIndex(index);
        setSelectedOption(null);

        // Scroll to the panel section
        panelRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const getSurvey = async () => {
        setIsLoading(true);
        try {
            const payload = {
                project_id: projectId
            }

            const res = await getMyVote(globalProjectOwnerId == user?.data?.user?.user_id ? "" : payload);
            setAllSurvey(res?.data?.data || []);
        } catch (error) {
            toast.error("Failed to fetch surveys");
        } finally {
            setIsLoading(false);
        }
    };
    const handleSubmitSurvey = async () => {
        try {
            setIsLoading(true)
            const payload = {
                survey_id: allSurvey[selectedIndex]._id,
                choice: selectedOptions,
            }
            const res = await submitVote(payload);
            getVoteStatus()
            getSurvey()
            getVoteCount()
            toast.success(res?.data?.Message)
            setIsLoading(false)


        } catch (error) {
            setIsLoading(false)
            toast.error(error.response?.data?.Message || 'something went wrong')
            console.error("Error submitting survey:", error);
        }
    };

    const handleUpdateStatusElection = async () => {
        setIsLoading(true)
        try {
            const payload = {
                election_id: allSurvey[selectedIndex]?._id,
                new_status: allSurvey[selectedIndex]?.status == "active" ? "inactive" : "active",
            }
            const res = await updateStatusElection(payload)

            toast.success(res?.data?.Message)
            getSurvey()
            setShowModal(false)
            setIsLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.Message || 'something went wrong')
            setIsLoading(false)

        }
    }
    const handleDateChange = (dates) => {
        setSelectedDates({
            from: isNaN(Date.parse(dates.from)) ? "" : new Date(dates.from).toDateString(),
            to: isNaN(Date.parse(dates.to)) ? "" : new Date(dates.to).toDateString(),
        });
    };
    const handleOptionStatementChange = (value, index) => {
        setOptions((prevOptions) =>
            prevOptions.map((option, idx) =>
                idx === index ? { ...option, statement: value } : option
            )
        );
    };
    const handlePublishVoting = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("project_id", projectId);
            formData.append("title", title);
            formData.append("option", options.length);
            if (bannerImage.file) {
                formData.append("File", bannerImage.file);  // Send actual banner image file
            }
            const file2Array = options.map(option => option.file).filter(file => file);
            const optionaNameArray = options.map(option => option.statement).filter(statement => statement);
            file2Array.forEach(file => {
                formData.append("File2", file);  // All files will be under the same "File2[]" key
            });
            optionaNameArray.forEach(file => {
                formData.append("option_name", file);  // All files will be under the same "File2[]" key
            });
            formData.append("description", statement);
            const startDateISO = new Date(selectedDates.from).toISOString();
            const endDateISO = new Date(selectedDates.to).toISOString();
            formData.append("start_date", startDateISO);
            formData.append("end_date", endDateISO);
            await addVote(formData);
            toast.success("Survey questions added successfully!");
            getSurvey();
        } catch (error) {
            toast.error(error?.response?.data?.Message || "Failed to add survey");
        } finally {
            setIsLoading(false);
        }
    };

    const getVoteCount = async () => {
        try {
            const payload = {
                survey_id: allSurvey[selectedIndex]._id

            }
            const res = await getTotalVotes(payload)
            setTotalVotes(res?.data)

        } catch (error) {


        }
    }
    const getVoteStatus = async () => {
        setIsLoading(true)
        try {
            const payload = {
                survey_id: allSurvey[selectedIndex]._id

            }
            const res = await getMyVoteStatus(payload)
            setIsLoading(false)
        } catch (error) {

            setIsLoading(false)
        }
    }
    useEffect(() => {
        getVoteCount()
        getVoteStatus()

    }, [selectedIndex])
    useEffect(() => {
        getSurvey();
        fetchgetFee()
    }, [projectId]);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const startTime = new Date(allSurvey[selectedIndex]?.start_date);
            const endTime = new Date(allSurvey[selectedIndex]?.end_date);

            let timeLeft;
            let label;

            if (now < startTime) {
                // Countdown to start time
                timeLeft = Math.max(0, startTime - now);
                label = 'Voting Starts in:';
            } else {
                // Countdown to end time
                timeLeft = Math.max(0, endTime - now);
                label = 'Voting Ends in:';
            }

            if (timeLeft <= 0) {
                clearInterval(interval);
                setIsTimeExpired(true);
                setTimerText('Time expired');
                return;
            }

            const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
            const seconds = Math.floor((timeLeft / 1000) % 60);

            setTimerText(
                `${label} ${days > 0 ? `${days}d ` : ''}${hours}h ${minutes}m ${seconds}s`
            );
        }, 1000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [selectedIndex]);



    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const handleCrop = () => {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                if (blob) {
                    const croppedImageBlobUrl = URL.createObjectURL(blob);
                    const croppedFile = new File([blob], "cropped-image.jpg", { type: blob.type });

                    // Update the bannerImage state with Blob URL and Blob object
                    setBannerImage({ image: croppedImageBlobUrl, file: croppedFile });

                    // Close the crop modal
                    setShowCropModal(false);

                    console.log("Cropped Image Blob URL:", croppedImageBlobUrl);
                    console.log("Cropped Image Blob:", blob);
                }
            }, "image/jpeg"); // Specify the format (e.g., "image/jpeg" or "image/png")
        }
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setImagePreview(imageURL); // Pass the image to Cropper
            setShowCropModal(true); // Open the modal for cropping
        }
    };
    return (
        <>

            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 99999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            {globalProjectOwnerId == user?.data?.user?.user_id && <Panel lg={12} title="Project Voting" closeBtn minusBtn refreshLoad>
                <h5 className="text-white">Add Questions and Options for Voting</h5>
                {bannerImage.image && (
                    <div className="py-2 d-flex flex-column gap-2">
                        <p>Selected Banner:</p>
                        <img
                            src={bannerImage.image}
                            alt="Selected Banner"
                        // style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "contain" }}
                        />
                        <Button
                            className="btn btn-danger w-25"
                            onClick={() => setBannerImage({ image: null, file: null })} // Reset both image and file
                        >
                            Remove
                        </Button>
                    </div>
                )}
                <input
                    type="file"
                    id="bannerInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageChange}
                />

                {!bannerImage.image && (
                    <Button
                        className="btn btn-primary"
                        onClick={() => document.getElementById("bannerInput").click()}
                    >
                        Add Banner Image
                    </Button>
                )}
                {/* Statement Section */}

                <div className="d-flex flex-row flex-wrap gap-3">
                    {/* Statement */}
                    <div className="col-lg-4 col-md-12 d col-sm-12 mt-1">
                        <InputDiv>
                            <label
                                htmlFor="statement"
                                className="text-white mt-2"
                                style={{ fontSize: "small" }}
                            >
                                Title *
                            </label>
                            <div>
                                <ChatSearchInput
                                    name="statement"
                                    className="mt-4"
                                    type="text"
                                    placeholder="Enter your Election Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                        </InputDiv>
                    </div>
                    <div className="col-lg-4 col-md-12 d col-sm-12 mt-1">
                        <InputDiv>
                            <label
                                htmlFor="statement"
                                className="text-white mt-2"
                                style={{ fontSize: "small" }}
                            >
                                Statement *
                            </label>
                            <div>
                                <ChatSearchInput
                                    name="statement"
                                    className="mt-4"
                                    type="text"
                                    placeholder="Enter your Statement"
                                    value={statement}
                                    onChange={(e) => setStatement(e.target.value)}
                                />
                            </div>
                        </InputDiv>
                    </div>
                    <InputDiv>
                        <label
                            htmlFor="statement"
                            className="text-white mt-2"
                            style={{ fontSize: "small" }}
                        >
                            Start and End Date *
                        </label>
                        <div>
                            <DateRangeFilter onDateChange={handleDateChange} />

                        </div>
                    </InputDiv>
                </div>


                {/* Options Section */}
                <div className="mt-3">
                    <div className="d-flex flex-wrap">
                        {options.map((option, index) => (
                            <div key={index} className="col-lg-4 col-md-12 col-sm-12 mt-2">
                                <label className="text-white" style={{ fontSize: "small" }}>
                                    {option.label}
                                </label>
                                <div className="py-2 d-flex  flex-column gap-2">
                                    {option.image && (
                                        <img
                                            src={option.image}
                                            alt={option.label}
                                            className='border-1 border'
                                            style={{
                                                width: "100px",
                                                height: "80px",
                                                objectFit: "contain",
                                                borderRadius: "13px",
                                            }}
                                        />
                                    )}
                                    <input
                                        type="file"
                                        id={`optionInput-${index}`}
                                        style={{ display: "none" }}
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, index)}
                                    />
                                    {!option.image ? (
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                if (index > 0) {
                                                    const prevOption = options[index - 1];
                                                    if (!prevOption.statement || prevOption.statement.trim() === "") {
                                                        toast.error(`Please provide a name for ${prevOption.label} before adding an image to ${option.label}.`);
                                                        return;
                                                    }
                                                }
                                                document.getElementById(`optionInput-${index}`).click();
                                            }}
                                        >
                                            Add {option.label} Image
                                        </Button>

                                    ) : (
                                        <>
                                            <div>
                                                <InputDiv>
                                                    <ChatSearchInput
                                                        name={`statement-${index}`}
                                                        className="mt-2"
                                                        type="text"
                                                        placeholder={`${option.label} Name`}
                                                        value={option.statement || ""}
                                                        onChange={(e) =>
                                                            handleOptionStatementChange(e.target.value, index)
                                                        }
                                                    />
                                                </InputDiv>
                                            </div>
                                            <Button
                                                className="btn btn-danger"
                                                onClick={() => removeOption(index)}
                                            >
                                                Remove {option.label}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                        <Button
                            variant="outline-primary"
                            className="d-flex align-items-center"
                            onClick={addOption}
                        >
                            <i className="fas fa-plus"></i> Add Option
                        </Button>
                    </div>
                </div>
                <FeeMessage>
                    Note:The fee for creating a election is <span className="text-success">{withdrawFee?.fee} {withdrawFee?.symbol}</span>.
                </FeeMessage>



                <div className="mt-3">
                    <Button className="btn btn-primary" disabled={!selectedDates.to || !selectedDates.from} onClick={handlePublishVoting}>
                        Publish Voting
                    </Button>
                </div>
            </Panel >}


            {/* <h5>Click banner to see all Questions</h5> */}
            <div className="d-flex flex-column gap-4">
                {/* Image Grid */}
                <div className="d-flex flex-column flex-md-row gap-2 px-4 flex-wrap">
                    {allSurvey?.map((item, idx) => (
                        <div
                            key={idx}
                            className="d-flex flex-column gap-2"
                        >
                            <img
                                src={item?.banner[0]}
                                className=''
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    objectFit: 'contain'
                                }}
                                alt={`Survey ${idx + 1}`}
                            />
                            <div className="d-flex flex-column item-center">
                                <Badge
                                    bg="success"
                                    // className="mb-1"
                                    className={`${item?.status == 'active' ? 'bg-success' : "bg-danger"} mb-1`}

                                    style={{
                                        paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                                    }}
                                >{item?.status}
                                </Badge>
                                <Button variant={'outline-primary'}
                                    onClick={() => handleImageClick(idx)} // Set index on click
                                >View</Button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Render Selected Survey Details */}
                {selectedIndex !== null && (
                    <div ref={panelRef}>
                        <Panel title={"Voting Details"}>
                            {allSurvey[selectedIndex] ? ( // Ensure the selected index is valid
                                <div className="">
                                    {/* Display the description */}
                                    <div className="mb-4">
                                        <h4>Statement:</h4>
                                        <p>{allSurvey[selectedIndex].description}</p>
                                    </div>

                                    {/* Display banners */}
                                    <div className="mb-4">
                                        {allSurvey[selectedIndex].banner.map((url, idx) => (
                                            <div style={{
                                                position: 'relative'
                                            }}>
                                                <img
                                                    key={idx}
                                                    src={url}
                                                    alt={`Banner ${idx + 1}`}
                                                    className="rounded-2"
                                                    style={{
                                                        width: "100%",
                                                        height: "300px", // Ensures the image takes up the full container height
                                                        marginBottom: "10px",
                                                        objectFit: "cover", // Maintains the aspect ratio by cropping excess
                                                        opacity: "0.5",
                                                        filter: "blur(1px)", // Apply blur effect
                                                    }}
                                                />





                                                <div
                                                    className="d-flex flex-column align-items-center gap-2 p-2 "

                                                    style={{
                                                        // backgroundColor: '#FAF9F6', paddingBottom: '6px',

                                                        position: 'absolute', right: 100, top: 10, borderRadius: '3px'
                                                    }}
                                                ><Button variant={'outline-primary'} onClick={() => setShowModal(true)}>Change Status</Button>
                                                    <Badge
                                                        bg="success"
                                                        className={`${allSurvey[selectedIndex]?.status == 'active' ? 'bg-success' : "bg-danger"}`}
                                                        style={{
                                                            paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                                                            position: 'absolute', left: 100, bottom: 100
                                                        }}
                                                    >{allSurvey[selectedIndex]?.status}
                                                    </Badge>

                                                </div>


                                            </div>

                                        ))}
                                    </div>

                                    <div className="d-flex flex-column flex-md-row gap-2 py-3">
                                        <div
                                            className="d-flex align-items-center gap-2 p-2 "

                                            style={{
                                                backgroundColor: '#FAF9F6',
                                                paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                                                borderRadius: '3px'
                                            }}
                                        >Total Votes<img src="../img/voting-box.png" style={{
                                            width: "22px",
                                            height: "22px"
                                        }} /> : <h4 className="text-black">{totalVotes?.totalVotes}</h4>
                                        </div>

                                        <div
                                            className="d-flex align-items-center gap-2 p-2 "

                                            style={{
                                                backgroundColor: '#FAF9F6',
                                                paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                                                borderRadius: '3px'
                                            }}
                                        >  Candidates <i class="fa fa-users" aria-hidden="true"></i>
                                            :
                                            <h4 className="text-black">{allSurvey[selectedIndex]?.option_banner?.length}</h4>
                                        </div>
                                        <div
                                            className="d-flex align-items-center gap-2 p-2 "

                                            style={{
                                                backgroundColor: '#FAF9F6',
                                                paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            {timerText}
                                            {/* <i class="fa fa-clock-o" aria-hidden="true"></i> */}

                                        </div>
                                        <div
                                            className="d-flex align-items-center gap-2 p-2 "

                                            style={{
                                                backgroundColor: '#FAF9F6',
                                                paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            <HashLink
                                                role="link"
                                                onClick={() => {
                                                    openInNewTab(`https://bscscan.com/tx/${allSurvey[selectedIndex].hash}`);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faExternalLinkAlt} />

                                            </HashLink>
                                        </div>

                                    </div>

                                    <div className="mb-4 flex-wrap">
                                        <h5>Vote for Candidate:</h5>
                                        <div className="d-flex flex-wrap py-2 gap-5">
                                            {allSurvey[selectedIndex].option_banner.map((url, idx) => (
                                                <div className='d-flex flex-column' >
                                                    <div key={idx} className="form-check me-3 d-flex align-items-center gap-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id={`option-${idx}`}
                                                            name="option-selection"
                                                            value={idx + 1} // Set value as index + 1
                                                            onChange={() => handleOptionChange(idx + 1)} // Pass index + 1 to handler
                                                        />
                                                        <label className="form-check-label" htmlFor={`option-${idx}`}>
                                                            <img
                                                                src={url}
                                                                alt={`Option ${idx + 1}`}
                                                                className='border border-1'
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                    borderRadius: "13px",
                                                                }} />
                                                        </label>
                                                    </div>
                                                    <p className="mb-0" style={{ fontSize: "14px", textAlign: "center" }}>
                                                        {allSurvey[selectedIndex].option_name[idx]}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        {globalProjectOwnerId != user?.data?.user?.user_id ?
                                            <Button disabled={!selectedOptions || isTimeExpired} className='mt-2' onClick={handleSubmitSurvey}>
                                                {allSurvey[selectedIndex]?.status == 'active' ? 'Submit' : "Voting Paused"}
                                            </Button>
                                            :

                                            <p className="text-warning" style={{
                                                fontSize: '12px',

                                            }}>You can not vote because you're owner of this Voting</p>}
                                    </div>
                                </div>
                            ) : (
                                <p>No data available for the selected index.</p>
                            )}
                            <div className="d-flex ">
                                <RandomAnimatedBars allSurvey={allSurvey} surveyId={allSurvey[selectedIndex]._id} />
                            </div>

                            {allSurvey[selectedIndex]?.status == 'inactive' &&
                                < p className="text-warning" style={{
                                    fontSize: '12px'
                                }}>
                                    Voting Unavailable: This session is no longer accepting responses.</p>
                            }
                        </Panel>
                    </div>
                )}

                {showModal && <ConfirmChangeStatusModal show={showModal} hide={() => setShowModal(false)} onClick={handleUpdateStatusElection} />
                }            </div >


            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                show={showCropModal}
                onHide={() => setShowCropModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        Crop Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container padding-bottom-3x mb-1 p-0">
                        <div
                            className="card mt-0"
                            style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                        >
                            <div className="card-body">
                                <Cropper
                                    src={imagePreview}
                                    // style={{ width: "100%", height: "300px" }} // Cropper display area
                                    aspectRatio={16 / 6}
                                    guides
                                    onInitialized={(instance) => setCropper(instance)}
                                />
                                <Button
                                    onClick={handleCrop}
                                    disabled={load}
                                    size="md"
                                    className="py-2 btn-block mt-2"
                                    variant="success"
                                >
                                    Crop & Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProjectVoting;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const FeeMessage = styled.div`
font-size:12px;
color:yellow;
text-align: start;
margin: 1rem 0;
`;