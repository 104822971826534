/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import Panel from '@/shared/components/Panel';
import { useCart } from './CartContext';
import { Button } from '../../../shared/components/Button';
import 'react-toastify/dist/ReactToastify.css';
import '../style/product.css';
import {
  FormControlLabel, FormControl, RadioGroup, Radio, FormLabel,
} from '@mui/material';
import { colorAccent } from '../../../utils/palette';
import { exchangeRatesStriga } from '../../../utils/services/user.service';
import CreateCheckoutView from '../../../../src/store/Layout/product/checkoutView';


const ProductCart = () => {
  const history = useHistory();
  const { id } = useParams();
  const [cartData, setCartData] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [getRemovel, setRemovel] = useState([]);
  const [setDeliveryCharges, setDeliveryChargesTotal] = useState(0);
  const [cartSybmol, setCartSybmol] = useState('');
  const [selectedCountry, setSelectedCountry] = useState();
  const { isLoggedIn } = useSelector(state => state.user);
  const [show1, setShow1] = useState(false);
  const [swapOverviewDataStriga, setSwapOverviewDataStriga] = useState(null);
  const [selectedValue, setSelectedValue] = useState('Crypto_payment');
  const [showInvoice, setShowInvoice] = useState(false);
  const [productInvoiceData, setProductInvoiceData] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      const payload = {}; // Replace with your actual payload
      const res = await exchangeRatesStriga(payload);
      // Assuming res.data.data contains the data similar to the one you posted
      const data = res?.data?.data;
      if (data) {
        const usdToEurData = data.USDTEUR;
        if (usdToEurData) {
          setSwapOverviewDataStriga(usdToEurData);
        }
      }
    };

    fetchData();
  }, []);
  const handleClose1 = () => setShow1(false);
  let countriesArray = [];
  let dataArray = [];
  let total1 = 0;
  const { cartCount, decrementCartCount } = useCart();
  const Parcel = `${process.env.PUBLIC_URL}/img/parcel.png`;

  const optionChain = [
    { value: 'ETH', label: 'ETH' },
    { value: 'BNB', label: 'BNB' },
    { value: 'MATIC', label: 'MATIC' },
    { value: 'BTC', label: 'BTC' },
  ];

  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange1,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });
  const handleChange1 = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleCountryChange = (value) => {
    setSelectedCountry(value.value);
  };

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then((data) => {
        // Extract specific data and store it in the countriesArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
        countriesArray = data.map(country => ({
          label: country.name.common,
          value: country.name.common,
        }));

        // Now you have the data in the countriesArray
        setCountries(countriesArray);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    const userAdress = JSON.parse(localStorage.getItem('user'));
    let storedCustomArray = [];
    let parsedCustomArray = [];
    if (localStorage.getItem('customArray') != null) {
      storedCustomArray = localStorage.getItem('customArray');
      parsedCustomArray = JSON.parse(storedCustomArray);
    }
    parsedCustomArray = parsedCustomArray.filter(item => item.userId === userAdress.data.user.user_id);
    setCartData(parsedCustomArray);
    if (parsedCustomArray?.length > 0) {
      setCartSybmol(parsedCustomArray[0].symbol);
    }

    // const calculateUpdatedPrice = (price, quantity) => price * quantity;
    // console.log('calculateUpdatedPrice', calculateUpdatedPrice);

    if (parsedCustomArray != null) {
      parsedCustomArray.forEach((data) => {
        const itemTotal = data.deliveryCharges;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        total1 += itemTotal;
      });
      setDeliveryChargesTotal(total1);
    }
  }, []);

  if (!cartData) {
    return (
      <div className="container mt-4 containerDiv">
        <div className="row">
          <div className="col-12 text-center"> <h1 className="text-white"> No Product Found in Cart</h1></div>
        </div>
      </div>
    );
  }

  const handleQuantityChange = (productId, newQuantity, Max) => {
    let parsedCustomArrayNew = [];
    const userAdress = JSON.parse(localStorage.getItem('user'));
    const storedCustomArrayNew = localStorage.getItem('customArray');
    parsedCustomArrayNew = JSON.parse(storedCustomArrayNew);
    parsedCustomArrayNew = parsedCustomArrayNew.filter(item => item.userId === userAdress.data.user.user_id);
    if (newQuantity <= 0) {
      setCartData(prevCartItems => prevCartItems.map(item => (item.objectId === productId ? { ...item, productqty: 1 } : item)));
      const updatedCustomArray = parsedCustomArrayNew.map((item) => {
        if (item.objectId === productId) {
          return { ...item, productqty: 1 };
        }
        return item;
      });
      localStorage.setItem('customArray', JSON.stringify(updatedCustomArray));
    } else if (newQuantity <= Max) {
      setCartData(prevCartItems => prevCartItems.map(item => (item.objectId === productId ? { ...item, productqty: newQuantity } : item)));
      const updatedCustomArray = parsedCustomArrayNew.map((item) => {
        if (item.objectId === productId) {
          return { ...item, productqty: newQuantity };
        }
        return item;
      });
      localStorage.setItem('customArray', JSON.stringify(updatedCustomArray));
    }
  };

  const handleRemove = (productId) => {
    // Remove the product with matching productId from localStorage
    const storedArray = JSON.parse(localStorage.getItem('customArray')) || [];
    const updatedArray = storedArray.filter(item => item.objectId !== productId);
    localStorage.setItem('customArray', JSON.stringify(updatedArray));
    decrementCartCount();
    toast.error('Product Removed from Cart', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });

    let parsedCustomArray = [];
    const storedCustomArray = localStorage.getItem('customArray');
    const userAdress = JSON.parse(localStorage.getItem('user'));
    parsedCustomArray = JSON.parse(storedCustomArray);
    parsedCustomArray = parsedCustomArray.filter(item => item.userId === userAdress.data.user.user_id);
    setCartData(parsedCustomArray);

    if (parsedCustomArray != null) {
      parsedCustomArray.forEach((data) => {
        const itemTotal = data.deliveryCharges;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        total1 += itemTotal;
      });
      setDeliveryChargesTotal(total1);
    }
  };
  const calculateTotal = () => cartData.reduce((total, item) => total + item.productqty * (selectedValue == "bank_payment" ? item.allData.pPrice * swapOverviewDataStriga?.price : item.allData.pPrice - (item.allData.pDiscount / 100) * selectedValue == "bank_payment" ? item.allData.pPrice * swapOverviewDataStriga?.price : item.allData.pPrice), 0);
  const calculateTotalQuantity = () => cartData.reduce((total, item) => total + item.productqty, 0);
  const calculateTotalCharges = () => cartData.reduce((total, item) => total +
    selectedValue == "bank_payment" ? item.deliveryCharges * swapOverviewDataStriga?.price : item.deliveryCharges
    , 0);

  const totalCharges = calculateTotalCharges();
  const totalQuantity = calculateTotal();
  const totalSum = totalCharges + totalQuantity;
  console.log("🚀 ~ ProductCart ~ totalSum:", totalSum)

  const handleCreateProduct = async (values) => {
    // console.log('values >>>>>>>>>>>>>>>>>>>>>>> ', values);

    if (isLoggedIn === true) {
      const filteredArray = cartData.filter(item => item.match === JSON.parse(localStorage.getItem('user')).data.user.user_id);
      if (filteredArray?.length > 0) {
        const filteredArray1 = filteredArray.filter(item => item.title);
        const titlesArray = filteredArray1.map(item => item.title);
        setRemovel(titlesArray);
        setShow1(true);
      } else {
        dataArray = cartData.map(data => ({
          detail_objectId: data.allData.pObjectId,
          product_id: data._id,
          price: selectedValue == "bank_payment" ? data.allData.pPrice * swapOverviewDataStriga.price : data.allData.pPrice,
          title: data.title,
          sub_category_id: data.subcategory_id,
          quantity: data.productqty,
          color: data.allData.pColor,
          warranty_in_months: data.warrantyInMonths,
          delivery_charges: data.deliveryCharges,
          discount: data.allData.pDiscount,
          cat_id: data.allData.pCategoryId,
          size: data.allData.pSize,
          pictures: data.pictures[0],
          symbol: selectedValue == "bank_payment" ? "EUR" : data.symbol,
        }));

        const productIds = cartData.map(data => data._id);
        const productCatIds = cartData.map(data => data.allData.pCategoryId);

        // console.log('dataArray >>>>>>>>>>>>>>>>>>>>>>> ', dataArray);
        // console.log('productIds >>>>>>>>>>>>>>>>>>>>>>> ', productIds);

        let productList = [{
          total_quantity: calculateTotalQuantity(),
          approver_wallet_Address: cartData[0].approver_wallet_Address,
          seller_wallet: cartData[0].seller_wallet?.address,
          // seller_wallet: cartData[0]?.seller_name[0]?.address,
          store_id: cartData[0].store_id,
          category_id: cartData[0].category_id,
          userId: cartData[0].userId,
          product_id: productIds,
          cat_id: productCatIds,
          product_details: dataArray,
          total_amount: totalSum || 0,
          delivery_country: 'China',
          total_delivery_charges: calculateTotalCharges(),
          total: calculateTotal(),
          delivery_address: 'USA',
          chain_id: cartData[0].chainId,
          symbol: selectedValue == "bank_payment" ? "EUR" : cartData[0].symbol,
          agency_percentage: cartData[0].agency_percentage,
          payMethod: selectedValue,
          conversionPrice: swapOverviewDataStriga?.price

        },
        ];
        // localStorage.setItem('customArray1', JSON.stringify(productList[0]));
        setShowInvoice(true)
        setProductInvoiceData(productList[0])

        // history.push('/checkout');
      }
    } else {

    }
  };

  return (
    <Panel lg={12} title="Cart" closeBtn minusBtn refreshLoad>
      {
        showInvoice ?
          <CreateCheckoutView productList={productInvoiceData} /> :
          <Formik
            enableReinitialize
            initialValues={{
              quantity: 1,
              pickupAddress: '',
              price: '',
            }}
            onSubmit={(values) => {

              handleCreateProduct(values);
            }}
            validate={(values) => {
              const errors = {};
              if (!values.quantity) {
                errors.quantity = 'Quantity is Required!';
              }
              return errors;
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="container mt-4 containerDiv">
                    <div className="row">
                      <div className="col-12">
                        <div style={{ overflowY: 'auto' }}>
                          <table className="table text-center table-hover">
                            <thead className="thead-light">
                              <tr>
                                <th style={{ fontWeight: 'bold' }}>Image</th>
                                <th style={{ fontWeight: 'bold' }}>Name</th>
                                <th style={{ fontWeight: 'bold' }}>Color</th>
                                {/* <th style={{ fontWeight: 'bold' }}>Size</th> */}
                                <th style={{ fontWeight: 'bold' }}>Quantity</th>
                                <th style={{ fontWeight: 'bold' }}>Price</th>
                                <th style={{ fontWeight: 'bold' }}>Discount</th>
                                <th style={{ fontWeight: 'bold' }}>After Discount</th>
                                <th style={{ fontWeight: 'bold' }}>Shipping</th>
                                <th style={{ fontWeight: 'bold' }}>Total</th>
                                <th style={{ fontWeight: 'bold' }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartData?.length === 0 ? (
                                <tr>
                                  <td colSpan="11">No Product Avaiable in Cart</td>
                                </tr>
                              ) : (
                                cartData.map(data => (
                                  // eslint-disable-next-line no-underscore-dangle
                                  <tr key={data._id}>
                                    <td style={{ width: '8%', verticalAlign: 'middle' }}>
                                      <div className="">
                                        <img
                                          src={data?.pictures?.length > 0 ? data.pictures[0] : Parcel}
                                          className="d-block w-100 card-img-top "
                                          alt="img"
                                        />
                                      </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle', textTransform: 'Capitalize' }}>{data.title}</td>
                                    {data.allData.pColor ? (
                                      <td style={{ verticalAlign: 'middle' }}>{data.allData.pColor}</td>
                                    ) : (
                                      <td style={{ verticalAlign: 'middle' }}>-</td>
                                    )}
                                    {/* <td style={{ verticalAlign: 'middle' }}>{data.allData.pSize}</td> */}
                                    <td style={{ width: '15%', verticalAlign: 'middle' }} className="text-center">
                                      <div className="justify-content-center d-none">
                                        <input
                                          type="number"
                                          value={data.productqty}
                                          className="form-control w-75"
                                          onChange={e => handleQuantityChange(data.objectId, parseInt(e.target.value))}
                                          min={1}
                                          max={data.allData.pMaxQty}
                                        />
                                        {errors.quantity && touched.quantity && (
                                          <Error className="input feedback">{errors.quantity}</Error>
                                        )}
                                      </div>
                                      <div className="d-flex justify-content-center">
                                        <button className="btn-sm btn btn-secondary" type="button" onClick={() => handleQuantityChange(data.objectId, data.productqty - 1, data.allData.pMaxQty)}>
                                          <i className="fa fa-minus" />
                                        </button>
                                        <button style={{ border: '1px solid black' }} className="btn-sm btn px-2 mx-1" type="button">
                                          {data.productqty}
                                        </button>
                                        <button className="btn-sm btn btn-secondary" type="button" onClick={() => handleQuantityChange(data.objectId, data.productqty + 1, data.allData.pMaxQty)}>
                                          <i className="fa fa-plus" />
                                        </button>
                                      </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>{selectedValue == "bank_payment" ? data.allData.pPrice * swapOverviewDataStriga.price : data.allData.pPrice}
                                      <b style={{ color: 'gray', fontWeight: '500' }}> {selectedValue == "bank_payment" ? "EUR" : data.symbol}</b>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>{data.allData.pDiscount}%</td>
                                    <td style={{ verticalAlign: 'middle' }}>{selectedValue == "bank_payment" ? data.allData.pPrice * swapOverviewDataStriga?.price : data.allData.pPrice - (data.allData.pDiscount / 100) * selectedValue == "bank_payment" ? data.allData.pPrice * swapOverviewDataStriga?.price : data.allData.pPrice}
                                      <b style={{ color: 'gray', fontWeight: '500' }}> {selectedValue == "bank_payment" ? "EUR" : data.symbol}</b>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>{data.deliveryCharges} <b style={{ color: 'gray', fontWeight: '500' }}> {selectedValue == "bank_payment" ? "EUR" : data.symbol}</b></td>
                                    {data.allData.pDiscount ? (
                                      <td style={{ verticalAlign: 'middle' }}>
                                        {(data.productqty * (selectedValue == "bank_payment" ? data.allData.pPrice * swapOverviewDataStriga?.price : data.allData.pPrice - (data.allData.pDiscount / 100) * selectedValue == "bank_payment" ? data.allData.pPrice * swapOverviewDataStriga?.price : data.allData.pPrice))?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {data.symbol}</b>
                                      </td>
                                    ) : (
                                      <td style={{ verticalAlign: 'middle' }}>
                                        {Number(selectedValue == "bank_payment" ? data.allData.pPrice * swapOverviewDataStriga?.price * calculateTotalQuantity() : data.allData.pPrice * calculateTotalQuantity())?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {selectedValue == "bank_payment" ? "EUR" : data.symbol}</b>
                                      </td>
                                    )}
                                    <td style={{ verticalAlign: 'middle' }}>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm mt-0 shadow-none text-capitalize rounded-circle"
                                        onClick={() => handleRemove(data.objectId)}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8 float-right" />
                      <div className="col-lg-4 float-right">
                        <div className="row text-center py-2">
                          <div className="col-6">
                            <h5 className="currentPrice mt-3">Total Quantity</h5>
                          </div>
                          <div className="col-6">
                            <h5 className="currentPrice mt-3"> {calculateTotalQuantity()}</h5>
                          </div>
                          <div className="col-6">
                            <h5 className="currentPrice mt-3">Shipping Charges</h5>
                          </div>
                          <div className="col-6">
                            <h5 className="currentPrice mt-3"> {calculateTotalCharges()?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {selectedValue == "bank_payment" ? "EUR" : cartSybmol}</b></h5>
                          </div>
                          <div className="col-6">
                            <h5 className="currentPrice mt-3">Total Price</h5>
                          </div>
                          <div className="col-6">
                            <h5 className="currentPrice mt-3"> {calculateTotal()?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {selectedValue == "bank_payment" ? "EUR" : cartSybmol}</b></h5>
                          </div>
                          <div className="col-6">
                            <h5 className="currentPrice mt-3"> Grand Total</h5>
                          </div>
                          <div className="col-6">
                            <h5 className="currentPrice mt-3">

                              {
                             totalSum?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {selectedValue == "bank_payment" ? "EUR" : cartSybmol}
                             
                              </b>
                            </h5>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <FormControl className="mt-1">
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={selectedValue}
                              name="ProductSalesTax"
                            >
                              <FormControlLabel
                                style={{ color: 'white' }}
                                value="Crypto_payment"
                                name="ProductSalesTax"
                                control={(
                                  <Radio
                                    {...controlProps('Crypto_payment')}
                                    sx={{
                                      color: 'white',
                                      '&.Mui-checked': {
                                        color: colorAccent,
                                      },
                                    }}
                                  />
                                )}
                                label="Pay with Crypto"
                              />
                              {/* <FormControlLabel
                          style={{ color: 'white' }}
                          value="bank_payment"
                          name="ProductSalesTax"
                          control={(
                            <Radio
                              {...controlProps('bank_payment')}
                              sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                  color: colorAccent,
                                },
                              }}
                            />
                          )}
                          label="Pay with Bank"
                        /> */}
                              <p>Pay with Fiat (coming soon)</p>



                            </RadioGroup>
                          </FormControl>



                          <div className="col-12">
                            {cartData?.length > 0 && (
                              <button
                                type="submit"
                                className="btn btn-primary btn-block w-40 purple-button mt-3 shadow-none text-capitalize"
                              >
                                Check Out
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}

          </Formik>
      }



      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}><b>Please Remove Your Store Products</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div className="card mt-0 p-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12 mb-3 text-dark">
                    <p className="text-dark">
                      We're unable to process your order at the moment as some of the your store items in your cart. Please review your cart and remove your store products.
                    </p>
                  </div>
                  <div className="col-12 text-danger">
                    <p className="text-dark">
                      <ul>
                        {getRemovel.map(data => (
                          <li style={{ fontWeight: '600' }}> {data}</li>
                        ))}
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Panel>
  );
};


export default ProductCart;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const FormButton = styled(Button)`
 color:"white";
 mergin-top:1rem;
`;

const Error = styled.div`
 
color: #e66e6e;
padding: 2px 0px;
font-size: 12px;
cursor:none;
`;
