/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import App from "@/containers/App/App";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from "./serviceWorker";
import { CartProvider } from "./store/Layout/product/CartContext";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ChainProvider } from "./containers/context/context";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <CartProvider>
    <React.StrictMode>
      <ChainProvider>
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </ChainProvider>

      <ToastContainer />
    </React.StrictMode>
  </CartProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
