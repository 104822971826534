
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Web3 from 'web3';
import { useAccount } from 'wagmi';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Ring } from '@uiball/loaders';
import { Formik } from 'formik';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  FormControlLabel, FormControl, RadioGroup, Radio, FormLabel,
} from '@mui/material';
import Panel from '@/shared/components/Panel';
import config from '@/config/walletConnect';
import { colorAccent } from '../../../utils/palette';
import { useCart } from './CartContext';
import { Button } from '../../../shared/components/Button';
import { CustomSelect } from '../../../shared/components/CustomSelect';
import Abi from '../../../containers/Dashboards/NewContract/component/Abi/tokenAbi.json';
import marketABI from '../ABI/MarketPlaceContractAbi.json';
import 'react-toastify/dist/ReactToastify.css';
import '../style/product.css';
import {
  CreateOrderInternal,
  getFee,
  getApprovers,
  CreateExternalOrder,
  ConfirmExternalOrder,
  getAdminWallet,
  CreateOrderStriga,
} from '../../../utils/services/user.service';

const ProductCart = ({ benficiaryToggle, chain, productList }) => {
  console.log("🚀 ~ ProductCart ~ productList:", productList)
  const account = useAccount();
  const history = useHistory();
  const web3 = new Web3(window.ethereum);
  const [errorMessage, setErrorMessage] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [secondarydeliveryAddress, setSecondaryDeliveryAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [Approvers, setApprovers] = useState([]);
  const [getApproverID, setApproverID] = useState('');
  const [reCaptchaVal, setReCaptchaVal] = useState(null);
  const [Countries, setCountries] = useState([]);
  const [setDeliveryCharges, setDeliveryChargesTotal] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showError1, setShowError1] = useState(false);
  const [showError2, setShowError2] = useState(false);
  const [DeliveryTotal, setDeliveryTotal] = useState(0);
  const [QuantityTotal, setQuantityTotal] = useState(0);
  const storedCustomArrayNew11 = productList;
  // const storedCustomArrayNew11 = JSON.parse(storedCustomArrayNew11);
  // const [GrandTotal, setGrandTotal] = useState(storedCustomArrayNew11.total_amount);
  // const [GrandTotalWithoutTax, setGrandTotalWithoutTax] = useState(storedCustomArrayNew11.total_amount);
  const [tokenBalance, settokenBalance] = useState(null);
  const [coinBalance, setcoinBalance] = useState(0);
  const [coinBalance1, setcoinBalance1] = useState(0);
  const [contractInstance, SetContractInstance] = useState({});
  const [selectedValue, setSelectedValue] = useState('Amount');
  console.log("🚀 ~ ProductCart ~ selectedValue:", selectedValue)
  const [adminWalletAddress, setAdminWalletAddress] = useState('');
  const [adminWalletExist, setAdminWalletExist] = useState(false);
  const [approvereFeeExist, setApprovereFeeExist] = useState(false);
  const [feeExist, setFeeExist] = useState(false);
  const [msg, setMsg] = useState('');
  const [Tax, setTax] = useState(0);
  console.log("🚀 ~ ProductCart ~ Tax:", Tax)
  const [approverFee, setApproverFee] = useState(0);
  const [taxedArray, setTaxedArray] = useState([]);
  const [taxedArray1, setTaxedArray1] = useState([]);
  const [taxedArray11, setTaxedArray11] = useState([]);
  const [taxedArray111, setTaxedArray111] = useState([]);
  const [totalPerSum, setTotalPerSum] = useState(0);
  let countriesArray = [];
  const storedArray111 = [];
  const storedArray11111 = [];
  let dataArray = [];
  let total1 = 0;
  const { cartCount, resetCartCount } = useCart();

  const storedCustomArray21 = localStorage.getItem('customArray');
  // const storedCustomArray211 = localStorage.getItem('customArray1');
  // const parsedCustomArray211 = JSON.parse(storedCustomArray211);
  productList?.product_details.forEach((item) => {
    const total = (item.quantity * (item.price - (item.discount / 100) * item.price)).toFixed(2).toString();
    const cat = item.cat_id;
    const keyValueObject1 = { id: cat, val: total };
    storedArray111.push(keyValueObject1);
    if (storedArray111.length > 0 && taxedArray11.length === 0) {
      setTaxedArray11(storedArray111);
    }
  });

  const handleChange1 = (event) => {
    if (event.target.value === 'Percentage') {
      const mergedArray = taxedArray1.map((item) => {
        const matchingItem = taxedArray11.find(el => el.id === item.id);
        return { ...item, ...matchingItem };
      });
      const mergedArrayPercentage = mergedArray.map((item) => {
        const percentage = (item.per / 100) * item.val;
        const keyValueObject1111 = { id: item.id, totalPer: percentage };
        const combinedArray111 = [...storedArray11111, keyValueObject1111];
        storedArray11111.push(keyValueObject1111);
      });
      if (storedArray11111.length > 0 && taxedArray111.length === 0) {
        setTaxedArray111(storedArray11111);
      }
      // console.log('mergedArray', mergedArray);
    }
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    // Calculate the sum of totalPer values in storedArray11111
    const sum = taxedArray111.reduce((acc, item) => acc + item.totalPer, 0);

    // Update the state with the sum
    setTotalPerSum(sum);
  }, [taxedArray111]);

  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange1,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const optionChain = [
    { value: 'ETH', label: 'ETH' },
    { value: 'BNB', label: 'BNB' },
    { value: 'MATIC', label: 'MATIC' },
    { value: 'BTC', label: 'BTC' },
  ];

  const {
    REACT_APP_MATIC_USDT_COMISSION_ADDRES, REACT_APP_BNB_USDT_COMISSION_ADDRESS, REACT_APP_ETH_USDT_COMISSION_ADDRESS,
    REACT_APP_ETHERIUM_CHAIN, REACT_APP_SMART_CHAIN, REACT_APP_MARKET_PLACE_CONTRACT_ADDRESS_BSC, REACT_APP_NULL_WALLET_ADDRESS,
  } = config;

  const handleCountryChange = (value) => {
    setSelectedCountry(value.value);
    setShowError1(false);
  };

  async function checkTokenBalance() {
    try {
      if (account.address !== undefined) {
        let contractAddress = '';
        if (Number(REACT_APP_ETHERIUM_CHAIN) === Number(chain)) {
          contractAddress = REACT_APP_ETH_USDT_COMISSION_ADDRESS;
        } else if (Number(REACT_APP_SMART_CHAIN) === Number(chain)) {
          contractAddress = REACT_APP_BNB_USDT_COMISSION_ADDRESS;
        } else {
          contractAddress = REACT_APP_MATIC_USDT_COMISSION_ADDRES;
        }
        // console.log('contractAddress ====>>>>>', contractAddress);
        const tokenContract = new web3.eth.Contract(Abi, contractAddress);
        // console.log('tokenContract', tokenContract);
        SetContractInstance(tokenContract);
        const tokenbalance = await tokenContract.methods.balanceOf(account.address).call();
        settokenBalance(tokenbalance);
      } else {
        setMsg('');
      }
    } catch (error) {
      setMsg(`Catch Error:${error?.message}`);
      // console.log(error);
    }
  }

  async function checkCoinBalance() {
    // console.log('account.address', account.address);
    if (account.address !== undefined) {
      await web3.eth.getBalance(account.address)
        .then((balance) => {
          // console.log(balance);
          // console.log(balance);
          const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
          //  const coinBalances = Number(web3.utils.fromWei(balanceInWei, 'ether')); // Convert to Ether
          //  console.log('Number(balanceInWei)', Number(balanceInWei));
          setcoinBalance(Number(balanceInWei));
        })
        .catch((error) => {
          setMsg(error);
          setcoinBalance(0);
        });
    } else {
      setMsg('');
    }
  }

  // const printInvoive = () => {
  //   console.log('printInvoive >>>>>> printInvoive >>>>>>>>> printInvoive >>>>> printInvoive >>>>>>>>> printInvoive');
  //   const doc = new jsPDF();

  //   //get html
  //   const pdfTable = document.getElementById('divToPrint');
  //   //html to pdf format
  //   var html = htmlToPdfmake(pdfTable.innerHTML);

  //   const documentDefinition = { content: html };
  //   pdfMake.vfs = pdfFonts.pdfMake.vfs;
  //   pdfMake.createPdf(documentDefinition).open();
  // };

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then((data) => {
        // Extract specific data and store it in the countriesArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
        countriesArray = data.map(country => ({
          label: country.name.common,
          value: country.name.common,
        }));

        // Now you have the data in the countriesArray
        setCountries(countriesArray);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    const fetchgetApprovers = async () => {
      try {
        const response = await getApprovers(); // Replace with your API call for approvers
        if (response.data && response.data.user.length > 0) {
          const approverInfo = response.data.user; // Extract the first approver
          approverInfo.sort((a, b) => a.name.localeCompare(b.name));
          setApprovers(approverInfo);
        } else {
          console.error('Error fetching approver data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching approver data:', error);
      }
    };

    fetchgetApprovers();
    const storedArray = [];
    const storedArray11 = [];
    const storedArray1111 = [];
    const storedArray1 = [];

    const fetchgetFee = async (value) => {
      setIsLoading(true);
      try {
        setIsLoading(true);
        const requestData = {
          type: 'sale_tax',
          categoryId: value,
        };
        const response = await getFee(requestData);
        console.log("🚀 ~ fetchgetFee ~ response:", response)
        if (response.data) {
          setIsLoading(false);
          setFeeExist(true);
          const walletActivities = response.data.data.fee;
          const walletActivities1 = response.data.data.percentage;
          const combinedArray = [...storedArray, walletActivities];
          storedArray.push(walletActivities);

          const keyValueObject1 = { id: value, val: walletActivities };
          const combinedArray111 = [...storedArray11, keyValueObject1];
          storedArray11.push(keyValueObject1);

          const keyValueObject11 = { id: value, per: walletActivities1 };
          const combinedArray1111 = [...storedArray1111, keyValueObject11];
          storedArray1111.push(keyValueObject11);

          const newTaxValue = combinedArray.reduce((sum, currentValue) => sum + currentValue, 0);
          setTax(newTaxValue);
        } else {
          setIsLoading(false);
          setFeeExist(false);
          console.error('getTax:', response.statusText);
        }
      } catch (error) {
        const keyValueObject1 = { id: value, val: 0 };
        const combinedArray111 = [...storedArray11, keyValueObject1];
        storedArray11.push(keyValueObject1);

        const keyValueObject11 = { id: value, per: 0 };
        const combinedArray1111 = [...storedArray1111, keyValueObject11];
        storedArray1111.push(keyValueObject11);

        setIsLoading(false);
        console.error('Sales tax error:', error.response.data.Message);
        setFeeExist(false);
        // if (error.response) {
        //   if (error.response.data && error.response.data.Message) {
        //     toast.error(error.response.data.Message, {
        //       position: toast.POSITION.TOP_RIGHT,
        //     });
        //   }
        // }
      }
      setTaxedArray(storedArray11);
      setTaxedArray1(storedArray1111);
    };

    const fetchgetFeeApprover = async () => {
      setIsLoading(true);
      try {
        setIsLoading(true);
        const requestData = {
          type: 'approver_fee',
        };
        const response = await getFee(requestData);
        if (response.data) {
          setIsLoading(false);
          const walletActivities1 = response.data.data.fee;
          setApproverFee(walletActivities1);
          setApprovereFeeExist(true);
        } else {
          setIsLoading(false);
          setApprovereFeeExist(false);
          console.error('getTax:', response.statusText);
        }
      } catch (error) {
        setIsLoading(false);
        setApprovereFeeExist(false);
        console.error('Approver Fee Error:', error.response.data.Message);
        // if (error.response) {
        //   if (error.response.data && error.response.data.Message) {
        //     toast.error(error.response.data.Message, {
        //       position: toast.POSITION.TOP_RIGHT,
        //     });
        //   }
        // }
      }
    };

    const fetchAdminAddress = async () => {
      setIsLoading(true);
      try {
        const adminWallet = await getAdminWallet();
        if (adminWallet.data.admin_address) {
          setAdminWalletAddress(adminWallet);
          setAdminWalletExist(true);
        } else {
          toast.error('Admin Wallet Address not found', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
          setAdminWalletExist(false);
        }
      } catch (error) {
        // console.log(error);
        setIsLoading(false);
        setAdminWalletExist(false);
        console.error('Admin Wallet Address not found');
        // toast.error('Admin Wallet Address not found', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    };


    const parsedCustomArrayNew = productList

    const CatIds = parsedCustomArrayNew?.cat_id;
    CatIds?.map((data) => {
      fetchgetFee(data);
    });
    fetchgetFeeApprover();
    fetchAdminAddress();
    const storedCustomArray = localStorage.getItem('customArray');
    const parsedCustomArray = JSON.parse(storedCustomArray);

    // setCartData(parsedCustomArrayNew);

    // const calculateUpdatedPrice = (price, quantity) => price * quantity;
    // console.log('calculateUpdatedPrice', calculateUpdatedPrice);

    if (parsedCustomArray != null) {
      parsedCustomArray.forEach((data) => {
        const itemTotal = data.deliveryCharges;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        total1 += itemTotal;
      });
      setDeliveryChargesTotal(total1);
    }
  }, []);

  if (!productList) {
    return (
      <div className="container mt-4 containerDiv">
        <div className="row">
          <div className="col-12 text-center"> <h1 className="text-white"> No Product Found in Cart</h1></div>
        </div>
      </div>
    );
  }

  const approverOptions = Approvers.map(approver => ({
    value: approver.address,
    label: approver.name,
  }));

  const handleApproverChange = (event) => {
    const selectedID = event.value;
    setApproverID(selectedID);
  };

  const handleDeliveryAddressChange = (value) => {
    setDeliveryAddress(value.value);
    setShowError2(false);
  };

  function onChangeRecaptcha(value) {
    // console.log('Captcha value ', value);
    setReCaptchaVal(value);
  }

  // const calculateTotal = () => productList.reduce((total, item) => total + item.productqty * (item.allData.pPrice - (item.allData.pDiscount / 100) * item.allData.pPrice), 0);
  // const calculateTotalQuantity = () => productList.reduce((total, item) => total + item.productqty, 0);
  // const calculateTotalCharges = () => productList.reduce((total, item) => total + item.deliveryCharges, 0);

  const calculateTotal = () => 0;
  const calculateTotalQuantity = () => 0;
  const calculateTotalCharges = () => 0;
  const calculateTotalSum = () => 0;

  const totalCharges = calculateTotalCharges();
  const totalQuantity = calculateTotal();
  const totalSum = totalCharges + totalQuantity;

  const generateAndDownloadPDF = () => {
    // console.log('productList >>>>>>>>>>>>>>>>>>>>>>>>>>>>', productList.product_details);
    // Example dynamic data
    const dynamicData = {
      title: 'Order Invoice',
      grand_total: `Grand Total ${storedCustomArrayNew11.total_amount?.toFixed(2).toString()}`,
      tableHeader: [
        ['Name', 'Color', 'Quantity', 'Price', 'Discount', 'After Discount', 'Shipping', 'Total'],
      ],
      tableData: [],
    };

    // Loop through productList and add it to tableData
    productList.product_details.forEach((item) => {
      const quantityAndPrice = `${item.price} ${item.symbol}`;
      const discount = item.price - (item.discount / 100) * item.price;
      const total = (item.quantity * (item.price - (item.discount / 100) * item.price)).toFixed(2).toString();
      const totalGrand = `${total} ${item.symbol}`;
      dynamicData.tableData.push([item.title, item.color, item.quantity, quantityAndPrice, item.discount, discount, item.delivery_charges, totalGrand]);
    });

    // Create a new jsPDF instance
    const doc = new jsPDF();
    // Set content for the PDF
    doc.text(dynamicData.title, 10, 10);
    doc.autoTable({
      head: [dynamicData.tableHeader[0]], // Table header
      body: dynamicData.tableData, // Table data excluding the header
      startY: 40, // Starting position for the table
    });
    doc.text(dynamicData.grand_total, 10, 20); // Adjust the Y position
    doc.save('order_invoice.pdf');
  };

  const orderCreate = async (transactionHash, buyerTrasactionFee) => {
    // console.log('orderCreate cartData1', productList);
    dataArray = productList.product_details.map(data => ({
      detail_objectId: data.detail_objectId,
      product_id: data.product_id,
      price: data.price,
      title: data.title,
      sub_category_id: data.sub_category_id,
      quantity: data.quantity,
      color: data.color,
      warranty_in_months: data.warranty_in_months,
      delivery_charges: data.delivery_charges,
      discount: data.discount,
      size: data.size,
    }));

    const productIds = productList.product_details.map(data => data.product_id);
    let productList = '';
    if (benficiaryToggle === 'external') {

      productList = [{
        total_quantity: QuantityTotal,
        approver_wallet_Address: productList.approver_wallet_Address,
        store_id: productList.store_id,
        category_id: productList.category_id,
        product_id: productIds,
        buyer_approver_address: getApproverID,
        product_details: dataArray,
        total_amount: storedCustomArrayNew11?.total_amount,
        sale_tax: selectedValue === 'Percentage' ? totalPerSum : Tax,
        delivery_country: selectedCountry,
        total_delivery_charges: DeliveryTotal,
        delivery_address: document.getElementById('deliveryAddress').value,
        chain_id: productList.chain_id,
        symbol: productList.symbol,
        payment_hash: transactionHash,
        seller_wallet: productList.seller_wallet,
        agency_percentage: productList.agency_percentage,

      },
      ];
    } else {
      
      productList = [{
        total_quantity: QuantityTotal,
        approver_wallet_Address: productList.approver_wallet_Address,
        store_id: productList.store_id,
        category_id: productList.category_id,
        product_id: productIds,
        buyer_approver_address: getApproverID,
        product_details: dataArray,
        total_amount: storedCustomArrayNew11?.total_amount,
        sale_tax: selectedValue === 'Percentage' ? totalPerSum : Tax,
        delivery_country: selectedCountry,
        total_delivery_charges: DeliveryTotal,
        delivery_address: document.getElementById('deliveryAddress').value,
        chain_id: productList.chain_id,
        symbol: productList.symbol,
        seller_wallet: productList?.seller_name,
        agency_percentage: productList?.agency_percentage,
      },
      ];
    }

    if (benficiaryToggle === 'external') {
      try {

        const response = await CreateExternalOrder(productList[0]);
        if (response.data) {
          try {
            const approvalStatus = (productList.approver_wallet_Address) ? 'pending' : 'approved';
            const approvalAddress = (productList.approver_wallet_Address) ? productList.approver_wallet_Address : REACT_APP_NULL_WALLET_ADDRESS;
            const sellerAddress = productList.seller_wallet.address;

            const buyerApprovalStatus = getApproverID !== '' ? 'pending' : 'approved';
            const buyerApprovalAddress = getApproverID !== '' ? getApproverID : REACT_APP_NULL_WALLET_ADDRESS;
            const marketContract = new web3.eth.Contract(marketABI, REACT_APP_MARKET_PLACE_CONTRACT_ADDRESS_BSC);
            const status = await marketContract.methods.createOrder(productList.store_id, sellerAddress, productIds, storedCustomArrayNew11?.total_amount, response.data.mongoId, approvalAddress, approvalStatus, buyerApprovalAddress, buyerApprovalStatus).send({ from: account.address, gasPrice: web3.utils.toWei('10', 'gwei'), gasLimit: 6000000 });
            const orderNumber = 89; // await marketContract.methods.getOrderId().call();
            const orderData = {
              mongo_id: response.data.mongoId,
              order_number: orderNumber,
              order_hash: status.transactionHash,
            };
            setIsLoading(true);
            const res = await ConfirmExternalOrder(orderData);
            if (res.data) {
              toast.success('Order is successfully placed with External Wallet', {
                position: toast.POSITION.TOP_RIGHT,
              });
              setIsLoading(false);
              history.push('/getmyorderasbuyer');
              resetCartCount();
              localStorage.removeItem('customArray');
              // console.log(response.data);
            } else {
              toast.error('Something went wrong confirm External Order', {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (e) {
            setIsLoading(false);
            toast.error(`Create Order Error : ${e.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setIsLoading(false);
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        setIsLoading(false);

        toast.error('Creating Order Error:', error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (error.response) {
          setIsLoading(false);
          if (error.response.data.status === 400) {
            console.error('Response Error: ', error.response.data.Error);
            setErrorMessage(error.response.data.Error);
          } else {
            toast.error(`Creating Order Error : ${error.response.data.Message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    } else {
      try {

        setIsLoading(true);
        let response;
        if (productList[0].payMethod == "Crypto_payment") {
          response = await CreateOrderStriga(productList[0])
        } else {
          response = await CreateOrderInternal(productList[0]);
        }




        if (response.data) {
          generateAndDownloadPDF();
          toast.success('Order is successfully placed', {
            position: toast.POSITION.TOP_RIGHT,
          });
          resetCartCount();
          localStorage.removeItem('customArray');
          setIsLoading(false);
          history.push('/getmyorderasbuyer');
        } else {
          setIsLoading(false);
          console.error('Not Found', response.status);
          toast.error(response.status, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {

        setIsLoading(false);
        toast.error('Creating Order Error:', error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (error.response) {
          setIsLoading(false);
          if (error.response.data.status === 400) {
            console.error('Response Error: ', error.response.data.Error);
            setErrorMessage(error.response.data.Error);
          } else {
            toast.error(`Creating Order Error : ${error.response.data.Message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    }
  };

  const handleCreateProductNew = async (values) => {
    dataArray = productList.product_details.map(data => ({
      detail_objectId: data.detail_objectId,
      product_id: data.product_id,
      price: data.price,
      title: data.title,
      sub_category_id: data.sub_category_id,
      quantity: data.quantity,
      color: data.color,
      warranty_in_months: data.warranty_in_months,
      delivery_charges: data.delivery_charges,
      discount: data.discount,
      size: data.size,
      phone_number: phoneNumber,
      second_delivery_address: secondarydeliveryAddress
    }));

    const productIds = productList.product_details.map(data => data.product_id);

    const productList1 = [{
      total_quantity: productList.total_quantity,
      approver_wallet_Address: productList.approver_wallet_Address,
      store_id: productList.store_id,
      category_id: productList.category_id,
      product_id: productIds,
      buyer_approver_address: getApproverID,
      product_details: dataArray,
      total_amount: storedCustomArrayNew11?.total_amount,
      // total_amount: storedCustomArrayNew11?.total_amount / productList.conversionPrice,
      sale_tax: Tax / productList.conversionPrice,
      delivery_country: selectedCountry,
      total_delivery_charges: productList.total_delivery_charges,
      // total_delivery_charges: productList.total_delivery_charges / productList.conversionPrice,
      delivery_address: document.getElementById('deliveryAddress').value,
      chain_id: productList.chain_id,
      symbol: productList.symbol,
      agency_percentage: productList.agency_percentage,
      seller_wallet: productList.seller_wallet,
      phone_number: phoneNumber,
      second_delivery_address: secondarydeliveryAddress
    },
    ];

    setIsLoading(true);
    try {
      let response;
      if (productList.payMethod == "Crypto_payment") {
        response = await CreateOrderInternal(productList1[0]);
      } else {
        response = await CreateOrderStriga(productList1[0])
      }
      setIsLoading(true);
      if (response.data) {
        generateAndDownloadPDF();
        toast.success('Order is successfully placed', {
          position: toast.POSITION.TOP_RIGHT,
        });
        resetCartCount();
        localStorage.removeItem('customArray');

        setIsLoading(false);
        history.push('/getmyorderasbuyer');
      } else {
        setIsLoading(false);
        console.error('Not Found', response.status);
        toast.error(response.status, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setIsLoading(false);
        if (error.response.data.status === 400) {

          setErrorMessage(error.response.data.Error);
        } else {
          toast.error(`${error.response.data.Message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
  };

  const handleCreateProduct = async (values) => {
    console.log("🚀 ~ handleCreateProduct ~ values:", values)
    if (selectedCountry === undefined) {
      setShowError1(true);
      return;
    }
    if (document.getElementById('deliveryAddress').value.length === 0) {
      setShowError2(true);
      return;
    }
    if (benficiaryToggle === 'external') {
      if (adminWalletExist === false) {
        toast.error('Admin Wallet Address not found', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (approvereFeeExist === false) {
        toast.error('Approver Fee not found', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (feeExist === false) {
        toast.error('Sales Tax not found', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      let NewBalance = 0;
      let tokenContractVal = null;
      let tokenbalanceNew = 0;
      if (window.ethereum) {
        if (account.address !== undefined) {
          await web3.eth.getBalance(account.address)
            .then((balance) => {
              const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
              NewBalance = Number(balanceInWei);
              setcoinBalance1(121212112121);
            })
            .catch((error) => {
              setMsg(error);
              setcoinBalance1(0);
            });
        } else {
          setMsg('');
        }
        if (NewBalance === 0 || !NewBalance || NewBalance < (300000 * 5)) {
          setMsg('Not Enough Gas to create store');
          toast.error('Not Enough Gas to place order', {
            position: toast.POSITION.TOP_RIGHT,
          });
          return false;
        }
        // await checkTokenBalance();
        try {
          if (account.address !== undefined) {
            let contractAddress = '';
            if (Number(REACT_APP_ETHERIUM_CHAIN) === Number(chain)) {
              contractAddress = REACT_APP_ETH_USDT_COMISSION_ADDRESS;
            } else if (Number(REACT_APP_SMART_CHAIN) === Number(chain)) {

              contractAddress = REACT_APP_BNB_USDT_COMISSION_ADDRESS;
            } else {
              contractAddress = REACT_APP_MATIC_USDT_COMISSION_ADDRES;
            }
            const tokenContract = new web3.eth.Contract(Abi, contractAddress);

            tokenContractVal = tokenContract;
            SetContractInstance(tokenContract);
            const tokenbalance = await tokenContract.methods.balanceOf(account.address).call();
            settokenBalance(tokenbalance);
            tokenbalanceNew = tokenbalance;
          } else {
            setMsg('');
          }
        } catch (error) {
          setMsg(`Catch Error:${error?.message}`);

        }

        let checkBalance = 0;
        if (productList.approver_wallet_Address) {
          checkBalance += approverFee;
        }
        if (getApproverID) {
          checkBalance += approverFee;
        }
        if (selectedValue === 'Percentage') {
          checkBalance += storedCustomArrayNew11.total_amount + totalPerSum;
        } else {
          checkBalance += storedCustomArrayNew11.total_amount + Tax;
        }

        if (Number(tokenbalanceNew) < checkBalance) {
          setMsg('Insufficient Fund!');
          toast.error('Insufficient Fund!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          return false;
        }
        let buyerTrasactionFee = '';
        let transactionHash = '';
        if (productList.approver_wallet_Address) {
          // console.log('signTransaction');
          try {

            // getAdminWallet
            // replace productList.seller_wallet.address wwih asim bai api
            // approver_fee
            // type : approver_fee
            // cat id : 12
            // replace appprvoerFee wwih asim bai api
            if (approverFee > 0 && productList.approver_wallet_Address) {

              const totalAmountFee = (approverFee * 10 ** 8).toString();
              // const totalAmountFee = web3.utils.toWei((approverFee).toString(), 'ether');
              const recipt = await tokenContractVal.methods.transfer(productList.approver_wallet_Address, totalAmountFee).send({ from: account.address, gasPrice: web3.utils.toWei('5', 'gwei'), gasLimit: 3000000 });
              buyerTrasactionFee = recipt.transactionHash;
            }

            if (approverFee > 0 && getApproverID) {

              const totalAmountFee = (approverFee * 10 ** 8).toString();
              // const totalAmountFee = web3.utils.toWei((approverFee).toString(), 'ether');
              const recipt = await tokenContractVal.methods.transfer(getApproverID, totalAmountFee).send({ from: account.address, gasPrice: web3.utils.toWei('5', 'gwei'), gasLimit: 3000000 });
              buyerTrasactionFee = recipt.transactionHash;
            }

            if (selectedValue === 'Amount' && Tax > 0) {

              const totalAmountFee = (Tax * 10 ** 8).toString();
              // const totalAmountFee = web3.utils.toWei((Tax).toString(), 'ether');
              const recipt = await tokenContractVal.methods.transfer(adminWalletExist, totalAmountFee).send({ from: account.address, gasPrice: web3.utils.toWei('5', 'gwei'), gasLimit: 3000000 });
              buyerTrasactionFee = recipt.transactionHash;
            }

            if (selectedValue === 'Percentage' && totalPerSum > 0) {

              const totalAmountFee = (totalPerSum * 10 ** 8).toString();
              // const totalAmountFee = web3.utils.toWei((totalPerSum).toString(), 'ether');
              const recipt = await tokenContractVal.methods.transfer(adminWalletExist, totalAmountFee).send({ from: account.address, gasPrice: web3.utils.toWei('5', 'gwei'), gasLimit: 3000000 });
              buyerTrasactionFee = recipt.transactionHash;
            }
            // const createTransaction = {
            //     from: account.address,
            //     to: '0x81D84b1C6656aafc194b34feC426C19e63eEE431',
            //     value: '300000',
            //     gas: 300000,
            //     gasPrice: web3.utils.toWei('10', 'gwei'),
            //   };
            // const recipt = await web3.eth.sendTransaction(createTransaction);
            // // console.log('Recipt Response Try ', recipt);
            // console.log('signTransaction 1');
            //   const createTransaction = await web3.eth.accounts.signTransaction(
            //     {
            //         from: account.address,
            //         to: '0x81D84b1C6656aafc194b34feC426C19e63eEE431',
            //         value: '300000',
            //         gas: 3000000,
            //     },
            // );
            // console.log('signTransaction 3');
            // const recipt = await web3.eth.sendSignedTransaction(createTransaction.rawTransaction);
            // buyerTrasactionFee = recipt.transactionHash;

          } catch (e) {

          }
        }
        try {

          const totalAmount = (storedCustomArrayNew11.total_amount * 10 ** 8).toString();
          // const totalAmount = web3.utils.toWei((storedCustomArrayNew11.total_amount).toString(), 'ether');


          const increaseAllownce = await tokenContractVal.methods.increaseAllowance(productList?.seller_wallet, totalAmount).send(
            { from: account.address, gasPrice: web3.utils.toWei('5', 'gwei'), gasLimit: 3000000 })

          const transactionRecipt1 = await tokenContractVal.methods.transfer(productList?.seller_wallet, totalAmount).send({ from: account.address, gasPrice: web3.utils.toWei('5', 'gwei'), gasLimit: 3000000 });
          transactionHash = transactionRecipt1.transactionHash;

          await orderCreate(transactionHash, buyerTrasactionFee);
        } catch (e) {

          toast.error(e.message)

        }
      }
    } else {
      handleCreateProductNew(values);
    }
    return true;
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel lg={12} title="Order Invoice" closeBtn minusBtn refreshLoad>
        <Formik
          enableReinitialize
          initialValues={{
            quantity: 1,
            pickupAddress: '',
            price: '',
          }}
          onSubmit={(values) => {
            handleCreateProduct(values);
          }}
          validate={(values) => {
            const errors = {};
            if (!values.quantity) {
              errors.quantity = 'Quantity is Required!';
            }
            return errors;
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <div className="container containerDiv">
                  <div className="row">
                    <div className="col-12">
                      <div style={{ overflowY: 'auto' }}>
                        <table className="table text-center table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th style={{ fontWeight: 'bold' }}>Name</th>
                              <th style={{ fontWeight: 'bold' }}>Color</th>
                              <th style={{ fontWeight: 'bold' }}>Quantity</th>
                              <th style={{ fontWeight: 'bold' }}>Price</th>
                              <th style={{ fontWeight: 'bold' }}>Discount</th>
                              <th style={{ fontWeight: 'bold' }}>After Discount</th>
                              <th style={{ fontWeight: 'bold' }}>Shipping</th>
                              {taxedArray.length > 0 && <th style={{ fontWeight: 'bold' }}>Sales Tax</th>}
                              <th style={{ fontWeight: 'bold' }}>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productList.length === 0 ? (
                              <tr>
                                <td colSpan="8">No Product Avaiable in Cart</td>
                              </tr>
                            ) : (
                              productList.product_details.map((data, index) => (
                                // eslint-disable-next-line no-underscore-dangle
                                <tr key={data._id}>
                                  <td style={{ verticalAlign: 'middle', textTransform: 'Capitalize' }}>{data.title}</td>
                                  {data.color ? (
                                    <td style={{ verticalAlign: 'middle' }}>{data.color}</td>
                                  ) : (
                                    <td style={{ verticalAlign: 'middle' }}>-</td>
                                  )}
                                  {/* <td style={{ verticalAlign: 'middle' }}>{data.size}</td> */}
                                  <td style={{ verticalAlign: 'middle' }}>{data.quantity}</td>
                                  <td style={{ verticalAlign: 'middle' }}>{data.price}
                                    <b style={{ color: 'gray', fontWeight: '500' }}> {data.symbol}</b>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }}>{data.discount}<b style={{ color: 'gray', fontWeight: '500' }}>%</b></td>
                                  <td style={{ verticalAlign: 'middle' }}>{data.price - (data.discount / 100) * data.price}  <b style={{ color: 'gray', fontWeight: '500' }}> {data.symbol}</b></td>
                                  <td style={{ verticalAlign: 'middle' }}>{data.delivery_charges} <b style={{ color: 'gray', fontWeight: '500' }}> {data.symbol}</b></td>
                                  {selectedValue === 'Percentage' ? (
                                    taxedArray111.length > 0 && <td style={{ verticalAlign: 'middle' }}>{taxedArray111[index]?.totalPer.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {data.symbol}</b></td>
                                  ) : (
                                    taxedArray.length > 0 && <td style={{ verticalAlign: 'middle' }}>{taxedArray[index]?.val.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {data.symbol}</b></td>
                                  )}
                                  {data.discount ? (
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {(data.quantity * (data.price - (data.discount / 100) * data.price)).toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}>{data.symbol}</b>
                                    </td>
                                  ) : (
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {(data.price).toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {data.symbol}</b>
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                      <div className="row py-4">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="chain" className="form-label text-white mt-3">Products Sales Tax</label>
                          <br />
                          <FormControl className="mt-1">
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={selectedValue}
                              name="ProductSalesTax"
                            >
                              <FormControlLabel
                                style={{ color: 'white' }}
                                value="Amount"
                                name="ProductSalesTax"
                                control={(
                                  <Radio
                                    {...controlProps('Amount')}
                                    sx={{
                                      color: 'white',
                                      '&.Mui-checked': {
                                        color: colorAccent,
                                      },
                                    }}
                                  />
                                )}
                                label="Amount"
                              />

                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      <div className="row pb-4">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                          <InputDiv>
                            <DropdownDiv className="mb-0">
                              <label htmlFor="chain" className="form-label text-white">Delivery Country *</label>
                              <CustomSelect
                                name="deliveryCountry"
                                id="deliveryCountry"
                                value={selectedCountry}
                                onChange={handleCountryChange}
                                options={Countries}
                              />
                            </DropdownDiv>
                            {errors.deliveryCountry && touched.deliveryCountry && (
                              <Error className="input feedback">{errors.deliveryCountry}</Error>
                            )}
                          </InputDiv>
                          {showError1 && <Error className="input feedback">Delivery Country is required</Error>}
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                          <InputDiv>
                            <DropdownDiv className="">
                              <label htmlFor="subcategory_id" className="form-label text-white">Buyer Approver</label>
                              <CustomSelect
                                name="subcategory_id"
                                id="subcategory_id"
                                // eslint-disable-next-line max-len
                                className={`form-control w-25 ${errors.approver_wallet_address && touched.approver_wallet_address && 'error'}`}
                                styles={{
                                  menu: provided => ({
                                    ...provided,
                                    textTransform: 'capitalize',
                                  }),
                                }}
                                value={getApproverID}
                                options={approverOptions}
                                onChange={handleApproverChange}
                              />
                              {errors.approver_wallet_address && touched.approver_wallet_address && (
                                <Error className="input feedback">{errors.approver_wallet_address}</Error>
                              )}
                            </DropdownDiv>
                          </InputDiv>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                          <label htmlFor="brand" className="form-label text-white" style={{ fontSize: '14px' }}>Delivery Address *</label>
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="Enter Delivery Address"
                            name="deliveryAddress"
                            id="deliveryAddress"
                            value={deliveryAddress}
                            onChange={handleDeliveryAddressChange}
                          />
                          {showError2 && <Error className="input feedback">Delivery Address is required</Error>}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                          <label htmlFor="brand" className="form-label text-white" style={{ fontSize: '14px' }}>Phone Number *</label>
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="Enter Phone Number"
                            name="phone_number"
                            id="phone_number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                          {showError2 && <Error className="input feedback"></Error>}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                          <label htmlFor="brand" className="form-label text-white" style={{ fontSize: '14px' }}>Secondary Delivery Address *</label>
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="Enter Secondary Delivery Address"
                            name="phone_number"
                            id="phone_number"
                            value={secondarydeliveryAddress}
                            onChange={(e) => setSecondaryDeliveryAddress(e.target.value)}
                          />
                          {showError2 && <Error className="input feedback"></Error>}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <div className="row text-center py-4">
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">Total Quantity</h5>
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">{productList?.total_quantity}</h5>
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">Shipping Charges</h5>
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">{productList?.total_delivery_charges?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {productList?.symbol}</b></h5>
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">Total Price</h5>
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">{productList?.total?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {productList?.symbol}</b></h5>
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">Sales Tax</h5>
                        </div>
                        <div className="col-6">
                          {selectedValue === 'Percentage' ? (
                            <h5 className="currentPrice mt-3">{totalPerSum.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {productList?.symbol}</b></h5>
                          ) : (
                            <h5 className="currentPrice mt-3">{Tax.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {productList?.symbol}</b></h5>
                          )}
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">  Grand Total</h5>
                        </div>
                        <div className="col-6">
                          <h5 className="currentPrice mt-3">{(productList?.total_amount + Tax)?.toFixed(2)} <b style={{ color: 'gray', fontWeight: '500' }}> {productList?.symbol}</b>
                          </h5>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12">
                          {productList.length !== 0 && (
                            <button
                              type="submit"
                              className="btn btn-primary btn-block w-40 purple-button mt-3 shadow-none text-capitalize"
                            >
                              Place Order
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Panel>
    </>
  );
};


export default ProductCart;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const FormButton = styled(Button)`
 color:"white";
 mergin-top:1rem;
`;

const Error = styled.div`
 
color: #e66e6e;
padding: 2px 0px;
font-size: 12px;
cursor:none;
`;
