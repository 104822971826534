import React, { useState, useEffect } from 'react';
import { Ring } from '@uiball/loaders';
import { getService } from '../../../utils/services/user.service';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation hook

const Services = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFullDescriptions, setShowFullDescriptions] = useState({});
  const location = useLocation(); // Get the current route location

  const toggleDescription = (id) => {
    setShowFullDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle visibility only for the specific product ID
    }));
  };

  const fetchAllServices = async () => {
    try {
      setIsLoading(true);
      const res = await getService();
      setProducts(res?.data?.data);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);

    }
  };

  useEffect(() => {
    fetchAllServices();
  }, []);

  useEffect(() => {
    // Initialize showFullDescriptions for fetched products
    const initialDescriptions = products.reduce((acc, product) => {
      acc[product._id] = false; // All descriptions are initially collapsed
      return acc;
    }, {});
    setShowFullDescriptions(initialDescriptions);
  }, [products]);

  // Determine how many products to display based on the route
  const displayedProducts = location.pathname === '/services' ? products : products.slice(0, 3);

  return (
    <div className={`container ${location.pathname === '/services' ? ' py-4 ' : "pb-4 mb-4"}`}>
      {isLoading && (
        <LoaderOverlay>
          <Ring size={65} color="#00897B" />
        </LoaderOverlay>
      )}
      <div className='d-flex align-items-center justify-content-between '>
        {/* <h2 className="text-center mb-4">Custodiy Services</h2> */}
        {location.pathname === '/' && (
          <Link to={'/services'}>
            <p>View All</p>
          </Link>
        )}
      </div>
      <div className={`row row-cols-1 ${location.pathname === '/services' ? ' row-cols-lg-4' : "row-cols-lg-3"} row-cols-md-3 g-4`}>
        {displayedProducts.map((product) => (
          <div className="col" key={product.id}>``
            <div className="card h-100">
              <img
                src={product?.banner}
                className="card-img-top border rounded-3"
                alt={product.title}
                style={{
                  height:'200px',
                  objectFit:'cover',
                
                }}
              />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title mt-2">{product.title}</h5>
                <Description className="text-black flex-grow-1">
                  {showFullDescriptions[product._id]
                    ? product.description
                    : `${product.description.substring(0, 150)}...`}
                  {product.description.length > 100 && (
                    <SeeMoreButton
                      onClick={() => toggleDescription(product._id)}
                    >
                      {showFullDescriptions[product._id] ? 'See Less' : 'See More'}
                    </SeeMoreButton>
                  )}
                </Description>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;

const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999999;
`;

const Description = styled.p`
  margin: 15px 0;
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;
