/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/home.css";
import { Polar } from "react-chartjs-2";

import styled from "styled-components";
import { Bar } from "react-chartjs-2";

// import Button from '@material-ui/core/Button';
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { Card, CardBody } from "@/shared/components/Card";
import Carousel from "@/shared/components/carousel/CarouselMultiply";

import { colorAccent, colorAdditional, colorBackground } from "@/utils/palette";
import { right, left, marginRight } from "@/utils/directions";
import Panel from "../../../shared/components/Panel";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {
  getRecentHistory,
  gettotalAccounts,
  gettotalBalance,
  getotcMarketplaceDetails,
  getNews,
  getstoreDetails,
  getPartner,
  getVotingGeneral,
  getRecentProduct,
} from "../../../utils/services/user.service";
import "../style/Rating.css";
import "../style/storedetail.css";
import "../style/storedetailuser.css";
import "../style/homecard.css";
import "./customStyle.css";
import HomeCard from "./HomeCard";
import NewDetailModal from "./NewsDetailModal";
import Logo from "../../../utils/img/custodiy.png";
import numeral from "numeral";
import PartnerGrid from "./PartnerGrid";
import { Divider } from "@material-ui/core";
import { size } from "polished";
import Services from "../service/Services";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import { useChainContext } from "../../../containers/context/context";
import { Col, Row } from "react-bootstrap";
import StarRating from "./StarRating";

export default function NewHomePage() {
  const navigate = useHistory();
  const [productData, setProductData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [getPartnerData, setPartnerData] = useState([]);
  const [flashproductData, setFlashProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(productData.length / 10);
  const [isLoading, setIsLoading] = useState(false);
  const [slidesToShowSize, setSlidesToShowSize] = useState(6);
  const [sessionId, setSessionId] = useState(0);
  const [gettotalAccountsVal, settotalAccountsVal] = useState("");
  const [gettotalBalanceVal, settotalBalanceVal] = useState(null);
  const [getMarketplaceData, setMarketplaceData] = useState([]);
  const [getStoreDetail, setStoreDetail] = useState([]);
  const [recentProducts, setRecentProduct] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [show, setShow] = useState(false);
  const [votingData, setVotingData] = useState(null);
  const { setInfoData } = useChainContext();

  const [countdowns, setCountdowns] = useState({});
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "All Voting Data",
        backgroundColor: "#FF6384",
        borderColor: "#FF6384",
        borderWidth: 1,
        hoverBackgroundColor: "#FF6384",
        hoverBorderColor: "#FF6384",
        data: [],
      },
    ],
  });
  useEffect(() => {
    const labels = votingData?.map((item) => item.title);
    const totalVotes = votingData?.map((item) => item.total_votes.length);

    setChartData({
      labels: labels,
      datasets: [
        {
          ...chartData.datasets[0],
          data: totalVotes,
        },
      ],
    });
  }, [votingData]);

  // Define a function to remove duplicate colors
  function removeDuplicateColors(product) {
    const seenColors = new Set();
    // eslint-disable-next-line no-param-reassign
    product.product_sizes_quantity = product.product_sizes_quantity.filter(
      (item) => {
        if (!seenColors.has(item.color)) {
          seenColors.add(item.color);
          return true;
        }
        return false;
      }
    );
  }
  const fetchtotalAccounts = async () => {
    try {
      const response = await gettotalAccounts();
      if (response?.data) {
        const Data = response?.data;

        settotalAccountsVal(Data);
      } else {
        console.error("Error fetching getBTC:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching getBTC:", error);
    }
  };
  const fetchtotalBalance = async () => {
    try {
      const response = await gettotalBalance();

      settotalBalanceVal(response.data.result.totalBalance);
    } catch (error) {
      console.error("Error fetching gettotalBalance:", error);
    }
  };
  const fetchNewData = async () => {
    setIsLoading(true);
    try {
      const response = await getNews();

      if (response?.data) {
        setNewsData(response?.data?.data);
        setIsLoading(false);
      } else {
        console.error("Error fetching gettotalBalance:", response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching gettotalBalance:", error);
    }
  };
  const fetchVotingData = async () => {
    setIsLoading(true);
    try {
      const response = await getVotingGeneral();
      setVotingData(response?.data?.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching gettotalBalance:", error);
    }
  };
  const fetchPartnerData = async () => {
    setIsLoading(true);

    try {
      const response = await getPartner();
      if (response?.data) {
        setPartnerData(response?.data?.data);
        setIsLoading(false);
      } else {
        console.error("Error fetching gettotalBalance:", response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching gettotalBalance:", error);
    }
  };
  const fetchOtchMarketplace = async () => {
    try {
      const response = await getotcMarketplaceDetails();
      setMarketplaceData(response?.data);
    } catch (error) {
      console.error("Error fetching getBTC:", error);
    }
  };
  const fetchStoreDetail = async () => {
    try {
      const response = await getstoreDetails();
      setStoreDetail(response?.data);
    } catch (error) {
      console.error("Error fetching getBTC:", error);
    }
  };
  const fetchRecentProduct = async () => {
    try {
      const response = await getRecentProduct();
      console.log("🚀 ~ fetchRecentProduct ~ response:", response);
      setRecentProduct(response?.data?.data);
    } catch (error) {
      console.error("Error fetching getBTC:", error);
    }
  };

  // Iterate through each product and remove duplicate colors
  if (productData.length > 0) {
    productData.forEach((product) => {
      removeDuplicateColors(product);
    });
  }

  const fetchRecentHistory = async () => {
    try {
      const requestData = {
        session_id: localStorage.getItem("storeSessioId"),
      };
      const response = await getRecentHistory(requestData);

      if (response?.data.status) {
        // console.log('getRecentHistory Response Data : ', response?.data);
        setRecentData(response?.data.recentProducts);
      } else {
        console.error("No category data received");
      }
    } catch (error) {
      console.error("Error fetching getRecentHistory:", error);
    }
  };

  useEffect(() => {
    fetchRecentHistory(); // Load initial data
  }, [currentPage]);

  useEffect(() => {
    if (localStorage.getItem("storeSessioId") === null) {
      const sessionIdValue = Math.random().toString(36).substring(2, 15);
      setSessionId(sessionIdValue);
      localStorage.setItem("storeSessioId", sessionIdValue);
    }

    const intervalId = setInterval(() => {
      if (window.innerWidth < 600) {
        setSlidesToShowSize(3);
      } else {
        setSlidesToShowSize(6);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  useEffect(() => {
    fetchtotalAccounts();
    fetchtotalBalance();
    fetchOtchMarketplace();
    fetchStoreDetail();
    fetchNewData();
    fetchPartnerData();
    fetchVotingData();
    fetchRecentProduct();
  }, []);
  const handleRedirect = (id) => {
    navigate.push(`/project-detail?id=${id}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedCountdowns = {};
      votingData.forEach((item) => {
        const now = new Date();
        const startTime = new Date(item.start_date);
        const endTime = new Date(item.end_date);

        let timeLeft;
        let label;

        if (now < startTime) {
          // Countdown to start time
          timeLeft = Math.max(0, startTime - now);
          label = "Voting Starts in:";
        } else if (now < endTime) {
          // Countdown to end time
          timeLeft = Math.max(0, endTime - now);
          label = "Voting Ends in:";
        } else {
          // Voting period has ended
          updatedCountdowns[item.id] = "Voting Ended";
          return;
        }

        if (timeLeft > 0) {
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
          const seconds = Math.floor((timeLeft / 1000) % 60);
          updatedCountdowns[item.id] = `${label} ${
            days > 0 ? `${days}d ` : ""
          }${hours}h ${minutes}m ${seconds}s`;
        }
      });
      setCountdowns(updatedCountdowns);
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [votingData]);

  useEffect(() => {
    // Prepare data
    const newData = [
      {
        value: gettotalAccountsVal?.result?.totalUsers || 0,
        icon: "fa-user",
      },
      {
        value: ` ${numeral(gettotalBalanceVal || 0)
          .format("0a")
          .toUpperCase()}`,
        icon: "fa-dollar",
      },
      {
        value: getStoreDetail?.totalStore || 0,
        icon: "fa-store",
      },
      {
        value: getMarketplaceData?.totalOtcMarketPlace || 0,
        icon: "fa-shopping-cart",
      },
    ];

    // Update state
    setInfoData(newData);
  }, [
    gettotalAccountsVal,
    gettotalBalanceVal,
    getStoreDetail,
    getMarketplaceData,
  ]);
  return (
    <div className="d-flex flex-column gap-4">
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <Panel className={"homePannel"}>
        <Services />
      </Panel>

      <div className="d-flex flex-column text-center  align-items-center justify-content-center gap-4">
        {/* <div className="d-flex flex-column flex-md-row gap-2 justify-content-center">
              <div className="d-flex flex-column col-12 justify-content-center col-md-4">
                <img
                  style={{ width: "100%", height: "100px" }}
                  className="d-none d-md-block"
                  src={Logo}
                  alt="logo"
                />
                <img
                  style={{ width: "100%" }}
                  className="d-md-none d-block"
                  src={Logo || ""}
                  alt="logo"
                />
              </div>
              <div className="d-flex col-12 col-md-8 flex-column align-items-center justify-content-center text-center  ">
                <h1 className="d-flex text-center align-content-center wight">
                  Custodiy is a Modular Platform
                </h1>
                <h5 className="d-flex text-start">
                  Custodiy is a modular platform designed to empower individuals
                  and businesses alike. Whether you're an entrepreneur looking
                  to establish your own marketplace, a seller seeking to expand
                  your reach, or an organization aiming to facilitate
                  over-the-counter transactions and fundraising, Custodiy
                  provides the tools and support you need. Our platform offers
                  seamless solutions for storing and managing important
                  documents, ensuring security and accessibility. With Custodiy,
                  unlock the potential to thrive in today's dynamic market
                  landscape.
                </h5>
              </div>
            </div> */}

        {/* <div className="row w-100 gap-2 gap-md-0 mt-2 justify-content-between align-items-center  ">
          <HomeCard
            title={"Total User"}
            value={gettotalAccountsVal?.result?.totalUsers || 0}
          />
          <HomeCard
            title={"Total Amount"}
            value={` ${"$ "} ${numeral(gettotalBalanceVal || 0)
              .format("0a")
              .toUpperCase()}`}
          />
          <HomeCard
            title={"Total Marketplace"}
            value={getStoreDetail?.totalStore || 0}
          />
          <HomeCard
            title={"Total OTC"}
            value={getMarketplaceData?.totalOtcMarketPlace || 0}
          />
        </div> */}
        {/* 
            <div className="d-flex gap-2 ">
              <div className="d-flex flex-column  align-items-center justify-content-center   ">
                {!userInfo?.data?.user && (
                  <Link to={"/login"}>
                    <FormButton variant="outline-primary" type="submit">
                      Log in
                    </FormButton>
                  </Link>
                )}
              </div>
            </div> */}
      </div>

      {/* top 5 product  */}

      <Panel lg={12} title="Recent Product" closeBtn minusBtn refreshLoad>
        <div className="col-lg-12 col-md-12 col-12  text-center ">
          <Row xs={12} md={12} lg={4} xl={4} className="mt-2 mb-4 ">
            {recentProducts.slice(0, 4).map((product, index) => (
              <Col style={{ textTransform: "capitalize" }} key={product._id}>
                <Link
                  to={`/productdetails?title=${encodeURIComponent(
                    product.title
                  )}&id=${encodeURIComponent(product._id)}`}
                >
                  <div
                    className="card productCard mt-1 d-flex flex-column justify-content-between"
                    style={{ background: "#2A2A30" }}
                  >
                    {product.product_sizes_quantity[0].discount &&
                      product.product_sizes_quantity[0].discount > 0 && (
                        <ProductItemLabel src={OffTag} alt="new" />
                      )}
                    <div className=" mt-4">
                      <img
                        src={
                          product.pictures.length > 0
                            ? product.pictures[0]
                            : NoImg1
                        }
                        className=" p-3 border-radius zoom-in-on-hover"
                        alt={product.storeName}
                      />
                    </div>

                    <div className="py-2">
                      <span className="d-flex flex-column flex-md-row justify-content-between px-1">
                        <h5 className="card-title text-nowrap producttitle mt-0 mb-1">
                          {product.title}
                        </h5>
                        <p className="card-title productPrice mb-0">
                          {product.product_sizes_quantity[0].price}{" "}
                          {product.symbol}
                        </p>
                      </span>
                      <div
                        className="Rating mb-4"
                        style={{ float: "right", marginTop: "1%" }}
                      >
                        {product.ProductRatting?.length > 0 ? (
                          <StarRating
                            rating={product.ProductRatting[0]?.averageRating}
                          />
                        ) : (
                          <StarRating rating={0} />
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </Panel>

      {votingData?.length > 0 && (
        <>
          <Panel lg={12} title="Vote Events" closeBtn minusBtn refreshLoad>
            <div className="col-lg-12 col-md-12 col-12  text-center votes">
              {/* Graph Section */}
              <Swiper
                modules={[Navigation, Pagination, EffectFade]}
                spaceBetween={10} // Adds spacing between slides
                slidesPerView={3} // Default to showing 3 cards at once
                navigation
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{
                  delay: 3000, // Adjust delay for autoplay
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  // Responsive settings for different screen sizes
                  320: {
                    slidesPerView: 1, // 1 card for smaller screens
                  },
                  768: {
                    slidesPerView: 2, // 2 cards for tablets
                  },
                  1024: {
                    slidesPerView: 3, // 3 cards for larger screens
                  },
                }}
              >
                {votingData.map((item, index) => {
                  const equalVotes = item.option_name.map((_, index) => {
                    const voteData = item.votes_counters.find(
                      (counter) => counter._id === index + 1
                    );
                    return voteData ? voteData.count : 0; // Default to 0 if no votes found
                  });
                  const colors = [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                    "rgba(255, 159, 64, 0.6)",
                  ];

                  const itemChartData = {
                    labels: item.option_name,
                    datasets: [
                      {
                        label: "Options",
                        data: equalVotes,
                        backgroundColor: colors.slice(
                          0,
                          item.option_name.length
                        ),
                        borderWidth: 1,
                      },
                    ],
                  };

                  const itemChartOptions = {
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  };

                  return (
                    <SwiperSlide
                      key={index}
                      className="border-end col-12 col-md-4 "
                    >
                      <div className="d-flex flex-column gap-1  ">
                        <div>
                          <Polar
                            data={itemChartData}
                            options={itemChartOptions}
                          />
                        </div>
                        <div className="container">
                          <p
                            className="text-info mb-2 text-start"
                            style={{ fontSize: "12px" }}
                          >
                            {/* Additional information */}
                          </p>
                          <p
                            className="text-info mb-2"
                            style={{ fontSize: "12px" }}
                          >
                            {countdowns[item.id] || "Loading..."}
                          </p>

                          <div
                            onClick={() => handleRedirect(item?.project_id)}
                            className="d-flex flex-column align-items-center"
                          >
                            <p className="text-white">{item?.title}</p>
                            {/* <div> */}
                            <img
                              src={item.banner[0]}
                              alt={`slide_${item.banner[0]}`}
                              className="d-flex col-12"
                              style={{
                                width: "80%", // Full width of the container
                                height: "200px", // Fixed height
                                objectFit: "contain", // Ensures the image covers the area without distortion
                              }}
                            />
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              {show && (
                <NewDetailModal
                  show={show}
                  hide={() => setShow(false)}
                  data={modalData}
                />
              )}
            </div>
          </Panel>
        </>
      )}

      {newsData.length > 0 && (
        <div className="col-lg-12 col-md-12 col-sm-12   text-center">
          <Card className="">
            <CardBody>
              <Carousel height={false}>
                {newsData?.map((item) => (
                  <div key={item.id} className=" ">
                    <div className="square-img-wrapper  ">
                      <img
                        src={item.images[0]}
                        alt={`slide_${item.images[0]}`}
                        className="card-img-top square-img"
                        onClick={() => {
                          setModalData(item);
                          setShow(true);
                        }}
                      />
                    </div>
                    {/* 
                  <h5
                    style={{ textTransform: "capitalize" }}
                    className="card-title producttitle mt-2 mb-0"
                  >
                    {item.title}
                  </h5>

                  <p className="card-title productPrice mb-0">
                    {item.description}
                  </p> */}
                  </div>
                ))}
              </Carousel>
            </CardBody>
          </Card>

          {show && (
            <NewDetailModal
              show={show}
              hide={() => setShow(false)}
              data={modalData}
            />
          )}
        </div>
      )}

      {getPartnerData.length > 0 && (
        <div className="d-flex flex-column justify-content-center  align-items-center py-2  ">
          <h3 className="mt-4">Partners</h3>
          <PartnerGrid getPartnerData={getPartnerData} />
        </div>
      )}

      <div className="d-flex gap-3 justify-content-center align-items-center pb-4">
        <Link to={"/privacy-notice"}>
          <p>Privacy Notice</p>
        </Link>

        <div className="vr text-white"></div>
        <Link to={"/cookie-policy"}>
          <p>Cookie Policy</p>
        </Link>
      </div>
    </div>
  );
}

// region STYLES

const ProductItemsWrap = styled.div`
  overflow: hidden;
  width: 100%;
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 20px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 2px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
`;

const ProductItemsList = styled.div`
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
`;

const ProductCard = styled(Card)`
  padding: 0;
  width: 25%;
  background: red;

  @media screen and (max-width: 1500px) {
    width: 25%;
    background: red;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    background: red;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    background: red;
  }
`;

const ProductItem = styled(CardBody)`
  margin-bottom: 30px;
  height: 440px;
  ${marginRight}: 30px;
  position: relative;
  background-color: ${colorBackground};
`;

const ProductItemImageWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  overflow: hidden;
`;

const ProductItemImage = styled.img`
  height: 100%;
  width: auto;
`;

const ProductItemInfo = styled.div`
  text-align: ${left};
  position: relative;
  width: calc(100% - 90px);
`;

const ProductItemTitle = styled.h4`
  font-weight: 500;
  transition: all 0.3s;
`;

const ProductItemLink = styled(Link)`
  padding: 40px 30px;
  display: block;

  &:hover {
    text-decoration: none;

    ${ProductItemTitle} {
      color: ${colorAccent};
    }
  }
`;

const ProductItemDescription = styled.p`
  margin: 0;
  color: ${colorAdditional};
  line-height: 17px;
`;

const ProductItemPrice = styled.h1`
  position: absolute;
  top: 0;
  ${right}: -90px;
  line-height: 36px;
`;

const ProductItemOldPrice = styled.p`
  position: absolute;
  ${right}: -90px;
  top: 36px;
  line-height: 28px;
  color: ${colorAdditional};
  margin: 0;
  text-decoration: line-through;
`;

const ProductItemColor = styled.span`
  height: 15px;
  width: 15px;
  ${marginRight}: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
`;

const ProductItemLabel = styled.img`
  position: absolute;
  top: -10px;
  ${right}: 5px;
  width: 65px;
`;
