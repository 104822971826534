/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import '../style/home.css';
import { makeStyles } from '@material-ui/core/styles';
import { Col, Container, Row } from 'react-bootstrap';
// import Card from '@material-ui/core/Card';
import styled from 'styled-components';
import CardActionArea from '@material-ui/core/CardActionArea';
import Slider from 'react-slick';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Ring } from '@uiball/loaders';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import Carousel from '@/shared/components/carousel/CarouselMultiply';
// import { CardBody } from '@/shared/components/Card';
import { colorAccent, colorAdditional, colorBackground } from '@/utils/palette';
import { right, left, marginRight } from '@/utils/directions';
import StarRating from './StarRating';
import HotStarRating from './HotStarRating';
import {
  getSameProduct,
} from '../../../utils/services/user.service';
import '../style/Rating.css';
import '../style/storedetail.css';
import '../style/storedetailuser.css';
import '../style/homecard.css';


export default function SameProducts(productIdNo) {
  const [flashproductData, setFlashProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [slidesToShowSize, setSlidesToShowSize] = useState(6);
  const [sessionId, setSessionId] = useState(0);
  const OffTag = `${process.env.PUBLIC_URL}/img/for_store/catalog/off_tag_.png`;
  const NoImg1 = `${process.env.PUBLIC_URL}/img/No-img1.png`;  

  // Define a function to remove duplicate colors
  function removeDuplicateColors(product) {
    const seenColors = new Set();
    // eslint-disable-next-line no-param-reassign
    product.product_sizes_quantity = product.product_sizes_quantity.filter((item) => {
      if (!seenColors.has(item.color)) {
        seenColors.add(item.color);
        return true;
      }
      return false;
    });
  }
 
  useEffect(() => {
  }, [currentPage]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // console.log('window.innerWidth Data : ', window.innerWidth);
    // console.log('localStorage Data : ', localStorage.getItem('storeSessioId2'));
    if (localStorage.getItem('storeSessioId') === null) {
      const sessionIdValue = Math.random().toString(36).substring(2, 15);
      setSessionId(sessionIdValue);
      localStorage.setItem('storeSessioId', sessionIdValue);
    }

    const intervalId = setInterval(() => {
      if (window.innerWidth < 600) {
        setSlidesToShowSize(3);
      } else {
        setSlidesToShowSize(6);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  useEffect(() => {
    const fetchflashProducts = async () => {
      try {
        const requestData = {
          productId: productIdNo,
          session_id: localStorage.getItem('storeSessioId'),
        };
        const response = await getSameProduct(requestData);
        if (response.data) {
          const flashProductsData = response.data.similarProducts;
          setFlashProductData(flashProductsData);
        } else {
          console.error('Error fetching getSameProduct:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching getSameProduct:', error);
      }
    };

    fetchflashProducts();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: slidesToShowSize,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: 'linear',
  };


  return (
    <>
      <div
        className="loader-overlay"
        style={{
    display: isLoading ? 'flex' : 'none', // Use flex display when loading
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999,
  }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="container pb-0">
        {flashproductData.length > 6 ? (
          <div className="row mt-1 mb-0 mx-1 pb-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h4 className="mb-2 mx-3" style={{ fontSize: '18px' }}> Handpicked Selectioneee</h4>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
              <div className="mx-3" style={{ background: '#232329' }}>
                <Slider {...settings}>
                  {flashproductData.map(item => (
                    <Link to={`/productdetails/${item._id}`}>
                      <div key={item.id} className="productCard p-1">
                        <div className="square-img-wrapper mt-1">
                          <img 
                            src={item.pictures[0]}  
                            alt={`slide_${item.pictures[0]}`} 
                            className="card-img-top square-img p-3 zoom-in-on-hover"
                          />
                        </div>
                        {item.title.length > 20 ? (
                          <h5 
                            style={{ textTransform: 'capitalize' }} 
                            className="card-title producttitle mt-0 mb-0"
                          >{item.title.substring(0, 20)}...
                          </h5>         
                            ) : (
                              <h5 
                                style={{ textTransform: 'capitalize' }}   
                                className="card-title producttitle mt-0 mb-0"
                              >{item.title}
                              </h5>
                            )}  
                        <p className="card-title productPrice mb-0" style={{ fontSize: 'smaller' }}>
                          {item.product_sizes_quantity[0].price} {item.symbol}
                        </p>
                        <div className="row text-text-center">
                          <div className="Rating mb-2" style={{ float: 'left', marginTop: '0%' }}>
                            {item.ProductRatting?.length > 0 ? (
                              <HotStarRating rating={item.ProductRatting[0]?.averageRating} />
                            ) : (
                              <HotStarRating rating={0} />
                            )}
                          </div> 
                        </div>
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        ) : (
          <Container className="text-white">
            <Row xs={12} md={12} lg={12} className="mx-1">
              <Col>
                <h4 className="mb-2 mx-1" style={{ fontSize: '18px' }}> Handpicked Selection </h4>
              </Col>
            </Row>
            <Row xs={2} md={3} lg={6} className="mt-2 mb-0 mx-1">           
              {flashproductData.map((product, index) => (
                <Col
                  style={{ textTransform: 'capitalize' }}
                  key={product._id}
                >
                  <Link to={`/productdetails/${product._id}`}>
                    <div className="card productCard mt-1" style={{ background: '#232329' }}>
                      {product.product_sizes_quantity[0].discount
                    && product.product_sizes_quantity[0].discount > 0 && (
                      <ProductItemLabel src={OffTag} alt="new" />
                    )}
                      <div className="square-img-wrapper mt-4">
                        <img
                          src={product.pictures.length > 0 ? product.pictures[0] : NoImg1}
                          className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                          alt={product.storeName}
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title producttitle mt-0 mb-1">{product.title}</h5>
                        <p className="card-title productPrice mb-0">
                          {product.product_sizes_quantity[0].price} {product.symbol}
                        </p>
                        <div className="Rating mb-4" style={{ float: 'right', marginTop: '1%' }}>
                          {product.ProductRatting?.length > 0 ? (
                            <StarRating rating={product.ProductRatting[0]?.averageRating} />
                      ) : (
                        <StarRating rating={0} />
                      )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
          ))}
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}

// region STYLES

const ProductItemsWrap = styled.div`
  overflow: hidden;
  width: 100%;
`;

const ProductItemsList = styled.div`
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
`;

const ProductCard = styled(Card)`
  padding: 0;
  width: 25%;
  background: red;

  @media screen and (max-width: 1500px) {
    width: 25%;
    background: red;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    background: red;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    background: red;
  }
`;

const ProductItem = styled(CardBody)`
  margin-bottom: 30px;
  height: 440px;
  ${marginRight}: 30px;
  position: relative;
  background-color: ${colorBackground};
`;

const ProductItemImageWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  overflow: hidden;
`;

const ProductItemImage = styled.img`
  height: 100%;
  width: auto;
`;

const ProductItemInfo = styled.div`
  text-align: ${left};
  position: relative;
  width: calc(100% - 90px);
`;

const ProductItemTitle = styled.h4`
  font-weight: 500;
  transition: all 0.3s;
`;

const ProductItemLink = styled(Link)`
  padding: 40px 30px;
  display: block;

  &:hover {
    text-decoration: none;

    ${ProductItemTitle} {
      color: ${colorAccent};
    }
  }
`;

const ProductItemDescription = styled.p`
  margin: 0;
  color: ${colorAdditional};
  line-height: 17px;
`;

const ProductItemPrice = styled.h1`
  position: absolute;
  top: 0;
  ${right}: -90px;
  line-height: 36px;
`;

const ProductItemOldPrice = styled.p`
  position: absolute;
  ${right}: -90px;
  top: 36px;
  line-height: 28px;
  color: ${colorAdditional};
  margin: 0;
  text-decoration: line-through;
`;

const ProductItemColor = styled.span`
  height: 15px;
  width: 15px;
  ${marginRight}: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
`;

const ProductItemLabel = styled.img`
  position: absolute;
  top: -10px;
  ${right}: 5px;
  width: 65px;
`;
